import React from 'react';
import Button from '../Common/Button';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { storeInfo } from '../Utilities/Placeholders';
import { configData } from '../Whitelabel/websitesData';

function Privacy() {
    const navigate = useNavigate();

    const handleGoBack = () => {
      navigate(-1);
    };

    return (
        <div className='main fadeIn'>
            <div className='pageContent legalStyle'>

                {configData.defaultStore === 'Peças Kia' ? (
                    <div className='card'>
                        <div className='column legalTerm' style={{padding: '20px', gap: '10px'}}>
                            <h2>Política de Privacidade</h2>
                            <p>A BRM Comercio de Veículos LTDA., pessoa jurídica de direito privado, com sede na Av. Barão Homem de Melo, 3.030, Bairro Estoril, Belo Horizonte/MG, inscrita no CNPJ sob o n. 10.221.140/0001-43 (“Kia Barão”), como proprietária deste website, possui guarda e gestão sobre o tratamento dos dados aqui coletados. A Política de Privacidade aqui tratada também se aplica a todo o Grupo Autorede, abrangendo controladoras e controladas, direta ou indiretamente, assim como suas coligadas sob controle comum.</p>
                            <p>Por definição, sendo a Kia Barão, bem como as demais empresas do Grupo Autorede, responsável pelo tratamento e segurança dos dados coletados pela própria empresa, é denominada “Controladora” e “Operadora”, e, conjuntamente, “Controladoras” e “Operadoras”.</p>
                            <p>A presente Política de Privacidade se presta a garantir seu entendimento quanto ao procedimento de coleta, objetivo e tratamento dos dados pessoais coletados referentes à você, usuário do site e/ou cliente (“Titular”). Vale frisar que todos os procedimentos envolvendo dados seguem estritamente os moldes da Lei nº 13.709/18, Lei Geral de Proteção de Dados (“LGPD”), bem como demais legislações aplicáveis.</p>
                            <p>A Política de Privacidade é pautada pelos princípios da adequação, finalidade, livre acesso, não discriminação, necessidade, prevenção, qualidade dos dados, segurança, transparência, consentimento e todos os demais princípios definidos em legislação aplicável.</p>

                            <h3>COLETA DE DADOS</h3>
                            <p>A presente Política de Privacidade descreve de forma simples, transparente e objetiva quais dados e informações serão obtidos do Titular, bem como a forma em que estes dados são utilizados e protegidos.</p>
                            <p>Para fins de melhor compreensão, conforme define a LGPD, os dados são caracterizados em três tipos:</p>
                            <p>i) Pessoais: Todo e qualquer tipo de informação relacionada a pessoa natural identificada ou identificável;</p>
                            <p>ii) Pessoais Sensíveis: Origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural;</p>
                            <p>iii) Anonimizados: dados relativos a titular que não possa ser identificado pela utilização de meios técnicos razoáveis e disponíveis na ocasião de seu tratamento.</p>
                            <p>Quanto à coleta destes dados, a Controladora os obtém quando o Titular interage com os sistemas do Grupo Autorede, por meio de websites e redes sociais, ou ainda interage pessoalmente, por telefone ou qualquer outra forma de contato. Também são coletados dados por meio da utilização de “cookies” e outras tecnologias similares.</p>
                            <p>As informações coletadas incluem, mas não se limitam, a:</p>
                            <p>i) nome;</p>
                            <p>ii) endereço de e-mail;</p>
                            <p>iii) endereço de correspondência;</p>
                            <p>iv) número de telefone;</p>
                            <p>v) informações bancárias e/ou de cartões de crédito;</p>
                            <p>vi) informações de conta de mídia social;</p>
                            <p>vii) informações de perfis públicos;</p>
                            <p>viii) documentos pessoais fornecidos; e</p>
                            <p>ix) informações sobre terceiros, como parentes, condutores principais/adicionais, sócios, representante legais.</p>
                            <p>A aceitação a esta Política de Privacidade se dará no momento do acesso do Titular às páginas pertencentes ao Grupo Autorede ou ao atendimento físico, telefônico ou eletrônico.</p>
                            <p>Ainda, cumpre informar que, aqueles dados considerados pela legislação vigente como sensíveis somente podem ser coletados com autorização expressa e específica do Titular, ressalvando os casos descritos na LGPD.</p>

                            <h3>DIREITOS DO TITULAR</h3>
                            <p>Seguindo os princípios elencados nesta Política de Privacidade e em conformidade com a LGPD, têm-se como direitos do Titular dos dados coletados e tratados pelo Grupo Autorede:</p>
                            <p>i) acessar seus dados em controle da empresa;</p>
                            <p>ii) retificar dados incompletos, inexatos ou desatualizados;</p>
                            <p>iii) requerer a anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a LGPD;</p>
                            <p>iv) requerer a transmissão de dados a outro fornecedor de serviço ou produto;</p>
                            <p>v) requerer a eliminação de dados;</p>
                            <p>vi) informação das entidades públicas e privadas com as quais a empresa Controladora dos dados realizou seu uso compartilhado; e</p>
                            <p>vii) informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa.</p>
                            <p>Ainda, frisa-se que, a qualquer momento, sem necessidade de prévia notificação, pode o Titular revogar o consentimento previamente fornecido. A solicitação da revogação deverá ser feita de maneira formal e escrita, direcionada aos contatos e endereços informados nesta Política de Privacidade.</p>
                            <p>É garantido à empresa Controladora dos dados coletados o tratamento destes até a data da solicitação, com base no consentimento sendo revogado. Dados abrangidos pelas hipóteses do artigo 16 da LGPD, por motivos administrativos e gerenciais, somente serão eliminados após cumpridas suas finalidades.</p>

                            <h3>DESTINAÇÃO E USO DOS DADOS COLETADOS</h3>
                            <p>Os dados coletados pelas empresas do Grupo Autorede têm o propósito serão sempre utilizados com o intuito de atender o exato propósito pelo qual foram coletados, servindo para o estabelecimento de vínculo contratual entre o Titular e a Controladora, ou para gestão, administração e aprimoramento das páginas do Grupo, para melhor atendimento ao Titular.</p>
                            <p>Os dados serão utilizados para:</p>
                            <p>i) cumprimento das obrigações legais e regulatórias do Grupo Autorede;</p>
                            <p>ii) execução de contratos e procedimentos de gestão relacionados a contratos que envolvam o Titular;</p>
                            <p>iii) envio de informação sobre produtos, bens e serviços comercializados e prestados pelo Grupo Autorede;</p>
                            <p>iv) exercício regular de direitos em processo judicial, administrativo ou arbitral, conforme a legislação aplicável;</p>
                            <p>v) atender aos interesses legítimos do Grupo Autorede;</p>
                            <p>vi) proteção do crédito.</p>
                            <p>As empresas do Grupo Autorede estão autorizadas, por meio desta Política de Privacidade, a enviarem e-mails marketing, divulgação de material publicitário por meio de websites, aplicativos, mídias sociais for business, serviços de mensagem, pesquisas, bem como todo e qualquer forma marketing direto ou indireto para o Titular. As informações que serão enviadas ao Titular poderão ser de interesse destes, como por exemplo oferta de produtos e/ou últimas notícias referentes ao core business das empresas do Grupo.</p>
                            <p>Em caso de oposição ao recebimento, o Titular poderá requerer, a qualquer tempo e de forma gratuita, o cancelamento do seu cadastro e/ou o esquecimento dos dados armazenados no ambiente do Grupo Autorede.</p>

                            <h3>COMPARTILHAMENTO DE DADOS</h3>
                            <p>Dados pessoais coletados pela Controladora podem ser compartilhados com os seguintes destinatários:</p>
                            <p>(i) fornecedores de serviços para a Controladora, responsáveis por auxiliar na sua própria prestação de serviço e disponibilização de seus produtos;</p>
                            <p>(ii) parceiros envolvidos em relações comerciais com a Controladora, para desenvolvimento das atividades, bem como para fins publicitários; e</p>
                            <p>(iii) administração pública, em caso de obrigação legal ou judicial, para proteção de direitos, cumprimento de deveres, garantia de segurança a clientes, investigação de fraudes e/ou respostas a questionamentos públicos.</p>
                            <p>Em caso de transferência internacional de dados, a Controladora se compromete a observar, na transmissão, o cumprimento de, ao menos, uma das seguintes hipóteses:</p>
                            <p>(i) que o destinatário esteja em país que assegure um nível de proteção de dados ao menos equiparável com a legislação brasileira;</p>
                            <p>(ii) quando a transferência for necessária para a cooperação judicial internacional entre órgãos públicos de inteligência e de investigação, de acordo com as normas de direito internacional;</p>
                            <p>(iii) quando a transferência for necessária para a proteção da vida ou incolumidade física do Titular ou de terceiros;</p>
                            <p>(iv) quando o órgão competente autorizar a transferência; e</p>
                            <p>(v) quando o Titular tiver fornecido o seu consentimento para a transferência, nos termos desta Política de Privacidade.</p>

                            <h3>SEGURANÇA DOS DADOS</h3>
                            <p>Todos os dados coletados são processados e armazenados da forma mais segura, por meio da adoção de práticas e medidas de segurança de cunho técnico, administrativo e organizacional, todas aptas à sua íntegra proteção, conforme critérios definidos pelos melhores padrões de mercado e em legislação aplicável.</p>
                            <p>A segurança dos dados visa sua proteção contra situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito, bem como processamento discriminatório de informações.</p>
                            <p>O compromisso do Grupo Autorede com o tratamento adequado das informações internas, de clientes e públicas em geral está fundamentado nos seguintes princípios:</p>
                            <p>(i) Confidencialidade: para que o acesso à informação seja feito somente por pessoas autorizadas e quando realmente necessário;</p>
                            <p>(ii) Disponibilidade: de modo que as pessoas autorizadas tenham acesso à informação sempre que necessário; e</p>
                            <p>(iii) Integridade: com a exatidão e a completude da informação e seus respectivos métodos de processamento, além da transparência no trato com os envolvidos.</p>
                            <p>O Grupo Autorede se compromete a informar ao Titular e aos órgãos de proteção de dados qualquer incidente de segurança que possa acarretar risco ou prejuízo relevante aos Titular.</p>

                            <h3>SITES E SERVIÇOS DE TERCEIROS</h3>
                            <p>Os portais, sites e plataformas gerenciadas pelo Grupo Autorede poderão conter links para websites e aplicativos de terceiros. Em caso de utilização destes pelo Titular, há possibilidade de coleta, processamento e compartilhamento de informações por terceiros não vinculados ao grupo, não sendo, portanto, de responsabilidade do Grupo Autorede o conteúdo ou as práticas de privacidades destes terceiros.</p>

                            <h3>CONTATO COM O GRUPO AUTOREDE</h3>
                            <p>Em caso de dúvidas, sugestões ou reclamações acerca desta Política de Privacidade ou de práticas em relação aos dados coletados pelo Grupo Autorede, gentileza entrar em contato com a área responsável pela Gestão da Segurança da Informação e Riscos Cibernéticos, por meio do e-mail juridico@autorede.com.br, ou por envio de correspondência aos cuidados deste área no endereço Av. Barão Homem de Melo, nº 3.030, Bairro Estoril, Belo Horizonte/MG, CEP 30494-080, Brasil.</p>
                            <Button
                                className='buttonColorSecond smallButton'
                                label='voltar'
                                onClick={handleGoBack}
                            />
                        </div>
                    </div>
                ) : configData.defaultStore === 'Iesa' ? (
                    <div className='card'>
                        <div className='column legalTerm' style={{padding: '20px', gap: '10px'}}>
                            <h3>POLÍTICA DE PRIVACIDADE</h3>
                            <p>O GRUPO IESA respeita a sua privacidade e compreende que o tratamento dos seus dados pessoais requer a sua confiança. Por isso, desenvolvemos essa Política de Privacidade (“Política de Privacidade” ou “Política”), que descreve nossas práticas relacionadas ao tratamento de dados pessoais, em conformidade com as exigências legais, e o nosso compromisso com você. Caso não concorde com a presente Política de Privacidade, por favor, não navegue no website e, tampouco, forneça seus dados pessoais para nós. Podemos alterar a Política de Privacidade a qualquer momento, por isso solicitamos que você a consulte periodicamente, de preferência sempre que voltar a acessar nosso website e sempre que optar por nos fornecer seus dados pessoais. Nós avisaremos quando
                            fizermos qualquer alteração substancial, inclusive para que você possa manifestar sua concordância ao acessar o nosso website. Note que sempre informamos, ao final desta Política, a data da sua última atualização. Alguns produtos ou serviços promovidos pelo Grupo Iesa (como, por exemplo, aplicativos para telefones celulares ou de conectividade de veículos, dentre outros) poderão apresentar políticas de privacidade próprias, as quais descreverão suas próprias regras de tratamento de dados pessoais inerentes ao seu funcionamento. A presente Política de Privacidade não substitui as regras específicas aplicáveis a tais produtos e serviços.</p>

                            <h3>1. Nosso compromisso em proteger sua privacidade e dados pessoais</h3>
                            <p>A presente Política de Privacidade estabelece a forma como o Grupo IESA trata os dados pessoais dos seus clientes e dos seus potenciais clientes e é aplicável aos dados fornecidos a partir dos websites descritos abaixo, das ferramentas de integração com redes sociais, bem como de eventos e ações promocionais realizadas pelo Grupo IESA.</p>

                            <p>https://www.grupoiesa.com.br</p>

                            <p>https://www.iesabmw.com.br</p>

                            <p>https://www.iesamotorrad.com.br</p>

                            <p>https://www.iesamini.com.br</p>

                            <p>https://www.iesavolvo.com.br</p>

                            <p>https://www.iesabyd.com.br</p>

                            <p>https://www.iesaharleydavidson.com.br</p>

                            <p>https://www.iesagwm.com.br</p>

                            <p>https://www.iesaram.com.br</p>

                            <p>https://www.iesajeep.com.br</p>

                            <p>https://www.iesafiat.com.br</p>

                            <p>https://www.iesahyundai.com.br</p>

                            <p>https://www.iesanissan.com.br</p>

                            <p>https://www.iesarenault.com.br</p>

                            <p>https://www.iesashop.com</p>

                            <p>https://www.iesaemobility.com.br</p>

                            <p>Para fins desta Política de Privacidade, é importante que Você conheça algumas definições:</p>
                            <ul>
                                <li>Titular – Você é o titular de seus dados pessoais;</li>
                                <li>Dados pessoais - toda e qualquer informação que identifique ou leve à identificação de um Titular, como por exemplo, seu nome completo, RG, CPF e endereço;</li>
                                <li>Tratamento de dados – toda e qualquer operação realizada com os dados pessoais, como a produção, recepção, classificação, utilização, acesso, reprodução, transmissão, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, etc;</li>
                                <li>Anonimização – Processo de despersonalização dos dados pessoais por meio da remoção de qualquer associação destas informações aos titulares a quem elas se referem.</li>
                            </ul>

                            <p>Somos adeptos de elevados padrões de segurança, tratando seus dados pessoais com sigilo e apenas utilizando-os para as finalidades identificadas e de acordo com os seus direitos. Tratamos esse assunto de forma transparente e por isso, desde o início, queremos que você saiba:</p>

                            <ul>
                                <li>Durante a navegação em nosso Website ou a partir dos outros meios submetidos a esta Política de Privacidade, poderemos solicitar ao Titular o fornecimento, em caráter voluntário, de dados pessoais;</li>

                                <li>Ao fornecer voluntariamente seus dados pessoais e aceitar esta Política, o Titular autoriza o Grupo Iesa a realizar o tratamento de dados, o que pode incluir o compartilhamento e transferência de dados com montadoras concedentes vinculadas e empresas parceiras, caso necessário.</li>

                                <li>Por empresas parceiras, entende-se não apenas aquelas relacionadas às ações de marketing ou indispensáveis para a atender à solicitação de serviço/produto, como também aos nossos fornecedores e outras que sejam relacionadas e necessárias para o cumprimento de obrigações legais ou regulatórias, por exemplo.</li>

                                <li>Por montadoras concedentes, entende-se não apenas aquela relacionada à relação negocial, mas outras representadas pelo grupo IESA.</li>

                                <li>Ao solicitar ao Grupo Iesa a prestação de qualquer serviço ou adquirir qualquer produto, o Titular fica ciente de que serão requeridos seus dados pessoais e que esses poderão ser transferidos entre diferentes setores da concessionária e/ou para outras empresas, para os procedimentos relacionados à prestação do serviço solicitado ou entrega do produto adquirido.</li>

                                <li>Nossa intenção é coletar e tratar a menor quantidade possível de dados pessoais e por isso pretendemos tratar os dados que forem necessários para atender às finalidades aqui descritas e lhe oferecer uma melhor experiência enquanto cliente e/ou potencial cliente.</li>
                            </ul>

                            <p>O titular dos dados pessoais é Você e nós respeitamos seus direitos em relação a eles. Você poderá exercê-los conforme descrito nesta Política.</p>

                            <h3>2. Das empresas abrangidas por esta Política de Privacidade:</h3>
                            <p>A presente Política de Privacidade é observada pelas seguintes empresas e suas filiais:</p>

                            <p>SCHONSTATT VEICULOS LTDA, inscrita no CNPJ/ME sob o nº 20.491.112/0001-08;</p>

                            <p>SACRE COEUR VEICULOS LTDA, inscrita no CNPJ/ME sob o nº 13.268.423/0001-39;</p>

                            <p>VIVERE VEICULOS LTDA, inscrita no CNPJ/ME sob o nº 11.408.138/0001-40;</p>

                            <p>NOTRE DAME VEICULOS LTDA, inscrita no CNPJ/ME sob o nº 10.658.023/0001-41;</p>

                            <p>RUAH VEICULOS LTDA, inscrita no CNPJ/ME sob o nº 04.130.692/0001-43;</p>

                            <p>IESA VEICULOS LTDA, inscrita no CNPJ/ME sob o nº 01.304.136/0001-58;</p>

                            <p>RUAH LOCADORA DE VEICULOS LTDA, inscrita no CNPJ/ME sob o nº 07.141.180/0001-70;</p>

                            <p>COXILIA CORRETORA DE SEGUROS LTDA, inscrita no CNPJ/ME sob o nº 11.101.079/0001-20;</p>

                            <p>NAZARETH VEICULOS LTDA, inscrita no CNPJ/ME sob o nº 09.298.635/0001-91;</p>

                            <p>TRINITA VEICULOS LTDA, inscrita no CNPJ/ME sob o nº 11.475.046/0001-83;</p>

                            <p>HOSANA VEICULOS LTDA, inscrita no CNPJ/ME sob o nº 48.954.185/0001-08;</p>

                            <p>representadas na forma de seus atos constitutivos.</p>

                            <h3>3. Quem é responsável pelo tratamento dos seus dados pessoais</h3>
                            <p>O Grupo Iesa é o responsável pelo tratamento dos dados pessoais decorrentes do acesso ao website e dos dados obtidos pelos demais meios submetidos a esta Política de Privacidade. Você está ciente e concorda que podemos compartilhar seus dados com terceiros que nos auxiliam na operacionalização desse tratamento, como empresas de tecnologia, armazenamento em nuvem e outros parceiros comerciais.</p>

                            <p>Caso você nos faça qualquer solicitação amparada pelos seus direitos como titular, conforme estabelecidos pela LGPD, empenharemos nossos melhores esforços para garantir que essas solicitações sejam encaminhadas para o responsável pelo tratamento correto, a fim de lhe ser dada a necessária resposta. Isso pode incluir o encaminhamento da solicitação para eventuais terceiros com os quais tenhamos compartilhado os dados, para que estes nos prestem algum serviço para te atender. No entanto, sempre que necessário e possível, informaremos a Você a quem deverá direcionar sua solicitação, caso não possamos te atender.</p>

                            <h3>4. Em quais situações tratamos os seus dados pessoais.</h3>
                            <p>Os seus dados pessoais poderão ser coletados e tratados pelo Grupo Iesa nas seguintes situações:</p>
                            
                            <ul>
                                <li>Caso nos contate, por meio do Website, das ferramentas de interação via redes sociais ou do serviço de apoio ao cliente do Grupo Iesa, e se estiver interessado, por exemplo, nos nossos produtos e serviços e/ou outras solicitações;</li>

                                <li>Caso adquira diretamente produtos ou serviços;</li>

                                <li>Caso responda a uma das nossas campanhas ou canais de marketing (por exemplo, mediante o preenchimento de um formulário em eventos ou da disponibilização dos seus dados online em um de nossos sites ou página inicial) e opte por receber material de marketing pelo Grupo Iesa e Empresas Parceiras;</li>

                                <li>Se os seus dados nos forem enviados por alguma das montadoras concedentes, por nossos parceiros comerciais ou outros terceiros, desde que observados os requisitos da legislação;</li>

                                <li>Quando nos disponibiliza os dados de seu veículo, incluindo o número de identificação, no âmbito do processo de serviço e manutenção/serviço de reparação realizados;</li>

                                <li>Quando nos disponibiliza os dados pessoais e de seu veículo em campanhas de Recall;</li>

                                <li>Quando nos disponibiliza seus dados para quaisquer tipos de contato ou interação com nosso serviço de atendimento ao cliente, seja por telefone ou pelo formulário disponibilizado neste site;</li>

                                <li>Quando disponibiliza seus dados para simulação de produtos ou serviços;</li>

                                <li>Quando houver fundamento legal, poderemos obter determinadas informações diretamente junto à determinadas empresas, tais como agências de avaliação/controle de crédito;</li>

                                <li>Participação em concurso, sorteio, questionário ou processo seletivo.</li>
                            </ul>

                            <h3>5. Quais dados pessoais podem ser tratados</h3>
                            <p>Como mencionamos, a intenção do Grupo Iesa é tratar a menor quantidade possível de dados pessoais, por isso buscamos nos limitar aos dados que forem necessários para atender nossos clientes e/ou potenciais clientes e lhes oferecer uma melhor experiência. Por isso, a depender da finalidade, podemos tratar os seguintes dados:</p>

                            <ul>
                                <li>Dados de contato: Nome, endereço, número de telefone e endereço de e-mail.</li>

                                <li>Dados para emissão de ordens de compra de produtos e de serviços, de recibo e/ou nota fiscal de aquisição de produtos e serviços: Nome, endereço, número de telefone, endereço de e-mail, CPF, RG, estado civil.</li>

                                <li>Outros dados pessoais: Informação que você nos tenha oferecido espontaneamente, por exemplo, da sua data de aniversário, escolaridade, estado civil ou profissão.</li>

                                <li>Dados necessários para preenchimento de fichas cadastrais nos termos da Lei ou para correta identificação do cliente em caso de contato com nosso serviço de atendimento ao cliente.</li>

                                <li>Dados do contrato: Número de cliente, número do contrato, dados do veículo (placa e chassi).</li>

                                <li>Dados decorrentes dos protocolos e registros de acesso em nossos websites, como o IP (Internet Protocol), datas e horários de acesso.</li>

                                <li>Informação acerca da forma como utiliza o website e sobre se abriu ou reencaminhou mensagens nossas, incluindo dados recolhidos por meio de cookies, mediante consentimento do usuário e política própria, disponível na seção Política de Cookies.</li>

                                <li>Dados de transações e interação: Informação sobre aquisição de produtos ou serviços, interação com o serviço de apoio ao cliente (os seus pedidos e/ou eventuais reclamações), bem como participação em pesquisas de mercado e também por meio dos formulários “Solicite uma Proposta” e “Atualize seu Cadastro”.</li>
                            </ul>

                            <h3>6. Para quais finalidades tratamos os seus dados pessoais</h3>
                            <p>O Grupo Iesa obtém seus dados pessoais no contexto de acesso a este website e/ou pelos demais meios submetidos a esta Política de Privacidade e eles podem ser tratados para as finalidades abaixo identificadas:</p>

                            <p>A. Cumprimento de obrigações contratuais no âmbito da oferta, venda, e manutenção de veículos ou produtos financeiros.</p>

                            <p>O Grupo Iesa realiza o tratamento de dados pessoais de clientes e potenciais clientes no âmbito da oferta, venda e manutenção de veículos. Esses dados podem ser obtidos diretamente pelo Grupo Iesa mediante compartilhamento ou transferência de dados por parte destes, desde que observados os requisitos da legislação.</p>

                            <p>A finalidade deste tratamento é garantir o regular cumprimento do contrato e/ou atendimento de solicitação do cliente, por exemplo, para encomenda de veículos, ordens de reparação/oficina, garantia contratual, agendamento de serviços ou para resposta a pedidos de proposta, de test-drive, dúvidas e reclamações, dentre outros.</p>

                            <p>O Grupo Iesa também trata os seus dados para otimizar a sua experiência com o serviço de apoio ao cliente do Grupo Iesa, por exemplo, para o identificar corretamente sempre que entrar em contato conosco.</p>

                            <p>B. Assegurar a qualidade dos produtos, pesquisa e desenvolvimento de novos produtos.</p>

                            <p>O Grupo Iesa diretamente, ou por meio de seus parceiros, realiza o tratamento de dados pessoais com o objetivo de desenvolver melhorias e atender as expectativas dos seus Clientes no que diz respeito aos elevados padrões de qualidade dos produtos e serviços, no ensejo de disponibilizar soluções inovadoras e atualizadas.</p>

                            <p>Compatibilizamos a proteção dos dados do Titular com a necessidade de desenvolvimento econômico e tecnológico.</p>

                            <p>C. Ações de marketing, comunicações promocionais e pesquisas de mercado.</p>

                            <p>Caso nos tenha dado o seu consentimento para a utilização adicional dos seus dados, estes poderão ser utilizados em conformidade com a respectiva declaração de consentimento. É o caso, por exemplo, do consentimento para o tratamento de dados para fins promocionais e ações de marketing (ofertas de produtos e serviços e envio de material), definição de perfil individual de cliente e/ou pesquisas de mercado (não relacionadas com o contrato e/ou serviço).</p>

                            <p>Você está ciente e concorda que poderemos transferir ou compartilhar esses dados com nossos parceiros comerciais e serviços de assistência, sempre respeitada a finalidade e os limites do termo de consentimento fornecido.</p>

                            <p>D. Cumprimento de obrigações legais ou regulatórias que o Grupo Iesa se encontre sujeita, bem como para exercício regular de direitos em processo judicial, administrativo ou arbitral.</p>

                            <p>O Grupo Iesa encontra-se sujeito a uma multiplicidade de obrigações legais. Poderemos tratar os seus dados pessoais na medida do necessário para cumprimento destas obrigações, bem como transmiti-los às autoridades mediante solicitação.</p>

                            <p>E. Realizar avaliação de crédito, prevenção de fraude e aplicação de termos e condições dos produtos e serviços, conforme necessário.</p>

                            <p>Para cumprir as obrigações decorrentes dos Serviços e dos contratos assinados por Você, inclusive para o processamento de operações financeiras.</p>

                            <p>Trataremos seus dados também para proteger o crédito de forma geral, seus riscos associados e no cumprimento de obrigações legais e regulatórias.</p>

                            <h3>7. Com quem podemos compartilhar seus dados</h3>
                            <p>Ao aceitar essa Política de Privacidade, você está ciente e concorda que em alguns casos, após uma verificação cuidadosa, o Grupo Iesa poderá transferir os seus dados para as montadoras concedentes das marcas do Grupo Iesa ou outros parceiros comerciais, desde que necessário. A partir desse momento tais empresas passarão, elas próprias, a serem responsáveis pelo tratamento dos seus dados pessoais, com os padrões de segurança necessários à proteção dos dados. Por empresas parceiras entende-se não apenas aquelas relacionadas às ações de marketing ou indispensáveis para atender à solicitação de serviço/produto, como também nossos fornecedores (inclusive de serviços de tecnologia, armazenamento em nuvem, etc.) e outras que sejam relacionadas e necessárias para atender o Grupo IESA no âmbito de suas atividades, conforme descritas nesta política.</p>

                            <p>Poderá haver a transferência de dados para bases de dados fisicamente localizadas em outros países, igualmente responsáveis pelo tratamento, com o mesmo padrão de segurança em nível global.</p>

                            <p>Essa transferência a outras empresas poderá ocorrer para fins de cumprimento dos processos de venda, serviço e administração, bem como para assegurar a qualidade dos produtos, pesquisa e desenvolvimento de novos produtos. De toda forma, realizaremos a transferência internacional apenas quando necessário e segundo as disposições da LGPD sobre o tema, em especial, para países ou organismos internacionais que proporcionem grau de proteção de dados pessoais adequado ao previsto na LGPD, ou quando estes receptores se comprometerem contratualmente a garantir cumprimento dos princípios, dos direitos do titular e do regime de proteção de dados previstos na LGPD.</p>

                            <p>O compartilhamento de dados com entidades terceiras poderá ocorrer nas finalidades descritas nesta Política de Privacidade, no âmbito da prestação de serviços, oferta e comercialização de seus produtos, no desenvolvimento de melhorias e atenção às expectativas dos Clientes aos elevados padrões de qualidade do Grupo Iesa. Ao aceitar essa política, você concorda com esse tratamento.</p>

                            <p>Por fim, em caso de determinação legal, requerimento, requisição ou ordem judicial, poderemos proceder o compartilhamento de dados com autoridades judiciais, administrativas ou governamentais competentes.</p>


                            <h3>8. Durante quanto tempo armazenamos os seus dados pessoais</h3>
                            <p>Armazenaremos os seus dados apenas pelo período estritamente necessário para as respectivas finalidades de tratamento ou pelo prazo legal, quando aplicável.</p>

                            <p>Os dados pessoais tratados para fins de ações de marketing, comunicações promocionais e pesquisas de mercado serão conservados por prazo indeterminado a partir da coleta do seu consentimento.</p>

                            <p>Resguardamos, contudo, o seu direito de, a qualquer tempo, revogar o consentimento para tal tratamento.</p>

                            <h3>9. Transferência internacional de dados</h3>
                            <p>Os seus dados pessoais podem ser armazenados fora do Brasil pelos nossos fornecedores ou prestadores de serviços ou por empresas parceiras, sempre para as finalidades descritas nesta Política de Privacidade.</p>

                            <p>Ao concordar com esta Política de Privacidade, você também entende e consente com essa “transferência internacional de dados”, como previsto na legislação brasileira.</p>

                            <p>“transferência internacional de dados”, como previsto na legislação brasileira. Garantimos que os seus dados pessoais eventualmente transferidos para outros países também serão tratados de acordo com as medidas de segurança apropriadas no exterior, e segundo as disposições da LGPD sobre o tema, em especial, para países ou organismos internacionais que proporcionem grau de proteção de dados pessoais adequado ao previsto na LGPD, ou quando estes receptores se comprometerem contratualmente a garantir cumprimento dos princípios, dos direitos do titular e do regime de proteção de dados previstos na LGPD.</p>

                            <h3>10. Como mantemos os seus dados pessoais seguros</h3>
                            <p>O Grupo IESA envida os melhores esforços para implementar e manter medidas de segurança física, eletrônica e procedimental destinadas a proteger os seus dados pessoais em conformidade com os requisitos de proteção de dados aplicáveis. Dentre outras. Proteção dos sistemas de TI através de firewalls e controles de acesso restrito e monitorização permanente dos acessos aos sistemas de tecnologias da informação, tudo para o fim de prevenir e impedir o acesso, divulgação e uso indevido dos seus dados pessoais.</p>

                            <p>Embora nossas medidas de segurança sejam tomadas para evitar ao máximo qualquer exposição de seus dados pessoais, reconhecemos que a internet traz riscos a ela inerentes e entendemos que não podemos anulá-los por completo. No entanto, garantimos que a proteção de seus dados pessoais é uma das nossas prioridades e que, caso algum incidente aconteça, adotaremos todas as medidas possíveis para minimizar eventuais danos.</p>

                            <h3>11. Quais são os seus direitos enquanto Titular e como exercê-los</h3>
                            <p>A legislação brasileira te concede uma série de direitos em relação aos seus dados pessoais.</p>

                            <p>Veja abaixo os Seus principais direitos relacionados aos dados pessoais:</p>

                            <ul>
                                <li>Solicitar a confirmação da existência de tratamento de dados pessoais;</li>

                                <li>Ter acesso aos seus dados, por meio da solicitação de disponibilização de uma cópia dos dados pessoais que nos forneceu;</li>

                                <li>A correção de dados incompletos, inexatos ou desatualizados;</li>

                                <li>A revogação, a qualquer momento, do seu consentimento anteriormente concedido para o tratamento de dados (o que terá efeitos para o futuro), bem como a informação sobre a possibilidade de você não consentir com determinado tratamento de dados e as consequências de não consentir;</li>

                                <li>A anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a lei, nas hipóteses legais;</li>

                                <li>A portabilidade dos seus dados pessoais a outro fornecedor de serviço ou produto, observados os segredos comercial e industrial;</li>

                                <li>A informação sobre as entidades públicas e privadas com as quais realizamos uso compartilhado de dados;</li>

                                <li>A revisão de decisões tomadas unicamente com base em tratamento automatizado de dados pessoais. O exercício destes direitos pode eventualmente se sujeitar a exceções destinadas, por exemplo, mas não se limitando, à salvaguarda de interesse público ou do nosso interesse (manutenção do sigilo profissional, comercial e industrial, prazos de garantia e obrigações fiscais, exercício regular de direitos, entre outros), nos termos da lei.</li>
                            </ul>
                            <p>Você pode exercer seus direitos gratuitamente pelo nosso site www.grupoiesa.com.br, ou através dos nossos websites destinados a cada marca conforme descritos no item 1. Para isso, registre sua solicitação clicando no botão “LGPD – Exerça seus direitos” que se localiza ao final da página e sua solicitação será analisada pelo Grupo IESA.</p>

                            <p>Em certos casos e com base na lei, se sua solicitação não puder ser atendida, lhe informaremos sempre mediante justificativa.</p>

                            <h3>12. Blocos de Dados (Cookies)</h3>
                            <p>O Grupo IESA usa blocos de dados (cookies) para rastrear as preferências do usuário e para atingir um projeto correspondentemente otimizado do website. Os blocos de dados (Cookies) são pequenos arquivos armazenados em seu disco rígido. Isto permite uma navegação mais fácil e um alto grau de facilidade para o usuário em um site da web.</p>

                            <p>Os blocos de dados (Cookies) também nos ajudam a identificar áreas particularmente populares do nosso material na internet. Isto nos permite personalizar o conteúdo de nossas páginas da internet conforme suas necessidades específicas e assim melhorar o serviço de internet que oferecemos a você.</p>

                            <p>Os blocos de dados (Cookies) podem ser usados para determinar se uma conexão já foi feita anteriormente de seu computador para nossos sites. Estes blocos de dados permitem identificar apenas o seu computador. Dados pessoais podem ser armazenados em Cookies, desde que você concorde, por exemplo, para facilitar o acesso seguro online para que você não tenha que fornecer usuário e senha repetidamente.</p>

                            <p>Naturalmente, você também pode visualizar nosso site da web sem quaisquer blocos de dados (cookies). A maioria dos navegadores aceitam os blocos de dados automaticamente. Você pode evitar que os blocos de dados (cookies) sejam salvos em seu disco rígido selecionando a opção "não aceitar blocos de dados (cookies)" no seu ajuste de navegação.</p>

                            <p>Para descobrir como isso funciona em seu caso em particular, vide instruções do fornecedor de seu navegador. Além disso, você pode apagar o bloco de dados (cookie) da pasta de seu sistema a qualquer momento. Se, contudo, você decidir não aceitar os blocos de dados (cookies), isto pode limitar as funções disponíveis para você em nossos sites.</p>

                            <h3>13. Modificação e Atualização da Política</h3>
                            <p>O Grupo Iesa reserva-se no direito de modificar esta Política de Privacidade de acordo com a legislação aplicável, a qualquer tempo. Eventual nova versão, será publicada neste website, indicando a data da última revisão. Se não concordar com as alterações, Você deve interromper imediatamente o uso do website e pode utilizar os canais de contato para apresentar pedidos do seu interesse e exercer seus direitos.</p>

                            <p>Última revisão do texto: 28/05/2024.</p>

                            <Button
                                className='buttonColorMain smallButton'
                                label='LGPD - Exerça seus direitos'
                                onClick={() => window.open('https://portaldotitular.safecomply.com.br/safecomply/grupoiesa', '_blank')}
                            />

                            <Button
                                className='buttonColorSecond smallButton'
                                label='voltar'
                                onClick={handleGoBack}
                            />
                        </div>
                    </div>
                ) : (
                    <div className='card'>
                        <div className='column legalTerm' style={{padding: '20px', gap: '10px'}}>
                            <h2>Política de Privacidade</h2>

                            {(configData.defaultStore !== 'Belcar Fiat' && configData.defaultStore !== 'Asuka')
                            ?
                                <>
                                    <p>A {configData.defaultStore} disponibilizou o aplicativo {configData.defaultStore} de forma gratuita. Este SERVIÇO é fornecido pela {configData.defaultStore} sem custos e deve ser utilizado no estado em que se encontra.</p>
                                    <p>Esta página é usada para informar os visitantes sobre nossas políticas de coleta, uso e divulgação de Informações Pessoais, caso decida usar nosso Serviço.</p>
                                    <p>Se você optar por usar nosso Serviço, você concorda com a coleta e uso de informações em relação a esta política. As informações pessoais que coletamos são usadas para fornecer e melhorar o serviço. Não usaremos ou compartilharemos suas informações com ninguém, exceto conforme descrito nesta Política de Privacidade.</p>
                                    <p>Os termos usados nesta Política de Privacidade têm os mesmos significados que em nossos Termos e Condições, que podem ser acessados em <a className="textLink" href={`https://${configData.defaultStoreLink}`} target='blank'>{configData.defaultStoreLink}</a>, a menos que definido de outra forma nesta Política de Privacidade.</p>
                                </>
                            :
                                <>
                                    {configData.defaultStore === 'Belcar Fiat' &&
                                        <p>A Belcar Fiat disponibiliza o aplicativo Belcar Fiat gratuitamente. Este serviço é oferecido sem custos e deve ser utilizado conforme disponibilizado.</p>
                                    }
                                    {configData.defaultStore === 'Asuka' &&
                                        <p>A Belcar Asuka disponibiliza o aplicativo Peças Asuka gratuitamente. Este serviço é oferecido sem custos e deve ser utilizado conforme disponibilizado.</p>
                                    }
                                    <p>Esta página tem o propósito de informar os usuários sobre nossas políticas de coleta, uso e divulgação de informações pessoais ao utilizarem nosso serviço.</p>
                                    <p>Ao optar por utilizar nosso serviço, você concorda com a coleta e o uso de suas informações conforme descrito nesta política. Os dados pessoais coletados são utilizados exclusivamente para fornecer e aprimorar nossos serviços. Não compartilharemos suas informações com terceiros, exceto nos casos previstos nesta Política de Privacidade.</p>
                                    <p>Os termos utilizados nesta Política de Privacidade possuem os mesmos significados estabelecidos em nossos Termos e Condições, disponíveis em belcarfiatpecas.com.br, salvo indicação contrária.</p>
                                </>
                            }

                            <h3>Coleta e uso de informações</h3>
                            <p>Para uma melhor experiência, ao usar nosso Serviço, podemos exigir que você nos forneça certas informações de identificação pessoal. As informações que solicitamos serão retidas por nós e usadas conforme descrito nesta Política de Privacidade.</p>
                            <p>O aplicativo usa serviços de terceiros que podem coletar informações usadas para identificá-lo.</p>
                            <p>Links para a Política de Privacidade de provedores de serviços terceirizados usados pelo aplicativo:</p>
                            <p><a className="textLink" href="https://www.google.com/policies/privacy/" target="_blank">• Google Play Services</a></p>
                            <p><a className="textLink" href="https://firebase.google.com/policies/analytics" target="_blank">• Google Analytics for Firebase</a></p>
                            <p><a className="textLink" href="https://firebase.google.com/support/privacy/" target="_blank">• Firebase Crashlytics</a></p>
                            <p><a className="textLink" href="https://www.facebook.com/about/privacy/update/printable" target="_blank">• Facebook</a></p>

                            <h3>Log Data</h3>
                            <p>Queremos informar que sempre que você utiliza nosso Serviço, em caso de erro no aplicativo, coletamos dados e informações (por meio de produtos de terceiros) em seu telefone denominado Log Data. Esses dados de registro podem incluir informações como endereço de protocolo de Internet ("IP") do dispositivo, nome do dispositivo, versão do sistema operacional, configuração do aplicativo ao utilizar nosso serviço, hora e data de uso do serviço e outras estatísticas.</p>

                            <h3>Cookies</h3>
                            <p>Cookies são arquivos com uma pequena quantidade de dados que são comumente usados como identificadores exclusivos anônimos. Eles são enviados para o seu navegador a partir dos sites que você visita e são armazenados na memória interna do seu dispositivo.</p>
                            <p>Este Serviço não usa esses "cookies" explicitamente. No entanto, o aplicativo pode usar código de terceiros e bibliotecas que usam "cookies" para coletar informações e melhorar seus serviços. Você tem a opção de aceitar ou recusar esses cookies e saber quando um cookie está sendo enviado para o seu dispositivo. Se você optar por recusar nossos cookies, poderá não ser capaz de usar algumas partes deste Serviço.</p>

                            <h3>Provedores de serviço</h3>
                            <p>Podemos empregar empresas terceirizadas e indivíduos pelos seguintes motivos:</p>
                            <p>• Para facilitar nosso Serviço;</p>
                            <p>• Para fornecer o Serviço em nosso nome;</p>
                            <p>• Para realizar operações relacionadas ao Serviço; ou</p>
                            <p>• Para nos ajudar a analisar como nosso Serviço é usado.</p>
                            <p>Queremos informar aos usuários deste Serviço que esses terceiros têm acesso às suas Informações Pessoais. O motivo é realizar as tarefas atribuídas a eles em nosso nome. No entanto, eles são obrigados a não divulgar ou usar as informações obtidas para qualquer outra finalidade.</p>

                            <h3>Segurança</h3>
                            {(configData.defaultStore !== 'Belcar Fiat' && configData.defaultStore !== 'Asuka')
                            ?
                                <p>Valorizamos sua confiança em nos fornecer suas informações pessoais, portanto, estamos nos empenhando para usar meios comercialmente aceitáveis de protegê-las. Mas lembre-se que nenhum método de transmissão pela internet, ou método de armazenamento eletrônico, é 100% seguro e confiável, e não podemos garantir sua segurança absoluta.</p>
                            :
                                <p>Prezamos pela sua confiança ao compartilhar suas informações pessoais conosco e, por isso, adotamos medidas de segurança técnicas e organizacionais adequadas para protegê-las, em conformidade com a Lei Geral de Proteção de Dados (Lei n° 13.709/2018). No entanto, é importante destacar que nenhum método de transmissão de dados pela internet ou de armazenamento eletrônico é completamente seguro. Embora nos esforcemos para garantir a máxima proteção, não podemos assegurar total inviolabilidade das informações.</p>
                            }
                            <h3>Links para outros sites</h3>
                            <p>Este serviço pode conter links para outros sites. Se você clicar em um link de terceiros, será direcionado a esse site. Observe que esses sites externos não são operados por nós. Portanto, recomendamos enfaticamente que você analise a Política de Privacidade desses sites. Não temos controle e não assumimos responsabilidade pelo conteúdo, políticas de privacidade ou práticas de quaisquer sites ou serviços de terceiros.</p>

                            <h3>Privacidade das crianças</h3>
                            <p>Esses Serviços não se dirigem a pessoas com menos de 14 anos. Não coletamos intencionalmente informações de identificação pessoal de crianças com menos de 14 anos. No caso de descobrirmos que uma criança menor de 14 anos nos forneceu informações pessoais, nós as apagamos imediatamente de nossos servidores. Se você é um pai ou responsável e sabe que seu filho nos forneceu informações pessoais, entre em contato para que possamos tomar as medidas necessárias.</p>

                            <h3>Mudanças nesta Política de Privacidade</h3>
                            <p>Podemos atualizar nossa Política de Privacidade de tempos em tempos. Portanto, é recomendável revisar esta página periodicamente para verificar quaisquer alterações. Iremos notificá-lo de quaisquer alterações, publicando a nova Política de Privacidade nesta página.</p>
                            <p>Esta política está em vigor a partir de {configData.launchDate}.</p>

                            <h3>Contate-Nos</h3>
                            <p>Se você tiver alguma dúvida ou sugestão sobre nossa Política de Privacidade, não hesite em nos contatar em <a href={`mailto:${configData.defaultContactEmail}`} target='blank'>{configData.defaultContactEmail}</a>.</p>
                            <Button
                                className='buttonColorSecond smallButton'
                                label='voltar'
                                onClick={handleGoBack}
                            />
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}
export default Privacy;
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import '../App.css';
import './VehicleSelect.css';
import { YearList } from '../Utilities/YearList';
import { BrandsList, BrandCodes } from '../Utilities/BrandsList';
import Button from './Button';
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import CartIcon from "@mui/icons-material/ShoppingCart";
import GenericVehicle from '../images/0.png';
import { Add, Edit, KeyboardArrowUp, NoCrash, SaveAltOutlined, Close } from '@mui/icons-material';
import { ADD_VEHICLE, setIsSelectingVehicle, setSelectedBrand, setSelectedYear, setSelectedModel, setSelectedVersion, eraseSelectedVehicle, setModelSeries, setCarID, setInternalID } from '../reducers/vehicle';
import ModelImgPH from '../images/models/8604.png';
import { loadAllCars } from '../reducers/vehicle';
import { CircularProgress } from '@mui/material';
import { ModelImgs } from '../Utilities/ModelImgs';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { configData } from '../Whitelabel/websitesData';

function VehicleSelect() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const { selectedBrand, isSelectingVehicle, selectedYear, selectedModel, selectedVersion, modelSeries, carID, InternalID } = useSelector(state => state.vehicle);
    
    const [loaded, setLoaded] = useState(false);
    const [modelCars, setModelsCars] = useState([]);
    const [filteredModelCars, setFilteredModelCars] = useState([]);
    const [modelCarsByYear, setModelCarsByYear] = useState([]);
    const [modelCarsVersion, setModelCarsVersion] = useState([]);
    // const [carID, setCarID] = useState(undefined);
    const [localModelSeries, setLocalModelSeries] = useState(modelSeries || '');
    // const [InternalID, setInternalID] = useState(undefined);
    const [licensePlate, setLicensePlate] = useState('');
    const [inputBorderColor, setInputBorderColor] = useState(''); // Default border color

    const [ loadingPlate, setLoadingPlate] = useState(false);

    const [filteredYearList, setFilteredYearList] = useState(YearList);

    const storeBrands = configData.brands;
    const includedBrands = configData.brands;

    useEffect(() => {
        dispatch(loadAllCars());
    }, [dispatch]);

    const allCars = useSelector(state => state.vehicle.allCars);

    useEffect(() => {
        if (allCars.length > 0 && modelCars.length === 0) {
            setModelsCars(allCars);
            setLoaded(true);
        }
    }, [allCars, modelCars.length]);
    
    useEffect(() => {
        if (selectedVersion) {
            dispatch({
                type: ADD_VEHICLE,
                payload: { 
                    brand: selectedBrand,
                    model: selectedModel, 
                    year: selectedYear, 
                    version: selectedVersion,
                    carId: carID,
                    InternalID: InternalID,
                    modelSeries: modelSeries,
                }
            });
        }
    }, [dispatch, navigate, selectedVersion, selectedBrand, selectedModel, selectedYear, modelSeries]);

    const loadCarsByBrandCode = (brand) => {
        // console.log('loadCarsByBrandCode called!');
        // console.log('modelCars: ', modelCars);
        const cars = modelCars.filter(item => item.brandID === brand);
        setFilteredModelCars(cars);
    
        // Find the minimum and maximum years in the filtered cars
        if (cars.length > 0) {
            const minYear = Math.min(...cars.map(car => car.yearStart));
            const maxYear = Math.max(...cars.map(car => car.yearEnd));
    
            // Generate a list of years between minYear and maxYear
            const yearRange = Array.from({ length: maxYear - minYear + 1 }, (v, k) => minYear + k).reverse();
            
            setFilteredYearList(yearRange);
        } else {
            // Reset to the original YearList if no cars are found for the brand
            setFilteredYearList(YearList);
        }
    };

    const filterModelsByYear = (year) => {
        const brandCode = BrandCodes[selectedBrand];
        const cars = filteredModelCars.filter(item => (item.yearStart <= year) && (item.yearEnd >= year) && item.brandID === brandCode);
        setModelCarsByYear(cars);
    }

    const filterModelsVersion = (model) => {
        const brandCode = BrandCodes[selectedBrand];
        const cars = modelCarsByYear.filter(item => item.name === model && item.yearStart <= selectedYear && item.yearEnd >= selectedYear && item.brandID === brandCode);
        setModelCarsVersion(cars);
    }

    const getUniqueModels = (models) => {
        const uniqueNames = new Set();
        return models.filter(model => {
            if (!uniqueNames.has(model.name)) {
                uniqueNames.add(model.name);
                return true;
            }
            return false;
        });
    };

    const uniqueModelCars = getUniqueModels(modelCarsByYear);
    uniqueModelCars.sort((a, b) => a.name.localeCompare(b.name));
    
    const resetBrand = () => {
        setModelsCars(allCars);
        dispatch(setSelectedBrand(undefined));
        dispatch(setSelectedYear(undefined));
        dispatch(setSelectedModel(undefined));
        dispatch(setSelectedVersion(undefined));
        dispatch(eraseSelectedVehicle());
    }
    
    const resetYear = () => {
        dispatch(setSelectedYear(undefined));
        dispatch(setSelectedModel(undefined));
        dispatch(setSelectedVersion(undefined));
    }
    
    const resetModel = () => {
        dispatch(setSelectedModel(undefined));
        dispatch(setSelectedVersion(undefined));
    }

    const resetVersion = () => {
        dispatch(setSelectedVersion(undefined));
    }

    const handleBrandSelect2 = (brand) => {
        loadCarsByBrandCode(BrandCodes[brand.toLowerCase()]);
        dispatch(setSelectedBrand(brand));
        dispatch(setSelectedYear(undefined));
        dispatch(setSelectedModel(undefined));
        dispatch(setSelectedVersion(undefined));
    };

    const handleYearChange2 = (year) => {
        filterModelsByYear(year);
        dispatch(setSelectedYear(year));
        dispatch(setSelectedModel(undefined));
        dispatch(setSelectedVersion(undefined));
    };

    const handleModelChange2 = (model) => {
        filterModelsVersion(model.name);
        dispatch(setSelectedModel(model.name));
        dispatch(setSelectedVersion(undefined));
        setLocalModelSeries(model.modelSeries);
        setModelSeries(model.modelSeries);
        //console.log('modelCarsVersion: ', modelCarsVersion);
    };
    
    // Add a useEffect hook to check modelCarsVersion updates
    useEffect(() => {
        if (modelCarsVersion.length === 1) {
            // Automatically select the only version option
            handleVersionChange2(modelCarsVersion[0].engine); // Pass the engine of the single version
        }
    }, [modelCarsVersion]);

    const handleVersionChange2 = (version) => {
        const selectedCar = modelCarsVersion.find(item => item.engine === version);
        dispatch(setSelectedVersion(version));
        dispatch(setCarID(selectedCar.carId));
        dispatch(setInternalID(selectedCar.InternalID));
        dispatch(setIsSelectingVehicle(false));
        navigate(`categorias/`);
        // if (!location.pathname.includes('minha-conta')) {
        //     navigate(`categorias/`);
        // }
    };

    const handleVehicleSave = () => {
        dispatch(setIsSelectingVehicle(false));
    };

    const handleGoToParts = () => {
        dispatch(setIsSelectingVehicle(false));
        navigate(`categorias/`);
    };

    const formatLicensePlate = (input) => {
        let formattedInput = input.toUpperCase().replace(/[^A-Z0-9]/g, '');

        if (formattedInput.length > 3) {
            formattedInput = formattedInput.slice(0, 3) + '-' + formattedInput.slice(3);
        }
    
        if (formattedInput.length > 8) {
            formattedInput = formattedInput.slice(0, 8);
        }
    
        return formattedInput;
    };

    const validateLicensePlate = (input) => {
        // Brazilian license plate pattern: 000-0000
        const regex = /^[A-Z0-9]{3}-[A-Z0-9]{4}$/;
        return regex.test(input);
    };

    async function loadCarbyCode(year, brand, modelo, cilindrada, modeloIPVA, modeloIPVAAux, fuel) {
        const allCars = [];
        let allCarsnoFilter = [];
      
        try {
          // First check for internet connection if running in a non-web environment
          if (typeof window === 'undefined') { // This is equivalent to !kIsWeb in Dart
            const result = await fetch('https://www.google.com');
            if (!result.ok) {
              throw new Error('Not connected to the internet');
            }
          }

        let dealersQuery = query(
            collection(getFirestore(), 'cars'),
            where('ipvaNames', 'array-contains', modeloIPVA.toLowerCase()),
            where('yearStart', '<=', year),
            where('yearEnd', '>=', year)
        );
        
        let snapProducts = await getDocs(dealersQuery);
        
        // Debugging output
        // console.log("Modelo IPVA:", modeloIPVA);
        // console.log("Year:", year);
        // console.log("Snap Products:", snapProducts);

          // If no results found for modeloIPVA, try modeloIPVAAux
          if (snapProducts.empty) {
            dealersQuery = query(
                collection(getFirestore(), 'cars'),
                where('ipvaNames', 'array-contains', modeloIPVAAux.toLowerCase())
              );
            snapProducts = await getDocs(dealersQuery);
          }
      
          // Convert Firestore documents to GPCar instances
          allCarsnoFilter = snapProducts.docs.map(doc => doc.data()); // Here we extract plain objects
      
        } catch (error) {
          console.error('Error fetching cars:', error);
          return new GPCar(); // Return an empty GPCar in case of error
        }
      
        // Filter based on cilindrada and fuel if more than 1 car is found
        if (allCarsnoFilter.length > 1) {
          if (cilindrada !== 0 && fuel.length > 0) {
            const mycar = getClosestValueInArray(allCarsnoFilter, cilindrada, fuel);
            if (mycar.brandID !== 0 && storeBrands.includes(mycar.brand.toLowerCase())) {
              allCars.push(mycar);
              return mycar;
            } else {
              return new GPCar(); // Return an empty GPCar if no match
            }
          }
        } else if (allCarsnoFilter.length === 1) {
          // Return the single car if it's found and is from a valid brand
          if (storeBrands.includes(allCarsnoFilter[0].brand.toLowerCase())) {
            allCars.push(allCarsnoFilter[0]);
            return allCarsnoFilter[0];
          }
        }
      
        // Return GPCar with -1 brandID if no car matches the store brands
        if (allCarsnoFilter.length > 0 && !storeBrands.includes(allCarsnoFilter[0].brand.toLowerCase())) {
          return new GPCar(-1);
        }
      
        // If nothing is found, return an empty GPCar
        return new GPCar();
      }
      
      // Helper functions (these will be part of your system)
      
      // GPCar model - Define according to your system
      class GPCar {
        constructor(brandID = 0) {
          this.brandID = brandID;
          this.brand = "";
          this.name = "";
          this.version = "";
          this.modelSeries = "";
        }
      
        static fromDocument(doc) {
          const data = doc.data();
          const car = new GPCar();
          car.brandID = data.brandID || 0;
          car.brand = data.brand || "";
          car.name = data.name || "";
          car.version = data.version || "";
          car.modelSeries = data.modelSeries || "";
          return car;
        }
      }
      
      // Example: getClosestValueInArray (adjust based on actual logic)
      function getClosestValueInArray(cars, cilindrada, fuel) {
        // Calculate the allowed variance (5%)
        const minCilindrada = cilindrada * 0.95;
        const maxCilindrada = cilindrada * 1.05;

        // Filter cars that have a cilindrada within the 5% range and match the fuel
        const filteredCars = cars.filter(car => {
            return (
            car.displacement >= minCilindrada &&
            car.displacement <= maxCilindrada &&
            car.fuel.toLowerCase() === fuel.toLowerCase()
            );
        });

        // If no cars meet the criteria, return null
        if (filteredCars.length === 0) {
            return null;
        }

        // Find the car with the closest cilindrada
        let closestCar = filteredCars[0];
        let smallestDifference = Math.abs(filteredCars[0].displacement - cilindrada);

        for (const car of filteredCars) {
            const difference = Math.abs(car.displacement - cilindrada);
            if (difference < smallestDifference) {
            smallestDifference = difference;
            closestCar = car;
            }
        }

        return closestCar;
    }

    const handleLicensePlateChange = (event) => {
        const input = event.target.value;
        const formatted = formatLicensePlate(input);
        setLicensePlate(formatted);
    };

    const handleLicensePlateSubmit = async (e) => {
        e.preventDefault();
        setLoadingPlate(true);
        if (validateLicensePlate(licensePlate)) {
            setInputBorderColor('');
            //console.log('Valid license plate:', licensePlate);
    
            const cleanPlate = licensePlate.replace('-', '');
            const url = `https://wdapi.com.br/placas/${cleanPlate}/17c5532261f53e63c29fefcef12d6ff3`;

            try {
                const response = await fetch(url);
                
                if (response.ok) {
                    const responseBody = await response.json(); // Parse the JSON response
            
                    // Extracting values from the response body
                    const brand = responseBody["MARCA"];
                    const year = parseInt(responseBody["anoModelo"]);
                    const model = responseBody["SUBMODELO"];
            
                    let modeloIPVA = "";
                    let modeloIPVAAux = "";
            
                    // Optional fields with error handling
                    try {
                    modeloIPVA = responseBody["extra"]["modelo"] || "";
                    } catch (e) {
                    modeloIPVA = "";
                    }
            
                    try {
                    modeloIPVAAux = responseBody["marcaModelo"] || "";
                    } catch (e) {
                    modeloIPVAAux = "";
                    }
            
                    let cilindrada = 0;
                    try {
                    cilindrada = parseInt(responseBody["extra"]["cilindradas"]) || 0;
                    } catch (e) {
                    cilindrada = 0;
                    }
            
                    let fuel = "";
                    try {
                    fuel = responseBody["extra"]["combustivel"] || "";
                    } catch (e) {
                    fuel = "";
                    }
                    let currentcar = await loadCarbyCode(year, brand, model, cilindrada, modeloIPVA, modeloIPVAAux, fuel);
                    dispatch(setInternalID(currentcar.InternalID));

                    dispatch(setSelectedBrand(currentcar.brand.toLowerCase()));
                    dispatch(setSelectedYear(year));
                    dispatch(setSelectedModel(model));
                    setLocalModelSeries(currentcar.modelSeries);
                    setModelSeries(currentcar.modelSeries);
                    dispatch(setSelectedVersion(currentcar.engine));
                    //console.log(currentcar.carId);
                    dispatch(setCarID(currentcar.carId));
                    dispatch(setIsSelectingVehicle(false));
                    
                    setLoadingPlate(false);
                    navigate(`categorias/`);
                    return;

                } else {
                    setLoadingPlate(false);
                    console.error(`Request failed with status: ${response.status}.`);
                    return 0;
                }
              } catch (error) {
                    setLoadingPlate(false);
                    console.error('An error occurred while fetching car details:', error);
                    return 0;
              }
    
            // Show confirmation dialog
            const userConfirmed = window.confirm('Work In Progress: abrir link com infos do veículo?');
    
            if (userConfirmed) {
                window.open(url, '_blank'); // Opens URL in a new tab if confirmed
            }
        } else {
            setLoadingPlate(false);
            setInputBorderColor('red'); // Set border color to red if invalid
        }
    };

    const localModelSeriesImg = (modelId) => {
        return ModelImgs[modelId] || GenericVehicle;
    };

    useEffect(() => {
        if (selectedBrand != undefined && allCars.length > 0) {
            // console.log('allCars 1: ', allCars);
            // console.log('modelscars 1: ', modelCars);
            setModelsCars(allCars);
            loadCarsByBrandCode(BrandCodes[selectedBrand.toLowerCase()]);
        }
    }, [selectedBrand, allCars]);

    useEffect(() => {
        dispatch(setModelSeries(localModelSeries));
    }, [localModelSeries]);

    // useEffect(() => {
    //     console.log('InternalID: ', InternalID);
    // }, [InternalID]);

    return (
        <div className={`vehicleSelectContainer ${!isSelectingVehicle ? 'noBg' : 'bg'}`}>
            {!isSelectingVehicle &&
                <div className='noBgspacing'></div>
            }
            {loadingPlate
                ?
                    (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '240px'}}>
                            <CircularProgress
                                sx={{ color: 'var(--secondColor)' }}
                            />
                        </div>
                    )
                :
                    (isSelectingVehicle &&
                        <div className='headerFilterMainColumn'>
                            
                            {selectedBrand &&
                                // <div className='row' style={{padding: '16px 10px 8px 10px', gap: '16px'}}>
                                <div className='vehicleSelectionHeaderRow'>
                                    <div className='overlayAlign resetSelection fadeIn' onClick={resetBrand}>
                                    {/* <div className='overlayAlign resetSelection fadeIn' onClick={configData.brands.length !== 1 ? resetBrand : null}> */}
                                        <div className='smallButtonCorner'>
                                            <CloseIcon style={{fontSize: '16px'}}/>
                                        </div>
                                        {/* {configData.brands.length !== 1 &&
                                            <div className='smallButtonCorner'>
                                                <CloseIcon style={{fontSize: '16px'}}/>
                                            </div>
                                        } */}
                                        <img src={BrandsList[selectedBrand.charAt(0).toUpperCase() + selectedBrand.slice(1)]} className='vehicleSelectorImg'/>
                                    </div>
                                    {selectedYear &&
                                    <div className='overlayAlign resetSelection' onClick={resetYear}>
                                        <div className='smallButtonCorner'>
                                            <CloseIcon style={{fontSize: '16px'}}/>
                                        </div>
                                        <div className='row selectedOption'>
                                            <span className='selectedVehicleTitle' style={{color: 'var(--textColor)'}}>{selectedYear}</span>
                                        </div>
                                    </div>
                                    }
                                    {/* {!selectedYear &&
                                        <select value={selectedYear} onChange={handleYearChange} disabled={!selectedBrand}>
                                            <option disabled selected value="">Ano...</option>
                                            {YearList.map(year => (
                                                <option key={year} value={year}>{year}</option>
                                            ))}
                                        </select>
                                    } */}
                                    {/* {!selectedYear
                                    ?
                                        <select value={selectedYear} onChange={handleYearChange} disabled={!selectedBrand}>
                                            <option disabled selected value="">Ano...</option>
                                            {YearList.map(year => (
                                                <option key={year} value={year}>{year}</option>
                                            ))}
                                        </select>
                                    :
                                        <div className='overlayAlign resetSelection' onClick={resetYear}>
                                            <div className='smallButtonCorner'>
                                                <CloseIcon style={{fontSize: '16px'}}/>
                                            </div>
                                            <div className='row selectedOption'>
                                                <span className='selectedVehicleTitle' style={{color: 'var(--textColor)'}}>{selectedYear}</span>
                                            </div>
                                        </div>
                                    } */}
                                    {selectedModel &&
                                        <div className='overlayAlign resetSelection fadeIn' onClick={resetModel}>
                                            <div className='smallButtonCorner'>
                                                <CloseIcon style={{fontSize: '16px'}}/>
                                            </div>
                                            <div className='row selectedOption'>
                                                <img src={localModelSeriesImg(localModelSeries)} className='modelSelectorImg'/>
                                                <span className='selectedVehicleTitle' style={{color: 'var(--textColor)'}} translate="no">{selectedModel}</span>
                                            </div>
                                        </div>
                                    }
                                    {selectedVersion &&
                                        <div className='overlayAlign resetSelection fadeIn' onClick={resetVersion}>
                                            <div className='smallButtonCorner'>
                                                <CloseIcon style={{fontSize: '16px'}}/>
                                            </div>
                                            <div className='row selectedOption'>
                                                <span className='selectedVehicleTitle' style={{color: 'var(--textColor)'}} translate="no">{selectedVersion}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            <div className='column'>
                                {!selectedBrand &&
                                    <div className='column fadeIn' style={{alignItems: 'center', gap: '4px', marginTop: '6px'}}>
                                        {/* <h3 style={{color: 'white'}}>Selecione um veículo para ver peças compatíveis:</h3> */}

                                        {configData.defaultStore == 'GoParts' &&
                                            <form className='row' style={{gap: '10px'}} onSubmit={handleLicensePlateSubmit}>
                                                <>
                                                    <input id='licensePlate' value={licensePlate} onChange={handleLicensePlateChange} placeholder='Insira a placa' style={{ borderColor: inputBorderColor }}/>
                                                </>
                                                <Button
                                                    label='Go!'
                                                    className='buttonStyle buttonColorThird smallButton'
                                                    type='submit'
                                                />
                                            </form>
                                        }

                                        {configData.defaultStore == 'GoParts'
                                            ?
                                                <h3 style={{color: 'white', marginTop: '4px'}}>ou selecione a marca:</h3>
                                            :
                                                <h3 style={{color: 'white', marginTop: '4px'}}>Selecione a marca do seu veículo:</h3>
                                        }

                                        <div className='headerFilterRowBrands'>
                                            {includedBrands.map((item) => {
                                                const brandName = item.charAt(0).toUpperCase() + item.slice(1);
                                                const imageSrc = BrandsList[brandName];
                                                return imageSrc ? (
                                                    <div
                                                        className='headerFilterOption'
                                                        key={brandName} // Use brandName for uniqueness
                                                        onClick={() => handleBrandSelect2(item)}
                                                    >
                                                        <img className='headerFilterBrandLogo' src={imageSrc} alt={item} />
                                                    </div>
                                                ) : (
                                                    <div className='headerFilterOption' style={{ color: 'white' }} key={item}>
                                                        {item}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                }
                                {selectedBrand &&
                                    <>
                                        {loaded
                                            ?
                                                <>
                                                    {selectedBrand && !selectedYear &&
                                                        // <select value={selectedYear} onChange={handleYearChange} disabled={!selectedBrand}>
                                                        //     <option disabled selected value="">Ano...</option>
                                                        //     {YearList.map(year => (
                                                        //         <option key={year} value={year}>{year}</option>
                                                        //     ))}
                                                        // </select>
                                                        <div className='column fadeIn' style={{alignItems: 'center'}}>
                                                            {/* <h2 className='vehicleSelectLabel'>Selecione o ano:</h2> */}
                                                            <div className='headerFilterRow headerOptionsContainer' style={{columnGap: '20px'}}>
                                                                {filteredYearList.map(year => (
                                                                    <span key={year} className='headerFilterOption' style={{fontSize: '18px', padding: '12px', margin: '6px'}} onClick={() => handleYearChange2(year)}>
                                                                        {year}
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    }
                                                    {selectedYear && !selectedModel &&
                                                        <div className='column fadeIn' style={{alignItems: 'center'}}>
                                                            {/* <h2 className='vehicleSelectLabel'>Selecione o modelo:</h2> */}
                                                            <div className='headerFilterRow headerOptionsContainer'>
                                                                {uniqueModelCars && uniqueModelCars.map(model => (
                                                                    <div className='headerFilterOption' key={model.InternalID} onClick={() => handleModelChange2(model)}>
                                                                        <div className='headerFilterTile'>
                                                                            <img src={localModelSeriesImg(model.modelSeries)} className='headerFilterTileImg'/>
                                                                            <span translate="no">{model.name}</span>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    }
                                                    {selectedModel && !selectedVersion &&
                                                        <div className='column fadeIn' style={{alignItems: 'center'}}>
                                                            {/* <h2 className='vehicleSelectLabel'>Selecione a versão:</h2> */}
                                                            <div className='headerFilterColumn headerOptionsContainer'>
                                                                {modelCarsVersion && modelCarsVersion.map(version => (
                                                                    <span key={version.InternalID} className='headerFilterOption' alt={version.engine} onClick={() => handleVersionChange2(version.engine)}>{version.engine}</span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            :
                                                <CircularProgress
                                                    style={{padding: '20px'}}
                                                    sx={{
                                                        color: 'white'
                                                    }}
                                                />
                                        }
                                    </>
                                }
                            </div>
                            {selectedVersion && 
                                <div className='column' style={{gap: '10px'}}>
                                    <Button
                                        className='buttonColorThird smallButton'
                                        iconStart={<CartIcon/>}
                                        label='Peças compatíveis'
                                        onClick={() => handleGoToParts()}
                                    />
                                    <Button
                                        className='buttonColorTransparentLight smallButton'
                                        iconStart={<Close/>}
                                        label='Remover veículo'
                                        onClick={() => resetBrand()}
                                        style={{marginTop: '10px !important', opacity: '0.3 !important'}}
                                    />
                                    {/* <Button
                                        className='buttonColorTransparentLight smallButton disableButton'
                                        iconStart={<SaveAltOutlined/>}
                                        label='Salvar veículo'
                                        onClick={() => {}}
                                        style={{marginTop: '10px !important', opacity: '0.3 !important'}}
                                    /> */}
                                </div>
                            }
                            <div className='column' style={{width: '100%'}}>
                                <Button
                                    className='buttonColorTransparentLight smallButton'
                                    label='Ok'
                                    icon={<KeyboardArrowUp/>}
                                    onClick={handleVehicleSave}
                                />
                            </div>
                        </div>
                    )
            }
        </div>
    );
}

export default VehicleSelect;
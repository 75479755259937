import React, { useState, useEffect, useRef } from 'react';
import '../../App.css';
import '../../Home/Home.css';
import './Slider.css';
import './ProductSlider.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/swiper-bundle.css';
import ProductTile from '../Tiles/ProductTile';
import { firestore } from '../../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import { configData } from '../../Whitelabel/websitesData';
import Product from '../Classes/Product';
import CircularProgress from '@mui/material/CircularProgress';

import tabKits from '../../images/categories/manutencaobasica_500px.webp';
import tabOleos from '../../images/categories/lubrificantes_e_fluidos_500px.webp';
import tabFiltros from '../../images/categories/filtros_500px.webp';
import tabAcessorios from '../../images/categories/tab_acessorio.png';
import tabRoupas from '../../images/categories/boutique_500px.webp';
import tabCorreias from '../../images/categories/correiastensoreserolamentos_500px.webp';
import tabMotores from '../../images/categories/motoreperifericos_500px.webp';
import tabFreios from '../../images/categories/freios_500px.webp';
import tabLuzes from '../../images/categories/iluminacao_500px.webp';
import tabRelated from '../../images/categories/allCats.webp';
import tabAll from '../../images/categories/shop_500px.webp';
import tabByd from '../../images/brands/byd.jpg';

import tabOleosVW from '../../images/categories/oleos_vw_500px.webp';
import tabPneus from '../../images/categories/pneus_500px.webp';
import tabBicos from '../../images/categories/injecaodecombustivel_500px.webp';

const tabImages = {
    'tabKits': tabKits,
    'tabOleos': configData.defaultStore === 'Discautol' ? tabOleosVW : tabOleos,
    'tabFiltros': tabFiltros,
    'tabAcessorios': tabAcessorios,
    'tabRoupas': tabRoupas,
    'tabCorreias': tabCorreias,
    'tabMotores': tabMotores,
    'tabFreios': tabFreios,
    'tabLuzes': tabLuzes,
    'tabRelated': tabRelated,
    'tabAll': tabAll,
    'tabByd': tabByd,
    'tabPneus': tabPneus,
    'tabBicos': tabBicos,
};

function ProductSlider({ data }) {
    const [fetchedProducts, setFetchedProducts] = useState([]);
    const vehicleState = useSelector(state => state.vehicle);    
    const [selectedTabIndex, setSelectedTabIndex] = useState(0); // Initialize with the first tab index
    const [products, setProducts] = useState(data[0].products); // Initialize with the first tab's products
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [width, setWidth] = useState(window.innerWidth); // Get the current window width
    const swiperRef = useRef(null);

    // Add a ref to track the current tab's fetch operation
    const currentFetchRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchProductFromFirestore = async (productId) => {
        try {
            const warehouseRef = collection(firestore, 'gp_warehouse');
            const q = query(warehouseRef, where('ds_number_gp', '==', productId));
            const querySnapshot = await getDocs(q);
            const itemsArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            if (itemsArray.length > 0) {
                return itemsArray[0]; // Return the first product found
            } else {
                // console.error(`No product found in Firestore with ID: ${productId}`);
                return null;
            }
        } catch (error) {
            // console.error('Error fetching product from Firestore:', error);
            return null;
        }
    };

    const loadSingleProduct = (part) => {
        if (part) {
            const sellers = Object.keys(part).filter(key => configData.storeSellersWarehouses.includes(key)).map(key => {
                const sellerData = part[key];
                const storeprice = configData.defaultStore === "GoParts" ? "price_ecommerce_gp" : "price_ecommerce_seller";
                
                return {
                    name: key,
                    active: sellerData.active,
                    price: sellerData.price,
                    stock: sellerData.stock,
                    price_ecommerce: sellerData.hasOwnProperty(storeprice) ? sellerData[storeprice] : sellerData.price,
                };
            });

            sellers.sort((a, b) => a.price_ecommerce - b.price_ecommerce);

            const mappedProduct = new Product(
                part.active,
                part.desc || 'No description available',
                part.ds_number,
                part.id_supplier,
                part.imhash || part.images,
                sellers,
                { height: part.altCM, width: part.largCM, weight: part.pesoR, depth: part.profCM },
                part.prop_car,
            );

            return mappedProduct;
        }
        return null;
    };

    const handleTabClick = (index) => {
        // Cancel any ongoing fetch operation
        if (currentFetchRef.current) {
            currentFetchRef.current.cancelled = true;
        }
        
        setLoading(true);
        setSelectedTabIndex(index);
        setFetchedProducts([]); // Clear previous products immediately
        setProducts(data[index].products);

        if (swiperRef.current) {
            swiperRef.current.slideTo(0);
        }
    };

    useEffect(() => {
        let totalCount = 0;
        let fetchedAll = false;

        // Create a new fetch context for this effect run
        const fetchContext = { cancelled: false };
        currentFetchRef.current = fetchContext;

        const fetchProducts = async (start, end) => {
            if (fetchedAll || fetchContext.cancelled) return;
            
            const slicedProducts = products.slice(start, end);
            const productsData = await Promise.all(slicedProducts.map(productId => fetchProductFromFirestore(productId)));
            
            // Check if this fetch operation was cancelled
            if (fetchContext.cancelled) return;
            
            const formattedProducts = productsData.map(productData => loadSingleProduct(productData)).filter(product => product !== null);

            // Filter products based on seller criteria with a counter limiter
            const limitedProducts = [];
            const existingIds = new Set(fetchedProducts.map(product => product.ds_number)); // Use a Set for quick lookup
            let localCount = 0; // Local count for this fetch operation

            for (const product of formattedProducts) {
                const validSellers = product.getSellers().filter(seller => 
                    configData.storeSellersWarehouses.includes(seller.name) && seller.stock > 0
                );

                if (validSellers.length > 0 && !existingIds.has(product.ds_number)) { // Check for duplicates
                    limitedProducts.push(product);
                    existingIds.add(product.ds_number); // Add the new product ID to the Set
                    localCount++; // Update the local count
                    totalCount++; // Update the total count

                    if (totalCount >= 20) { // Stop if we have reached the limit of 20 products
                        break;
                    }
                }
            }

            // Only update state if this fetch wasn't cancelled
            if (!fetchContext.cancelled && totalCount <= 20) {
                setFetchedProducts(prevFetchedProducts => [
                    ...prevFetchedProducts,
                    ...limitedProducts
                ]);
            }
        };

        const fetchMoreProducts = async (start, end) => {
            if (fetchedAll || fetchContext.cancelled) return;
            setLoadingMore(true);
            
            const slicedProducts = products.slice(start, end);
            const productsData = await Promise.all(slicedProducts.map(productId => fetchProductFromFirestore(productId)));
            
            // Check if this fetch operation was cancelled
            if (fetchContext.cancelled) return;
            
            const formattedProducts = productsData.map(productData => loadSingleProduct(productData)).filter(product => product !== null);

            // Filter products based on seller criteria
            const filteredProducts = formattedProducts.filter(product => {
                const validSellers = product.getSellers().filter(seller => 
                    configData.storeSellersWarehouses.includes(seller.name) && seller.stock > 0
                );
                return validSellers.length > 0; // Keep product if it has at least one valid seller
            });

            // Limit the number of additional products to ensure total does not exceed 20
            const additionalProducts = [];
            const existingIds = new Set(fetchedProducts.map(product => product.ds_number)); // Use a Set for quick lookup
            let localCount = 0; // Local count for this fetch operation

            for (const product of filteredProducts) {
                if (totalCount >= 20) {
                    break; // Stop if we have reached the limit of 20 products
                }
                if (!existingIds.has(product.ds_number)) { // Check for duplicates
                    additionalProducts.push(product);
                    existingIds.add(product.ds_number); // Add the new product ID to the Set
                    localCount++; // Update the local count
                    totalCount++; // Update the total count
                }
            }

            // Only update state if this fetch wasn't cancelled
            if (!fetchContext.cancelled && totalCount <= 20) {
                setFetchedProducts(prevFetchedProducts => [
                    ...prevFetchedProducts,
                    ...additionalProducts
                ]);
            }
            
            if (!fetchContext.cancelled) {
                setLoadingMore(false);
            }
        };

        const initialFetch = async () => {
            if (fetchedProducts.length === 0) {
                setLoading(true);
                await fetchProducts(0, 100);
                if (!fetchContext.cancelled) {
                    setLoading(false);
                }
                
                if (totalCount < 20 && !fetchContext.cancelled) {
                    await fetchMoreProducts(101, 250);
                }
                if (totalCount < 20 && !fetchContext.cancelled) {
                    await fetchMoreProducts(251, 400);
                }
                if (totalCount < 20 && !fetchContext.cancelled) {
                    await fetchMoreProducts(401, 600);
                }
                if (totalCount < 20 && !fetchContext.cancelled) {
                    await fetchMoreProducts(601, 1000);
                }
            }
            if (!fetchContext.cancelled) {
                removeDuplicates();
            }
        };

        initialFetch();

        // Cleanup function to cancel this fetch operation when the effect is cleaned up
        return () => {
            fetchContext.cancelled = true;
        };
    }, [products]); // Effect runs when products change

    const getSlidesPerView = () => {
        // Determine slides per view based on breakpoints
        if (width >= 1080) {
            return Math.min(fetchedProducts.length, 4); // Max 4 slides for large screens
        } else if (width >= 970) {
            return Math.min(fetchedProducts.length, 3); // Max 3 slides for medium screens
        } else if (width >= 660) {
            return Math.min(fetchedProducts.length, 2); // Max 2 slides for smaller screens
        } else {
            return Math.min(fetchedProducts.length, 1); // Max 1 slide for mobile screens
        }
    };
    
    const slidesPerView = getSlidesPerView(); // Calculate slides per view based on fetched products

    const removeDuplicates = () => {
        if (fetchedProducts.length > 0) {
            const uniqueIds = new Set(); // Create a Set to track unique IDs
            const uniqueProducts = fetchedProducts.filter(product => {
                if (!uniqueIds.has(product.ds_number)) {
                    uniqueIds.add(product.ds_number); // Add the ID to the Set
                    return true; // Keep this product
                }
                return false; // Filter out duplicates
            });
            if (uniqueProducts.length !== fetchedProducts.length) { // Only update if there are duplicates
                setFetchedProducts(uniqueProducts); // Update the state with unique products
            }
        }
    }

    useEffect(() => {
        // Call removeDuplicates only when fetchedProducts is updated
        removeDuplicates();
    }, [fetchedProducts.length]); // Listen to the length of fetchedProducts


    return (
        <div className='productSliderArea'>
            {fetchedProducts.length > 0 ?
            <>
                {data.length > 1
                    ?
                        <div className='productTabArea'>
                            {data.map((tab, index) => (
                                <div key={tab.name} onClick={() => handleTabClick(index)} className={`productSliderTab ${selectedTabIndex === index ? 'activeTab' : ''}`}>
                                    <img src={tabImages[tab.img]} alt={tab.name} className='productSliderTabImg'/>
                                    <h2 className={`productSliderTitle ${selectedTabIndex === index ? 'activeTabTitle' : ''}`}>{tab.name}</h2>
                                </div>
                            ))}
                        </div>
                    :
                        <>
                            {data[0].img !== '' && data[0].name !== ''
                                ?
                                    <div className='productTabArea'>
                                        <img src={tabImages[data[0].img]} alt={data[0].name} className='productSliderTabImg'/>
                                        <h2 className='productSliderTitle activeTabTitle'>{data[0].name}</h2>
                                    </div>
                                :
                                    <></>
                            }
                        </>
                }
                <div className='productSliderContainer'>
                    <Swiper
                        onSwiper={(swiper) => { swiperRef.current = swiper; }} // Set the swiper reference
                        modules={[Navigation, Pagination, Scrollbar]}
                        loop={false}
                        spaceBetween={10}
                        slidesPerView={slidesPerView}
                        navigation
                        pagination={{ clickable: true }} // Ensure this is set
                        className="productSliderSwiper"
                        // breakpoints={{
                        //     360: { slidesPerView: 1 },
                        //     660: { slidesPerView: 2 },
                        //     970: { slidesPerView: 3 },
                        //     1080: { slidesPerView: 4 },
                        // }}
                    >
                        {fetchedProducts.length > 0 && (!loading || !loadingMore) ? (
                            fetchedProducts.map((product, index) => (
                                <SwiperSlide key={`${product.ds_number}-${index}`} className='fadeIn'>
                                    <ProductTile
                                        product={product}
                                        vehicle={vehicleState}
                                    />
                                </SwiperSlide>
                            ))
                        ) : (
                            <div className='row center' style={{ height: '100px' }}>
                                <CircularProgress
                                    sx={{ color: 'var(--secondColor)' }}
                                />
                            </div>
                        )}
                    </Swiper>
                    {/* {!loading && loadingMore &&
                        <div className='row center' style={{ height: '32px' }}>
                            <CircularProgress
                                sx={{ color: 'var(--secondColor)' }}
                            />
                        </div>
                    } */}
                </div>
            </>
            :
                <>
                    {loading || loadingMore
                        ?
                            <div className='row center' style={{ height: '100px' }}>
                                <CircularProgress
                                    sx={{ color: 'var(--secondColor)' }}
                                />
                            </div>
                        :
                            <></>
                    }
                </>
            }
        </div>
    );
}

export default ProductSlider;
import React, { useState } from 'react';
import { setIsSelectingVehicle } from '../reducers/vehicle';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import '../App.css';
import WhatsApp from '@mui/icons-material/WhatsApp';
import WhatsappDialog from '../Utilities/WhatsappDialog';
import { handleWhatsappClick } from '../Utilities/Whatsapp';
import { configData } from '../Whitelabel/websitesData';

function FloatingButton() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { selectedBrand, isSelectingVehicle, selectedYear, selectedModel, selectedVersion } = useSelector(state => state.vehicle);
    
    const [showDialog, setShowDialog] = useState(false);

    const getProductInfo = () => {
        const path = location.pathname.substring(1);
        
        const nonProductRoutes = [
            'login', 'signup', 'recuperar-senha', 'minha-conta', 'categorias',
            'checkout', 'privacidade', 'termos-e-condicoes', 'troca-e-devolucao',
            'reembolso', 'entrega', 'sobre-nos', 'sellers-ativos'
        ];

        if (path && !nonProductRoutes.includes(path) && !path.includes('/')) {
            return path;
        }
        return null;
    };

    const handleClick = () => {
        if (Object.keys(configData.multiContact).length > 0) {
            setShowDialog(true);
        } else {
            const productInfo = getProductInfo();
            handleWhatsappClick(selectedBrand, selectedModel, selectedYear, selectedVersion, null, productInfo);
        }
    };

    const handleContactSelect = (number) => {
        const productInfo = getProductInfo();
        handleWhatsappClick(selectedBrand, selectedModel, selectedYear, selectedVersion, null, productInfo, number);
        setShowDialog(false);
    };

    const openVehicleSelect = () => {
        if (selectedVersion) {
            navigate('/categorias');
        } else {
            dispatch(setIsSelectingVehicle(!isSelectingVehicle));
        }
    };

    return (
        <div className='floatingContainer fadeIn'>
            <div className='floatingButton' style={{backgroundColor: 'var(--highlightGreen)', color: 'white'}} onClick={handleClick}>
                <WhatsApp style={{fontSize: '32px'}}/>
            </div>
            {showDialog && (
                <WhatsappDialog 
                    onClose={() => setShowDialog(false)} 
                    onSelect={handleContactSelect} 
                />
            )}
        </div>
    );
}

export default FloatingButton;
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import '../App.css';
import { subcatPeloNomeCategoria, subcatPeloNomeCategoriaBikes, idsSubcategoriaPeloNome, categoryAxle } from '../Utilities/CategoriesLists';
import { ProductPH } from '../Utilities/Placeholders';
import { CategoriesPH } from '../Utilities/Placeholders';
import { categoriesCars, categoriesBikes, bikeBrands } from '../Utilities/CategoriesLists';
import ProductTile from '../Common/Tiles/ProductTile';
import { ArrowBackSharp, Sort } from '@mui/icons-material';
import { loadOEbyCarCodeType, getCategoryWithIdProd, GET_PARTS_LIST_FROM_WH, setIsSelectingVehicle, setSelectedBrand, setSelectedYear, setSelectedModel, setSelectedVersion, eraseSelectedVehicle } from '../reducers/vehicle';
import { getFunctions, httpsCallable } from "firebase/functions";
import { configData } from '../Whitelabel/websitesData';
import Product from '../Common/Classes/Product';
import CircularProgress from '@mui/material/CircularProgress';
import { AddCar } from '../Utilities/Icons';
import Button from '../Common/Button';

import { doc, getDoc, collection } from 'firebase/firestore'; // Import Firestore functions you need
import { getFirestore, query, where, getDocs } from "firebase/firestore";
import { firestore } from '../firebase' // Adjust the import according to your Firebase configuration

import { BrandsList } from '../Utilities/BrandsList';
import { Search } from '@mui/icons-material';

function SearchResultsPage() {
    const { searchquery } = useParams();
    const [searchHistory, setSearchHistory] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const vehicle = useSelector(state => state.vehicle.selectedVehicle);
    const { selectedBrand, isSelectingVehicle, selectedYear, selectedModel, selectedVersion } = useSelector(state => state.vehicle);
    const vehicleState = useSelector(state => state.vehicle);
    const internalID = (configData.defaultStore === 'UmuaramaHD' || configData.defaultStore === 'UmuaramaMotos') ? '68444' : vehicle.InternalID;
    const allOEs = useSelector(state => state.vehicle.carOEs);
    const [allProducts, setAllProducts] = useState([]);
    const [searchProducts, setSearchProducts] = useState([]);
    const [productsWithLinks, setProductsWithLinks] = useState([]);
    const [sortOption, setSortOption] = useState('default');
    const [loading, setLoading] = useState(true);
    const [findinDB, setFindinDB] = useState(true);
    const [loadingDB, setloadingDB] = useState(internalID === undefined);
    const Categories = (selectedBrand && bikeBrands.includes(selectedBrand.toLowerCase())) ? categoriesBikes : categoriesCars;

    useEffect(() => {
        if(searchquery){
            setLoading(true);
            getPartsListFromDB();
            return;
        } 
    }, [searchquery]);

    // useEffect(() => {
    //     console.log('allOEs: ', allOEs);
    // }, [allOEs]);

    const keepVehicleSelection = () => {
        if ((configData.defaultStore === 'UmuaramaHD' || configData.defaultStore === 'UmuaramaMotos')) {
            dispatch(setIsSelectingVehicle(false));
            dispatch(setSelectedBrand('uhd'));
            dispatch(setSelectedYear('2020'));
            dispatch(setSelectedModel('softail'));
            dispatch(setSelectedVersion('abc'));
        }
    }

    useEffect(() => {
        keepVehicleSelection();
    }, [selectedVersion]);

    const [all_OEs_state, setAll_OEs_state] = useState({});
    const [vehicle_state, setVehicle_state] = useState({});
    
    useEffect(() => {
        if (
            allOEs !== all_OEs_state &&
            Object.keys(allOEs).length !== 0 &&
            ((configData.defaultStore !== 'UmuaramaHD' && configData.defaultStore !== 'UmuaramaMotos') ? vehicle !== vehicle_state && Object.keys(vehicle).length !== 0 : true)
        ) {
            setAll_OEs_state(allOEs);
            setVehicle_state(vehicle);
            allCarParts(allOEs);
        }
    }, [allOEs, vehicle]);

    const [allProds_state, setAllProds_state] = useState([]);

    useEffect(() => {
        if (searchquery !== searchHistory && allProducts.length > 0) {
            setSearchHistory(searchquery);
            filterSearch(searchquery);
        }
    }, [allProducts, searchquery, searchHistory]);
    // useEffect(() => {
    //     if (
    //         ((configData.defaultStore !== 'UmuaramaHD' && configData.defaultStore !== 'UmuaramaMotos') ? allProds_state !== allProducts : true) &&
    //         allProducts.length > 0
    //     ) {
    //         setAllProds_state(allProducts);
    //         filterSearch(searchquery);
    //     }
    // }, [allProducts, searchquery]);

    const vehicleSelectWithBrand = (brand) => {
        dispatch(setSelectedVersion(''));
        dispatch(setSelectedModel(''));
        dispatch(setSelectedYear(''));
        dispatch(setSelectedBrand(brand));
        dispatch(setIsSelectingVehicle(true));
    }

    const openVehicleSelectOnly = () => {
        dispatch(setIsSelectingVehicle(true));
    }



    useEffect(() => {
        const fetchProductLinks = async () => {
            const productsWithLinks = await Promise.all(searchProducts.map(async item => {
                const foundCategory = await dispatch(getCategoryWithIdProd(Number(item.id_prod)));
                const categoryName = getCategoryNameBySubcategory(foundCategory.category);
    
                let defaultImage = '';
                let measures = {};
                
                // Filter sellers based on configData.storeSellersWarehouses
                const filteredSellers = item.sellers.filter(seller =>
                    configData.storeSellersWarehouses.includes(seller.name)
                );
    
                const mappedProduct = new Product(
                    item.active,
                    item.description,
                    item.ds_number,
                    item.id_supplier,
                    item.images,
                    filteredSellers, // Use filtered sellers here
                    measures = { height: item.height, width: item.height, weight: item.height },
                    item.listofPropertiesbyCar,
                );
    
                return mappedProduct;
            }));
    
            dispatch({
                type: GET_PARTS_LIST_FROM_WH,
                payload: productsWithLinks,
            });
            setProductsWithLinks(productsWithLinks);
        };

        if (searchProducts.length == 0) {
            fetchProductLinks();
        } else {
            setProductsWithLinks(searchProducts);
        }
    }, [searchProducts, dispatch, configData.storeSellersWarehouses]);
    
    const getCategoryNameBySubcategory = (subcategory) => {
        for (const [category, subcategories] of Object.entries(bikeBrands.includes(selectedBrand.toLowerCase()) ? subcatPeloNomeCategoriaBikes : subcatPeloNomeCategoria)) {
            if (subcategories.includes(subcategory)) {
                return category;
            }
        }
        return null;
    };

    const allCarParts = (allOEs) => {
        const allIDs = Object.entries(Categories)
            .flatMap(([category, image]) => getCategoriesIds(category));

        const uniqueIDs = [...new Set(allIDs)];

        loadParts(vehicle, uniqueIDs, true, "all", allOEs);
    }

    const getCategoriesIds = (name) => {
        const subCategories = bikeBrands.includes((selectedBrand || '').toLowerCase()) 
            ? subcatPeloNomeCategoriaBikes[name] 
            : subcatPeloNomeCategoria[name];
        
        const listofIds = [];
        
        if (Array.isArray(subCategories)) {
            subCategories.forEach((element) => {
                const ids = idsSubcategoriaPeloNome[element];
                if (ids) {
                    listofIds.push(...ids);
                } else {
                    console.warn(`No IDs found for element: ${element}`);
                }
            });
        } else {
            console.warn(`No subcategories found for category: ${name}`);
        }
    
        return listofIds;
    };

    const loadParts = async (car, ids, starting, subcategory, allOEs) => {
        const partsList = [];
    
        ids.forEach((categoryId) => {
            allOEs.parts.forEach((oe) => {
                if (oe && oe.id_prod && oe.id_prod.toString() === categoryId.toString()) {
                    if (!partsList.includes(oe.oe_gpm)) partsList.push([oe.oe_gpm]);
                }
            });
        });
    
        const distinctIds = partsList;
    
        const axle = categoryAxle[subcategory?.toString() || 'default']; // Safeguard for subcategory
    
        const requestPayload = {
            data: distinctIds,
            cross: false,
        };
    
        if (configData.defaultStore === 'UmuaramaHD') {
            requestPayload.brand = 'GENUINAHARLEYDAVIDSON';
        } else if (configData.defaultStore === 'UmuaramaMotos') {
            // Create request payload for GENUINAHARLEYDAVIDSON
            const harleyPayload = {
                ...requestPayload,
                brand: 'GENUINAHARLEYDAVIDSON',
            };
    
            // Create request payload for GENUINAKTM
            const ktmPayload = {
                ...requestPayload,
                brand: 'GENUINAKTM',
            };

            // Create request payload for GENUINATRIUMPH
            const triumphPayload = {
                ...requestPayload,
                brand: 'GENUINATRIUMPH',
            };
    
            // Send requests for all brands
            const [WHpartsHarley, WHpartsKTM, WHpartsTriumph] = await Promise.all([
                getPartsListFromWH(harleyPayload),
                getPartsListFromWH(ktmPayload),
                getPartsListFromWH(triumphPayload),
            ]);
    
            // Combine results from all brands
            const combinedParts = [...WHpartsHarley, ...WHpartsKTM, ...WHpartsTriumph];
            setAllProducts(combinedParts);
            return; // Exit the function after handling all brands
        } else {
            requestPayload.axle = axle;
            requestPayload.carID = car.InternalID.toString();
        }
    
        const WHparts = await getPartsListFromWH(requestPayload);
        setAllProducts(WHparts);
    }

    const getPartsListFromWH = async (info) => {
        const functions = getFunctions();
        const callableGetPartsListFromWH = httpsCallable(functions, `${(configData.defaultStore === 'UmuaramaHD' || configData.defaultStore === 'UmuaramaMotos') ? 'getPartsListFromBrand' : 'getPartsListFromWH'}`);

        const parts = await callableGetPartsListFromWH(info).then((result) => {
            let i = 0;
            const listofArticles = [];
            result.data.forEach((document) => {
                i = i + 1;
                if (document != null) {
                    const listOfSellers = document["sellersList"];
                    const growableList = [];
                    const storeprice = configData.defaultStore === "GoParts" ? "price_ecommerce_gp" : "price_ecommerce_seller";

                    listOfSellers.forEach((element) => {
                        if (configData.storeSellersWarehouses.includes(element)) {
                            growableList.push({
                                id: document["id"].toString(),
                                active: document[element]["active"],
                                name: document[element]["name"],
                                price: document[element]["price"],
                                stock: document[element]["stock"],
                                price_ecommerce: document[element].hasOwnProperty(storeprice)
                                    ? document[element][storeprice]
                                    : document[element["price"]],
                                distance: 1
                            });
                        }
                    });

                    growableList.sort((a, b) => {
                        var mina = a.price_ecommerce < a.price ? a.price_ecommerce : a.price;
                        var minb = b.price_ecommerce < b.price ? b.price_ecommerce : b.price;

                        if (mina < minb) return -1;

                        if (minb < mina) return 1;
                        return 0;
                    });

                    const newProduct = new Product(
                        document['active'],
                        document['desc'],
                        document['ds_number'],
                        document['id_supplier'],
                        document['imhash'],
                        growableList,
                        {
                            height: document.hasOwnProperty('altCM')
                            ? document['altCM']
                            : -1,
                            width:document.hasOwnProperty('largCM')
                            ? document['largCM']
                            : -1,
                            depth: document.hasOwnProperty('profCM')
                            ? document['profCM']
                            : -1,
                            weight: document.hasOwnProperty('pesoG')
                            ? document['pesoG']
                            : -1,
                        },
                        document.hasOwnProperty('prop_car')
                            ? document['prop_car']
                            : null,
                    );

                    listofArticles.push(newProduct);
                }
            });

            if (listofArticles.length === 0) {
                
                listofArticles.push({
                    id: "0",
                    active: false,
                    description: "Sem cadastro de peça",
                    ds_number: "Sem cadastro de peça",
                    ds_number_gp: "Sem cadastro de peça",
                    id_prod: "Sem cadastro de peça",
                    id_supplier: "0",
                    images: [],
                    sellers: [],
                    listofPropertiesbyCar: {},
                    priority: 0,
                    ds_numbers: [],
                    weight: -1,
                    height: -1,
                    depth: -1,
                    width: -1,
                });
            }

            listofArticles.sort((a, b) => {
                const totalA = a.sellers.reduce((tot, item) => tot + Number(item.stock), 0);
                const totalB = b.sellers.reduce((tot, item) => tot + Number(item.stock), 0);
                return totalB - totalA;
            });

            return listofArticles;
        }).catch((error) => {
            console.error(error);
        });

        return parts;
    }

    const resetVehicle = () => {
        dispatch(setSelectedBrand(undefined));
        dispatch(setSelectedYear(undefined));
        dispatch(setSelectedModel(undefined));
        dispatch(setSelectedVersion(undefined));
        dispatch(eraseSelectedVehicle());
    }

    const getPartsListFromDB = async () => {
        setloadingDB(true);
        const warehouseRef = collection(firestore, 'gp_warehouse'); // Reference to the collection
        const q = query(warehouseRef, where('ds_number', '==', searchquery.toUpperCase().trim()));
    
        const querySnapshot = await getDocs(q); // Get the query snapshot
    
        // Map over the documents and return an array of items
        if (querySnapshot.docs.length > 0) {
            const itemsArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
    
            const listofArticles = [];
            itemsArray.forEach((document) => {
                if (document != null) {
                    const listOfSellers = document["sellersList"];
                    const growableList = [];
                    const storeprice = configData.defaultStore === "GoParts" ? "price_ecommerce_gp" : "price_ecommerce_seller";
    
                    listOfSellers.forEach((element) => {
                        if (configData.storeSellersWarehouses.includes(element)) {
                            growableList.push({
                                id: document["id"].toString(),
                                active: document[element]["active"],
                                name: document[element]["name"],
                                price: document[element]["price"],
                                stock: document[element]["stock"],
                                price_ecommerce: document[element].hasOwnProperty(storeprice)
                                    ? document[element][storeprice]
                                    : document[element["price"]],
                                distance: 1
                            });
                        }
                    });
    
                    growableList.sort((a, b) => {
                        const mina = a.price_ecommerce < a.price ? a.price_ecommerce : a.price;
                        const minb = b.price_ecommerce < b.price ? b.price_ecommerce : b.price;
    
                        if (mina < minb) return -1;
                        if (minb < mina) return 1;
                        return 0;
                    });
    
                    const newProduct = new Product(
                        document['active'],
                        document['desc'],
                        document['ds_number'],
                        document['id_supplier'],
                        document['imhash'],
                        growableList,
                        {
                            height: document.hasOwnProperty('altCM') ? document['altCM'] : -1,
                            width: document.hasOwnProperty('largCM') ? document['largCM'] : -1,
                            depth: document.hasOwnProperty('profCM') ? document['profCM'] : -1,
                            weight: document.hasOwnProperty('pesoG') ? document['pesoG'] : -1,
                        },
                        document.hasOwnProperty('prop_car') ? document['prop_car'] : null,
                    );
    
                    listofArticles.push(newProduct);
                }
            });
    
            if (listofArticles.length === 0) {
                listofArticles.push({
                    id: "0",
                    active: false,
                    description: "Sem cadastro de peça",
                    ds_number: "Sem cadastro de peça",
                    ds_number_gp: "Sem cadastro de peça",
                    id_prod: "Sem cadastro de peça",
                    id_supplier: "0",
                    images: [],
                    sellers: [],
                    listofPropertiesbyCar: {},
                    priority: 0,
                    ds_numbers: [],
                    weight: -1,
                    height: -1,
                    depth: -1,
                    width: -1,
                });
            }
    
            listofArticles.sort((a, b) => {
                const totalA = a.sellers.reduce((tot, item) => tot + Number(item.stock), 0);
                const totalB = b.sellers.reduce((tot, item) => tot + Number(item.stock), 0);
                return totalB - totalA;
            });
    
            if (listofArticles.length) {
                setFindinDB(true);
                setloadingDB(false);
                if (listofArticles.length === 1) {
                    resetVehicle();
                    navigate(`/${listofArticles[0].ds_number}`);
                }
            } else {
                setFindinDB(false);
                setloadingDB(false);
            }
    
            return listofArticles;
        } else {
            setFindinDB(false);
            setloadingDB(false);
    
            if (internalID && sortedProducts.length === 0) dispatch(loadOEbyCarCodeType(internalID));
            return [];
        }
    };

    const normalizeString = (str) => {
        if (typeof str === 'string' && str) {
            return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
        } else {
            return ''; // or handle it in another appropriate way, like returning null or an error message
        }
    };

    const filterSearch = (searchValue) => {
        const normalizedSearchValue = normalizeString(searchValue);
        const searchTerms = normalizedSearchValue.split(" ").filter(term => term);
    
        let filteredProducts = allProducts.filter(item => {
            const normalizedDescription = normalizeString(item.description);
            const normalizedNumber = normalizeString(item.ds_number_gp || item.ds_number);
    
            return searchTerms.every(term =>
                normalizedDescription.includes(term) || normalizedNumber.includes(term)
            );
        });
    
        setSearchProducts(filteredProducts);
        if (filteredProducts.length === 0) setLoading(false);
    };

    const handleSortChange = (event) => {
        setSortOption(event.target.value);
    };

    const sortedProducts = [...productsWithLinks].sort((a, b) => {
        const getPrice = (product, priceType) => {
            if (product.sellers && product.sellers.length > 0) {
                return product.sellers[0][priceType] || 0; // Return 0 if price is missing
            }
            return 0; // If no sellers, return 0
        };
    
        const getDiscount = (product) => {
            const originalPrice = getPrice(product, "price");
            const ecommercePrice = getPrice(product, "price_ecommerce");
            const discount = (originalPrice <= 0 || ecommercePrice <= 0) ? 0 : Math.abs(originalPrice - ecommercePrice);            
            return discount; // Absolute discount value
        };
    
        const discountA = getDiscount(a);
        const discountB = getDiscount(b);
    
        switch (sortOption) {
            case 'lowestPrice':
                const priceA = getPrice(a, "price_ecommerce");
                const priceB = getPrice(b, "price_ecommerce");
    
                // Move products without sellers to the end
                if (a.getSellers().length === 0 && b.getSellers().length === 0) return 0; // Both have no sellers
                if (a.getSellers().length === 0) return 1; // a has no sellers, put it after b
                if (b.getSellers().length === 0) return -1; // b has no sellers, put it after a
    
                return priceA - priceB; // Sort by lowest price
    
            case 'highestPrice':
                return getPrice(b, "price_ecommerce") - getPrice(a, "price_ecommerce");
    
            case 'alphabetical':
                return (a.description || '').localeCompare(b.description || '');
    
            case 'highestDiscount':
                // Check if either product has sellers
                if (a.getSellers().length === 0 && b.getSellers().length === 0) return 0; // Both have no sellers
                if (a.getSellers().length === 0) return 1; // a has no sellers, put it after b
                if (b.getSellers().length === 0) return -1; // b has no sellers, put it after a
    
                return discountB - discountA; // Sort by absolute discount, highest to lowest
                    
            default:
                return 0;
        }
    });

    const showResults = () => {
        if(sortedProducts.length > 0){
            setLoading(false);
        }
    }
    
    useEffect(() => {
        showResults()
    }, [sortedProducts]);

    const openVehicleSelect = () => {
        dispatch(setIsSelectingVehicle(!isSelectingVehicle));
    };

    // useEffect(() => {
    //     console.log('sortedProducts: ', sortedProducts);
    // }, [sortedProducts]);

    // useEffect(() => {
    //     console.log('productsWithLinks: ', productsWithLinks);
    // }, [productsWithLinks]);

    // useEffect(() => {
    //     console.log('searchProducts: ', searchProducts);
    // }, [loading]);

    return (
        <div className='main fadeIn'>
            {findinDB
                ?
                    <div className='pageContent'>
                        <CircularProgress
                            sx={{ color: 'var(--secondColor)' }}
                        />
                    </div>
                :
                selectedVersion || (configData.defaultStore === 'UmuaramaHD' || configData.defaultStore === 'UmuaramaMotos')
                    ?
                        <div className='pageContent'>
                            <div className='pageHeader'>
                                <div className='column' style={{ alignItems: 'center', gap: '6px'}}>
                                    {selectedVersion && (configData.defaultStore !== 'UmuaramaHD' && configData.defaultStore !== 'UmuaramaMotos')
                                        ?
                                            <span>Buscando por "{searchquery}" para <a onClick={openVehicleSelect}>{selectedModel} {selectedYear} {selectedVersion}</a></span>
                                        :    
                                            <span>"{searchquery}"</span>
                                    }
                                </div>
                                {!loading && (
                                    <div className='row fadeIn' style={{ alignItems: 'center', gap: '6px' }}>
                                        <Sort />
                                        <span style={{ whiteSpace: 'nowrap' }}>Ordenar por: </span>
                                        <select style={{ maxWidth: '200px' }} onChange={handleSortChange}>
                                            <option value='default'>Relevância</option>
                                            <option value='lowestPrice'>Menor preço</option>
                                            <option value='highestPrice'>Maior preço</option>
                                            <option value='highestDiscount'>Maior Desconto</option>
                                            <option value='alphabetical'>Ordem Alfabética</option>
                                        </select>
                                    </div>
                                )}
                            </div>
                            <div className='centerGrid' style={{ marginTop: '10px' }}>
                                <div className='tileGrid fadeIn'>
                                    {loading ? (
                                        <CircularProgress
                                            // sx={{ color: 'black' }}
                                            sx={{ color: 'var(--secondColor)' }}
                                        />
                                    ) : sortedProducts.length > 0 ? (
                                        sortedProducts.map(product => (
                                            <ProductTile
                                                key={product.id}
                                                product={product}
                                                defaultImage={product.defaultImage}
                                                vehicle={vehicleState}
                                            />
                                        ))
                                    ) : 
                                        <span>"Não há resultados para essa pesquisa"</span>
                                    }
                                </div>
                            </div>
                        </div>
                    :
                        <div className='pageContent'>
                            {/* <div className='pageContent center column' style={{gap: '10px', textAlign: 'center'}}>
                                <h2>Selecione um veículo para buscar peças compatíveis.</h2>
                                <Button
                                    className='buttonColorMain smallButton'
                                    iconStart={<AddCar style={{fontSize: '16px'}}/>}
                                    label='Selecionar'
                                    onClick={openVehicleSelect}
                                />
                            </div> */}
                        {configData.brands.length > 1
                            ?
                                <div className='column center' style={{gap: '10px'}}>
                                    <h2>Selecione um veículo para buscar peças compatíveis.</h2>
                                    <div className='defaultRowBrands'>
                                    {configData.brands.map((item) => {
                                        const brandName = item.charAt(0).toUpperCase() + item.slice(1);
                                        const imageSrc = BrandsList[brandName];
                                        return imageSrc ? (
                                        <div
                                            className='headerFilterOption'
                                            key={item}
                                            onClick={() => vehicleSelectWithBrand(item)}
                                        >
                                            <img className='headerFilterBrandLogo' src={imageSrc} alt={item} loading='lazy' />
                                        </div>
                                        ) : (
                                        <div className='headerFilterOption' style={{ color: 'white' }} key={item}>
                                            {item}
                                        </div>
                                        );
                                    })}
                                    </div>
                                    {configData.defaultStore === 'GoParts' &&
                                        <div className='column center' style={{gap: '6px'}}>
                                            <span>ou</span>
                                            <Button
                                            onClick={openVehicleSelectOnly}
                                            className="buttonColorThird smallButton"
                                            label="Encontre pela placa"
                                                iconStart={<Search/>}
                                            />
                                        </div>
                                    }
                                </div>
                            :
                                <div className='column center' style={{gap: '10px'}}>
                                    <h2>Selecione um veículo para buscar peças compatíveis.</h2>
                                    <Button
                                        className='buttonColorThird smallButton'
                                        iconStart={<AddCar style={{fontSize: '16px'}}/>}
                                        label='Selecionar veículo'
                                        onClick={() => vehicleSelectWithBrand(configData.brands[0])}
                                    />
                                </div>
                            }
                        </div>
                }
        </div>
    );
}

export default SearchResultsPage;

import { storeInfo } from './Placeholders';
import { configData } from '../Whitelabel/websitesData';

const formatPhoneNumber = (number) => {
    // Ensure the number is in the correct format
    return number.replace(/\D/g, ''); // Remove non-digit characters
};

export const handleWhatsappClick = (selectedBrand, selectedModel, selectedYear, selectedVersion, orderInfo, productInfo, number) => {
    // Check if number is defined, if not, use the default whatsapp number
    const linkNumber = formatPhoneNumber(number || configData.whatsappNumber);
    const whatsappLink = `whatsapp://send?phone=55${linkNumber}&text=Olá, tudo bem?`;
    const webLink = `https://api.whatsapp.com/send?phone=55${linkNumber}&text=Olá, tudo bem?`;

    const gtmWhatsappClick = () => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'button_whatsapp_click'
            });
        } else {
            console.warn("dataLayer is not defined");
        }
    };

    // Helper function to construct the message
    const constructMessage = () => {
        let message = '';
        
        if (selectedBrand && selectedModel && selectedYear && selectedVersion) {
            message = `Meu veículo é um ${selectedBrand} ${selectedModel} ${selectedYear} ${selectedVersion}`;
        }
        if (productInfo) {
            const productMessage = `Estou vendo o produto ${productInfo}`;
            message = message ? `${message}. ${productMessage}` : productMessage;
        } else if (orderInfo) {
            message = `Meu pedido é o de Nº ${orderInfo}`;
        }
        
        return message;
    };

    const message = constructMessage();

    // Append the message to both links if it exists
    const newLink = message ? `${whatsappLink} ${message}` : whatsappLink;
    const finalWebLink = message ? `${webLink} ${message}` : webLink;

    // Check if the user is on a mobile device
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    const finalLink = isMobile ? newLink : finalWebLink;

    window.open(finalLink, '_blank');
    gtmWhatsappClick();
};
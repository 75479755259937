import React from 'react';
import Button from '../Common/Button';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { storeInfo } from '../Utilities/Placeholders';
import { configData } from '../Whitelabel/websitesData';

function TermsAndConditions() {
    const navigate = useNavigate();

    const handleGoBack = () => {
      navigate(-1);
    };

    return (
        <div className='main fadeIn'>
            <div className='pageContent'>
                {configData.defaultStore === 'Peças Kia'
                ?
                    <div className='card'>
                        <div className='column legalTerm' style={{padding: '20px', gap: '10px'}}>
                            <h2>Termos e Condições de Compra e Venda</h2>
                            <p>Termos e Condições de Compra e Venda de BRM Comércio de Veículos, com sede na Avenida Barão Homem de Melo, 3030, Estoril, Belo Horizonte, Minas Gerais, doravante denominada simplesmente BRM Motors, e, de outro lado, o cliente BRM Motors, qualificado no momento da compra dos produtos BRM Motors, doravante denominado simplesmente Cliente.</p>
                            <p>Considerando que a BRM Motors realiza venda de produtos e serviços pela internet; Considerando o interesse do Cliente na compra dos produtos oferecidos pela BRM Motors ("Produtos") em seus canais de venda; O presente contrato tem por finalidade estabelecer as condições gerais de uso e compra de produtos e serviços do cliente do site BRM Motors.</p>

                            <h3>I. Confidencialidade</h3>
                            <p>É de responsabilidade da BRM Motors a preservação da confidencialidade de todos os dados e informações fornecidos pelo Cliente no processo de compra. A segurança do site é auditada diariamente e garantida contra a ação de hackers, através do selo "Site Blindado".</p>

                            <h3>II. Serviço de Atendimento ao Cliente (SAC)</h3>
                            <p>O cliente dispõe desse serviço para sanar suas dúvidas, solucionar eventuais solicitações ou reclamações a respeito do seu pedido ou de qualquer conteúdo disponibilizado no site. O SAC poderá ser acionado por meio de telefone ou de formulário do site.</p>

                            <h3>III. Política de entrega</h3>
                            <p>O prazo para entrega dos Produtos é informado durante o procedimento de compra, contabilizado em dias úteis. As entregas dos Produtos são realizadas de segunda a sexta-feira, das 8h às 18h. Excepcionalmente, algumas entregas de Produtos podem ocorrer aos sábados, domingos e feriados.</p>
                            <p>III.I - A conferência da adequação das dimensões do produto é de responsabilidade do Cliente, que deverá se assegurar de que estas estão de acordo com os limites espaciais dos elevadores, portas e corredores do local da entrega. Não será realizada a montagem ou desmontagem do produto, transporte pela escada e/ou portas e janelas, ou içamento das entregas.</p>
                            <p>III.II - Serão realizadas até três tentativas de entrega no local informado, em dias alternados, com intervalo de até 48h entre uma entrega e outra. É indispensável que, no endereço solicitado, haja uma pessoa autorizada pelo comprador, maior de 18 anos, e portando documento de identificação para receber a mercadoria e assinar o protocolo de entrega. Se houver três tentativas de entrega sem sucesso, o pedido retornará para o Centro de Distribuição da BRM Motors.</p>
                            <p>III.III - Após a finalização do pedido não é possível alterar a forma de pagamento e/ou endereço de entrega, solicitar adiantamento ou, ainda, prioridade da entrega.</p>
                            <p>III.IV - O prazo de entrega informado durante o procedimento de compra do Produto leva em consideração o estoque, a região, o processo de emissão da nota fiscal e o tempo de preparo do produto. A cada atualização no status de entrega do pedido, o sistema da BRM Motors envia, automaticamente, e-mails de alerta para o Cliente.</p>
                            <p>III.V - O valor do frete da entrega é calculado com base no local de entrega, peso e dimensões do Produto.</p>
                            <p>III.VI - A BRM Motors não autoriza a transportadora a: entrar no domicílio; entregar por meios alternativos (exemplo: içar produto por janela); realizar instalação ou manutenção de produtos; abrir a embalagem do produto; realizar entrega em endereço diferente do que consta no DANFE; realizar entrega a menor de idade ou sem documento de identificação.</p>
                            <p>III.VII - A BRM Motors não se responsabiliza pela retenção de mercadorias na SEFAZ quando esta se dever exclusivamente a pendências do cliente, sendo, portanto, necessário seu comparecimento no posto fiscal para que a mercadoria seja liberada, tendo em vista que nestes casos as informações referentes a liberações e pagamentos só são passadas aos interessados.</p>

                            <h3>IV. Direito de arrependimento</h3>
                            <p>Ao Cliente será facultado o exercício do direito de arrependimento da compra, com a finalidade de devolução do Produto, hipótese na qual deverão ser observadas as seguintes condições: o prazo de desistência da compra do produto é de até 7 (sete) dias corridos, a contar da data do recebimento; em caso de devolução, o produto deverá ser devolvido à BRM Motors na embalagem original, acompanhado do DANFE (Documento Auxiliar da Nota Fiscal Eletrônica), do manual e de todos os seus acessórios.</p>
                            <p>IV.I - O Cliente deverá solicitar a devolução através do Serviço de Atendimento ao Cliente (SAC) ou diretamente no Painel de Controle, no tópico "cancelar pedido". As despesas decorrentes de coleta ou postagem do Produto serão custeadas pela BRM Motors.</p>
                            <p>IV.II - Após a chegada do produto ao Centro de Distribuição, a BRM Motors verificará se as condições supra citadas foram atendidas. Em caso afirmativo, providenciará a restituição no valor total da compra.</p>
                            <p>IV.III - Em compras com cartão de crédito a administradora do cartão será notificada e o estorno ocorrerá na fatura seguinte ou na posterior, de uma só vez, seja qual for o número de parcelas utilizado na compra. O prazo de ressarcimento e, ainda, a cobrança das parcelas remanescentes após o estorno integral do valor do Produto no cartão de crédito do Cliente realizado pela BRM Motors, é de responsabilidade da administradora do cartão. Na hipótese de cobrança de parcelas futuras pela administradora do cartão, o Cliente não será onerado, vez que a BRM Motors, conforme mencionado acima, realiza o estorno do valor integral do Produto em uma única vez, sendo o crédito referente ao estorno concedido integralmente pela administradora do cartão na fatura de cobrança subsequente ao mês do cancelamento.</p>
                            <p>IV.IV - Em compras pagas com boleto bancário ou débito em conta, a restituição será efetuada por meio de depósito bancário, em até 10 (dez) dias úteis, somente na conta corrente do(a) comprador(a), que deve ser individual. É necessário que o CPF do titular da conta corrente ou CNPJ da Empresa.</p>

                            {/* <h2>Mudanças nestes Termos e Condições</h2>
                            <p>Podemos atualizar nossos Termos e Condições de tempos em tempos. Portanto, é recomendável revisar esta página periodicamente para verificar quaisquer alterações. Iremos notificá-lo de quaisquer alterações, publicando os novos Termos e Condições nesta página.</p>
                            <p>Estes termos e condições são válidos a partir de {configData.launchDate}.</p>

                            <h2>Contate-Nos</h2>
                            <p>Se você tiver alguma dúvida ou sugestão sobre os nossos Termos e Condições, não hesite em entrar em contato conosco em <a href={`mailto:${configData.defaultContactEmail}`} target='_blank'>{configData.defaultContactEmail}</a>.</p> */}
                            <Button
                                className='buttonColorSecond smallButton'
                                label='voltar'
                                onClick={handleGoBack}
                            />
                        </div>
                    </div>
                :
                    <div className='card'>
                        <div className='column legalTerm' style={{padding: '20px', gap: '10px'}}>
                            <h2>Termos e Condições</h2>
                            <p>Ao baixar ou usar o aplicativo, estes termos se aplicarão automaticamente a você - portanto, certifique-se de lê-los com atenção antes de usar o aplicativo. Você não tem permissão para copiar ou modificar o aplicativo, qualquer parte do aplicativo ou nossas marcas registradas de forma alguma. Você não tem permissão para tentar extrair o código-fonte do aplicativo e também não deve tentar traduzir o aplicativo para outros idiomas ou fazer versões derivadas. O aplicativo em si, e todas as marcas, direitos autorais, direitos de banco de dados e outros direitos de propriedade intelectual relacionados a ele, pertencem à {configData.defaultStore}.</p>
                            <p>A {configData.defaultStore} está empenhada em garantir que a aplicação seja o mais útil e eficiente possível. Por esse motivo, nos reservamos o direito de fazer alterações no aplicativo ou cobrar por seus serviços, a qualquer momento e por qualquer motivo. Nunca cobraremos de você pelo aplicativo ou seus serviços sem previamente deixar bem claro exatamente o que você está pagando.</p>
                            <p>O aplicativo {configData.defaultStore} armazena e processa dados pessoais que você nos forneceu, a fim de fornecer nosso Serviço. É sua responsabilidade manter o seu telefone e o acesso ao aplicativo seguros. Portanto, recomendamos que você não faça jailbreak ou root em seu telefone, que é o processo de remoção de restrições e limitações de software impostas pelo sistema operacional oficial de seu dispositivo. Isso pode tornar seu telefone vulnerável a malwares / vírus / programas maliciosos, comprometer os recursos de segurança do telefone e pode significar que o aplicativo {configData.defaultStore} não funcionará corretamente.</p>
                            <p>O aplicativo usa serviços de terceiros que declaram seus próprios Termos e Condições. Link para os Termos e Condições de provedores de serviços terceirizados usados pelo aplicativo:</p>
                            <p><a className="textLink" href="https://www.google.com/policies/privacy/" target="_blank">• Google Play Services</a></p>
                            <p><a className="textLink" href="https://firebase.google.com/policies/analytics" target="_blank">• Google Analytics for Firebase</a></p>
                            <p><a className="textLink" href="https://firebase.google.com/support/privacy/" target="_blank">• Firebase Crashlytics</a></p>
                            <p><a className="textLink" href="https://www.facebook.com/about/privacy/update/printable" target="_blank">• Facebook</a></p>
                            <p>Esteja ciente de que existem certas coisas pelas quais a {configData.defaultStore} não se responsabiliza. Algumas funções do aplicativo exigirão que o dispositivo tenha uma conexão ativa com a Internet. A ligação pode ser Wi-Fi, ou fornecida pelo seu fornecedor de rede móvel, mas a {configData.defaultStore} não pode assumir a responsabilidade pelo aplicativo não operar em plena funcionalidade se não tiver acesso a Wi-Fi e não tiver mais sua cota de dados móveis disponível.</p>
                            <p>Se você estiver usando o aplicativo fora de uma área com Wi-Fi, lembre-se de que os termos do contrato com seu provedor de rede móvel ainda se aplicam. Como resultado, você pode ser cobrado por sua operadora de celular pelo custo dos dados durante a conexão ao acessar o aplicativo ou outras cobranças de terceiros. Ao usar o aplicativo, você aceita a responsabilidade por quaisquer cobranças, incluindo taxas de roaming de dados se usar o aplicativo fora de seu território (ou seja, região ou país) sem desligar o roaming de dados. Se você não for o pagador de contas do dispositivo no qual está usando o aplicativo, esteja ciente de que presumimos que você recebeu permissão do pagador de contas para usar o aplicativo.</p>
                            <p>A {configData.defaultStore} nem sempre pode se responsabilizar pela forma como você usa o aplicativo, ou seja, você precisa se certificar de que seu dispositivo fica carregado - se ficar sem bateria e você não conseguir ligá-lo para utilizar o Serviço, a {configData.defaultStore} não se responsabiliza.</p>
                            <p>No que diz respeito à responsabilidade da {configData.defaultStore} pelo uso que você faz do aplicativo, quando você estiver usando a {configData.defaultStore}, é importante ter em mente que embora nos esforcemos para que ele esteja sempre atualizado e correto, contamos que terceiros nos forneçam informações para que possamos disponibilizá-las a você. A {configData.defaultStore} não se responsabiliza por qualquer perda, direta ou indireta, que você experimente em decorrência de confiar totalmente nesta funcionalidade do aplicativo.</p>
                            <p>Em algum momento, podemos desejar atualizar o aplicativo. O aplicativo está disponível atualmente para Android e iOS - os requisitos para ambos os sistemas (e para quaisquer sistemas adicionais para os quais decidirmos estender a disponibilidade do aplicativo) podem mudar e você precisará baixar as atualizações se quiser continuar usando a aplicação. A {configData.defaultStore} não se compromete a atualizar sempre a aplicação para que seja relevante para si e / ou funcione com a versão Android e iOS instalada no seu dispositivo. No entanto, você se compromete a sempre aceitar as atualizações do aplicativo quando oferecidas a você. Também podemos desejar interromper o fornecimento do aplicativo e podemos encerrar o uso dele a qualquer momento sem aviso prévio para você. A menos que informemos o contrário, em caso de rescisão, (a) os direitos e licenças concedidos a você nestes termos serão encerrados; (b) você deve parar de usar o aplicativo e (se necessário) excluí-lo do dispositivo.</p>

                            <h2>Mudanças nestes Termos e Condições</h2>
                            <p>Podemos atualizar nossos Termos e Condições de tempos em tempos. Portanto, é recomendável revisar esta página periodicamente para verificar quaisquer alterações. Iremos notificá-lo de quaisquer alterações, publicando os novos Termos e Condições nesta página.</p>
                            <p>Estes termos e condições são válidos a partir de {configData.launchDate}.</p>

                            <h2>Contate-Nos</h2>
                            <p>Se você tiver alguma dúvida ou sugestão sobre os nossos Termos e Condições, não hesite em entrar em contato conosco em <a href={`mailto:${configData.defaultContactEmail}`} target='_blank'>{configData.defaultContactEmail}</a>.</p>
                            <Button
                                className='buttonColorSecond smallButton'
                                label='voltar'
                                onClick={handleGoBack}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default TermsAndConditions;

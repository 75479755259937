import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../App.css';
import '../Common/Tiles/ProductTile.css';
import './Checkout.css';
import Button from '../Common/Button';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { formatPrice } from '../Utilities/Formats';
import defaultImage from '../images/defaultImage.jpg';
import { configData, contentData } from '../Whitelabel/websitesData';
import { baraoHyundaiFilter } from '../Whitelabel/Barao/baraoHyundaiFilter';

import { useDispatch } from 'react-redux';
import { INCREASE_QUANTITY, DECREASE_QUANTITY, REMOVE_FROM_CART } from '../reducers/cart';

function ProductRow({ hasSale = false, itemQty, id, title, titleComplement, originalPrice, price, img, stock, onClick }) {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(itemQty);

  const gtmAddToCart = () => {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
                currency: 'BRL',
                value: price, // Optional: total value of the item being added
                items: [{
                    id: id,
                    name: title,
                    price: price,
                    quantity: 1,
                    seller: '',
                    discount: ''
                }]
            }
        });
    } else {
        console.warn("dataLayer is not defined");
    }
  };

  const incrementQty = () => {
    const availableStock = stock;
    if (quantity < availableStock) {
      setQuantity(prevQty => prevQty + 1);
      dispatch({ type: INCREASE_QUANTITY, payload: { id } });
      gtmAddToCart();
    } else {
      alert(`Não é possível adicionar mais unidades. Estoque: ${availableStock}`);
    }
  };

  const decrementQty = () => {
    if (quantity > 1) {
      setQuantity(prevQty => prevQty - 1);
      dispatch({ type: DECREASE_QUANTITY, payload: { id } });
      gtmRemoveFromCart(1);  // Remove 1 item
    } else {
      dispatch({ type: REMOVE_FROM_CART, payload: { id } });
      gtmRemoveFromCart(quantity);  // Remove all items of this type
    }
  };
  
  const gtmRemoveFromCart = (qty) => {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'remove_from_cart',
            ecommerce: {
                currency: 'BRL',
                remove: {
                    products: [{
                        id: id,
                        name: title,
                        quantity: qty,
                    }]
                }
            }
        });
    } else {
        console.warn("dataLayer is not defined");
    }
  };

  const removeItem = () => {
    dispatch({ type: REMOVE_FROM_CART, payload: { id } });
    gtmRemoveFromCart(quantity);
  }

  return (
    <div className='row center' style={{gap: '10px'}}>
      <div className='column center'>
        <Button
          className='buttonStyle buttonColorTransparentDark tinyButton'
          icon={<AddIcon/>}
          onClick={incrementQty}
        />
        <p style={{margin: '4px auto'}}>{quantity}</p>
        <Button
          className='buttonStyle buttonColorTransparentDark tinyButton redText'
          icon={<RemoveIcon/>}
          onClick={decrementQty}
        />
      </div>
      <div className='checkoutItemInfo clickArea' style={{justifyContent: 'space-between', padding: '10px 10px 10px 0px'}} onClick={onClick}>

        <div className='row center' style={{gap: '6px'}}>
          {configData.defaultStore === 'Peças Kia' && baraoHyundaiFilter.includes(id)
            ?
              <img src={contentData.missingPic} className='productRowImg'/>
            :
              <img src={img ? img : defaultImage} className='productRowImg'/>
          }
          <div className='column '>
              <h3 className='' translate="no">
                {title} {titleComplement}
              </h3>
              {/* <h4 className='tileDescription'>
                  {props.description && Object.entries(props.description).map(([key, value], index) => (
                      <p key={index}>
                          <span>{key}: </span>
                          <span>{value}</span>
                      </p>
                  ))}
              </h4> */}
              {/* <h3 className='grayedText'>Código: {props.id}
                {props.description && Object.entries(props.description).map(([key, value], index) => (
                  <span key={index}>, {key}: {value}</span>
                ))}</h3> */}
              <h3 className='grayedText noSelect'>Código: {id}</h3>
              <div className='row' style={{gap: '6px'}}>
                {hasSale &&
                  <p className='originalPrice'>{formatPrice(originalPrice*quantity)}</p>
                }
                <p className='mainPrice' style={{fontWeight: '100'}}>{formatPrice(price*quantity)}</p>
              </div>
          </div>
        </div>
      </div>
      <Button
          className='mainButtonColor buttonColorLight smallButton grayedText buttonLabelAnim'
          label='remover'
          iconStart={<DeleteIcon/>}
          onClick={removeItem}
        />
    </div>
  );
}

// Define PropTypes
ProductRow.propTypes = {
  hasSale: PropTypes.bool,
  itemQty: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleComplement: PropTypes.string,
  originalPrice: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  img: PropTypes.string,
  stock: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ProductRow;
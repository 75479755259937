import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../App.css';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSelectingVehicle } from '../reducers/vehicle';
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YoutubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import BusinessIcon from "@mui/icons-material/Business";
import WhatsappIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import { NavLink } from 'react-router-dom';
import { storeInfo } from '../Utilities/Placeholders';
import { contentData, configData } from '../Whitelabel/websitesData';
import UHD_footer_bg_01 from '../images/UmuaramaHD/UHD_footer_bg_01.jpg';
import Button from '../Common/Button';

function Footer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSelectingVehicle, selectedVersion } = useSelector(state => state.vehicle);
  const [ leadFormName, setLeadFormName ] = useState('');
  const [ leadFormEmail, setLeadFormEmail ] = useState('');

  const openVehicleSelect = () => {
    if (selectedVersion) {
      navigate('/categorias');
    } else {
      dispatch(setIsSelectingVehicle(!isSelectingVehicle));
    }
  };

  const socialLinks = contentData.socialMedia

  const socialIcon = {
    Instagram: InstagramIcon,
    Facebook: FacebookIcon,
    LinkedIn: LinkedInIcon,
    Youtube: YoutubeIcon,
    Twitter: TwitterIcon,
  };  

  const whatsappDefaultLink = `https://api.whatsapp.com/send/?phone=%2B${configData.whatsappNumber}&text=Olá, tudo bem?`;

  const handleWhatsappClick = () => {
      window.open(whatsappDefaultLink, '_blank');
  }
  const handleEmailClick = () => {
      window.open(`mailto:${configData.defaultContactEmail}`, '_blank');
  }

  const leadFormSubmit = (e) => {
    e.preventDefault();
    //console.log('leadForm: ', leadFormName, leadFormEmail);
  }

  const GoPartsSvg = () => {
    return (
      <svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 82 18" style={{ enableBackground: 'new 0 0 82 18' }} xmlSpace="preserve" fill='currentColor'>
        <g>
          <path className="cls-1" d="M81.6,10.2L81.6,10.2c-0.3-0.6-0.7-1-1.2-1.4c-0.5-0.4-1.1-0.7-1.8-1c-0.7-0.3-1.5-0.6-2.3-0.8
            c-0.4-0.1-0.8-0.3-1.1-0.4c-0.3-0.1-0.6-0.2-0.8-0.4c-0.2-0.1-0.4-0.3-0.5-0.4c-0.1-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.5
            c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.5-0.1,0.7-0.1c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.5,0.2,0.7,0.3
            c0.2,0.1,0.4,0.2,0.6,0.4s0.4,0.3,0.5,0.4l0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3-0.1,0.4-0.2L81,3.6
            c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.2-0.2-0.4c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2-0.2-0.4-0.3-0.6-0.5
            c-0.2-0.2-0.5-0.3-0.8-0.5c-0.6-0.3-1.3-0.6-1.9-0.7c-0.7-0.2-1.3-0.2-2.1-0.2H51.8H49c-0.2,0-0.3,0.1-0.4,0.1
            c-0.1,0.1-0.1,0.2-0.1,0.4v8.9l-3.2-8.4L45,0.6c0-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.1h-3c-0.1,0-0.1,0-0.2,0
            c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0.1-0.1,0.1-0.1,0.2l-1.1,2.7c0-0.1-0.1-0.2-0.1-0.2c-0.2-0.4-0.5-0.8-0.8-1.2
            c-0.3-0.4-0.7-0.7-1.1-0.9c-0.4-0.3-0.8-0.5-1.3-0.6c-0.5-0.1-1-0.2-1.5-0.2h-7.5c-0.2,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.4
            v1.4c0.1,0.1,0.2,0.2,0.4,0.3c0.8,0.8,1.4,1.8,1.9,2.9c0.4,1.1,0.7,2.3,0.7,3.5s-0.2,2.4-0.7,3.5c-0.4,1.1-1.1,2-1.9,2.9
            c-0.1,0.1-0.2,0.2-0.4,0.3v1.5c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.1,0.4,0.1h3.5c0.4,0,0.5-0.2,0.5-0.5v-5.6H35
            c0.6,0,1.2-0.1,1.7-0.3l-2.3,5.9c-0.1,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.1,0.4,0.1h3.5c0.3,0,0.5-0.1,0.6-0.4
            c0.1-0.4,0.3-0.8,0.5-1.2c0.2-0.4,0.3-0.8,0.5-1.2H46c0.1,0.4,0.3,0.8,0.4,1.2c0.1,0.4,0.3,0.8,0.5,1.2c0.1,0.2,0.1,0.3,0.2,0.3
            c0.1,0.1,0.2,0.1,0.4,0.1h5c0.4,0,0.5-0.2,0.5-0.5v-5.3H55l2.8,5.4c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.4,0.1h4
            c0.1,0,0.3,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.4c-0.1-0.1-0.2-0.3-0.2-0.4l-3-5.7c0.4-0.2,0.8-0.5,1.2-0.9
            c0.4-0.3,0.7-0.7,0.9-1.1c0.3-0.4,0.5-0.9,0.6-1.3c0.1-0.5,0.2-1,0.2-1.5c0-0.6-0.1-1.1-0.2-1.7h1.2v12.9c0,0.2,0.1,0.3,0.2,0.4
            c0.1,0.1,0.2,0.2,0.4,0.2h3.5c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.4V4.3h1.2c0,0.2,0,0.4,0,0.6c0,0.7,0.1,1.3,0.4,1.8
            c0.2,0.5,0.6,1,1.1,1.5c0.5,0.5,1.1,0.9,1.8,1.2c0.7,0.4,1.5,0.7,2.5,1c0.2,0.1,0.5,0.2,0.8,0.3c0.3,0.1,0.6,0.2,0.8,0.4
            c0.3,0.1,0.5,0.3,0.7,0.5c0.2,0.2,0.3,0.4,0.3,0.6c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2,0.1-0.4,0.3-0.6,0.3
            c-0.3,0.1-0.6,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.1-0.8-0.3-1.2-0.6c-0.3-0.2-0.5-0.4-0.7-0.5s-0.4-0.3-0.5-0.4
            c-0.1-0.1-0.2-0.2-0.3-0.2s-0.2-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.4,0.2l-2,1.9c-0.2,0.2-0.3,0.3-0.3,0.4c0,0.1,0.1,0.3,0.3,0.5
            c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0.1,0.1,0.3,0.3,0.5,0.5c0.2,0.2,0.5,0.4,0.8,0.7c0.8,0.5,1.6,0.9,2.4,1.3
            c0.9,0.3,1.8,0.5,2.7,0.5c1,0,1.8-0.1,2.7-0.4c0.8-0.3,1.6-0.6,2.2-1.1c0.6-0.5,1.1-1.1,1.5-1.7c0.4-0.7,0.5-1.4,0.5-2.3
            S81.9,10.8,81.6,10.2z M35.6,6.7c-0.1,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.3,0.3-0.6,0.4C34.4,7.9,34.2,8,33.9,8h-2.5V4h2.5
            c0.3,0,0.5,0.1,0.8,0.2s0.4,0.3,0.6,0.5c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.1,0.5,0.1,0.7C35.7,6.2,35.7,6.5,35.6,6.7L35.6,6.7z
             M41.1,11.1L41.1,11.1c0.1-0.4,0.3-0.8,0.5-1.3c0.2-0.5,0.3-0.9,0.5-1.4c0.1-0.5,0.3-0.9,0.5-1.4c0.2-0.5,0.3-0.9,0.5-1.3l1.8,5.4
            H41.1z M57.6,6.8c-0.1,0.2-0.2,0.5-0.3,0.6c-0.1,0.2-0.3,0.4-0.6,0.5c-0.2,0.1-0.5,0.2-0.7,0.2c-0.1,0-0.3,0-0.4,0
            c-0.1,0-0.2,0-0.4,0h-2.1V3.9h1.6c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0c0.3,0,0.5,0.1,0.7,0.3c0.2,0.1,0.4,0.3,0.6,0.5
            c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.1,0.5,0.1,0.7C57.7,6.3,57.7,6.6,57.6,6.8L57.6,6.8z"/>
          <path className="st0" d="M17.1,17.3c-0.9-0.4-1.6-0.8-2.3-1.4c0,0,0,0,0,0L3.1,18h17.6l0,0C19.4,18,18.2,17.7,17.1,17.3z"/>
          <path className="st0" d="M24,5.7c-0.4-0.4-0.9-0.7-1.5-1c-0.6-0.2-1.2-0.4-1.8-0.4c-0.7,0-1.3,0.1-1.8,0.4c-0.6,0.2-1,0.6-1.4,1
            c-0.4,0.4-0.7,0.9-0.9,1.5S16.1,8.3,16.1,9c0,0.6,0.1,1.2,0.3,1.8c0.2,0.6,0.5,1,0.9,1.4c0.4,0.4,0.9,0.7,1.4,1
            c0.6,0.2,1.2,0.4,1.8,0.4s1.3-0.1,1.8-0.4c0.6-0.2,1-0.6,1.5-1c0.4-0.4,0.7-0.9,0.9-1.4c0.2-0.6,0.3-1.2,0.3-1.8
            c0-0.6-0.1-1.2-0.3-1.8C24.7,6.6,24.4,6.1,24,5.7z M16.9,7.7c0.8-2.4,2,0.1,1.9,0.6S16.2,10.1,16.9,7.7z M18.4,12.2
            c-2-1.5,0.8-1.9,1.2-1.6C19.9,10.9,20.4,13.7,18.4,12.2z M20.7,7c-0.5,0-2.5-2,0-2C23.2,5,21.2,7,20.7,7z M23.1,12.2
            c-2,1.5-1.6-1.3-1.2-1.6C22.3,10.3,25.1,10.7,23.1,12.2z M22.6,8.4c-0.2-0.5,1.1-3,1.9-0.6C25.3,10.1,22.8,8.8,22.6,8.4z"/>
          <path className="st0" d="M4.4,16.7l9.5-1.7c-0.6-0.7-1.2-1.6-1.5-2.5c-0.3-0.8-0.6-1.7-0.6-2.7h-0.6c-0.2,0-0.3,0-0.4,0.1
            c-0.1,0.1-0.1,0.2-0.1,0.4v3.1c-0.3,0.1-0.6,0.1-0.9,0.2c-0.3,0-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.1-1.8-0.4c-0.5-0.2-1-0.6-1.4-1
            c-0.4-0.4-0.7-0.9-0.9-1.5C4.6,10.2,4.5,9.6,4.5,9s0.1-1.2,0.3-1.8C5,6.6,5.3,6.1,5.7,5.7c0.4-0.4,0.8-0.8,1.4-1
            c0.5-0.2,1.1-0.4,1.8-0.4c0.5,0,1,0.1,1.5,0.2c0.5,0.2,0.9,0.4,1.3,0.7c0.2,0.1,0.3,0.1,0.4,0.1c0.1,0,0.2-0.1,0.3-0.3l1.9-2.8
            c0.1-0.2,0.1-0.3,0-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.7-0.5-1.5-0.9-2.4-1.1C10.8,0.2,9.9,0,9,0C8.2,0,7.4,0.1,6.6,0.3
            C5.8,0.6,5.1,0.9,4.4,1.2C3.8,1.6,3.2,2.1,2.6,2.6c-0.5,0.6-1,1.2-1.4,1.8C0.8,5.1,0.5,5.9,0.3,6.6C0.1,7.4,0,8.2,0,9
            s0.1,1.6,0.3,2.4c0.2,0.8,0.5,1.5,0.9,2.1c0.4,0.7,0.9,1.3,1.4,1.8C3.2,15.9,3.8,16.3,4.4,16.7L4.4,16.7z"/>
        </g>
      </svg>
    );
  }

  return (
    <div className='footerContainer' style={{backgroundImage: (configData.defaultStore === 'UmuaramaHD' || configData.defaultStore === 'UmuaramaMotos') ? `url(${UHD_footer_bg_01})` : '', paddingTop: (configData.defaultStore === 'UmuaramaHD') ? '0': '20px'}}>
      
      {/* {(configData.defaultStore === 'UmuaramaHD' || configData.defaultStore === 'UmuaramaMotos') &&
        <div className='leadFormContainer'>
          <form className='leadFormRow' onSubmit={leadFormSubmit}>
            <div className='leadFormText'>
              <h2>Fique por dentro das melhores ofertas</h2>
              <span>cadastre seu email e <span style={{ color: 'var(--secondColor)' }}>ganhe 5%</span> na sua próxima compra</span>
            </div>
            <input
              placeholder='Digite seu nome'
              value={leadFormName}
              onChange={(e) => setLeadFormName(e.target.value)}
            />
            <input
              placeholder='Digite seu email'
              value={leadFormEmail}
              onChange={(e) => setLeadFormEmail(e.target.value)}
            />
            <Button
              className='buttonColorThird smallButton'
              label='Cadastrar'
              type='submit'
            />
          </form>
        </div>
      } */}

      <div className="footerMenu">
          <div className='column' style={{gap: '10px'}}>
            <h4>Loja</h4>
            <NavLink to={'/categorias'}>Comprar</NavLink>
            <NavLink to={'/checkout'}>Carrinho</NavLink>
            <NavLink to={'/minha-conta#pedidos'}>Pedidos</NavLink>
            <NavLink to={'/minha-conta#meus-dados'}>Conta</NavLink>
            {configData.defaultStore === 'Peças Kia' &&
              <div className='defaultItemBgDark row' style={{maxWidth: '400px', fontSize: '12px', gap: '10px', textAlign: 'start', alignItems: 'center', marginBottom: '10px'}}>
                <img src={contentData.kiaBaraoLogoWhite} alt='Kia Barão' style={{width: '100px'}}/>
                <span>A Loja PeçasKia.com.br é um e-commerce oficial da Concessionária Kia Barão em Belo Horizonte.</span>
              </div>
            }
            
            <h4>Atendimento</h4>
            <a onClick={handleWhatsappClick} style={{display: 'flex', alignItems: 'center', gap: '5px'}}><WhatsappIcon/>{configData.whatsappNumber}</a>
            <a onClick={handleEmailClick} style={{display: 'flex', alignItems: 'center', gap: '5px'}}><EmailIcon/>{configData.defaultContactEmail}</a>
          </div>

          <div className='column' style={{gap: '10px'}}>
            <h4>Informações</h4>
            <NavLink to={'/sobre-nos'}>Sobre nós</NavLink>
            <NavLink to={'/privacidade'}>Política de Privacidade</NavLink>
            <NavLink to={'/termos-e-condicoes'}>Termos e Condições</NavLink>
            <NavLink to={'/troca-e-devolucao'}>Troca e Devolução</NavLink>
            {configData.defaultStore === 'Peças Kia' &&
              <NavLink to={'/entrega'}>Política de Entrega</NavLink>
            }
            {configData.defaultStore !== 'Peças Kia' &&
              <NavLink to={'/reembolso'}>Reembolso</NavLink>
            }
          </div>

          <div className='column' style={{gap: '10px'}}>
            <h4>Mais</h4>
            {Object.entries(socialLinks).map(([key, value]) => {
              const IconComponent = socialIcon[key]; // Dynamically get the icon component
              return value ? ( // Check if there is a link
                <Link to={value} key={key} target="_blank" rel="noopener noreferrer" style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                  {IconComponent && <IconComponent />}
                  {key}
                </Link>
              ) : null;
            })}
          </div>
      </div>
      {contentData.footerContent.length > 0 &&
        <div className='defaultItemBgDark column center' style={{fontSize: '12px', marginInline: '20px', textAlign: 'center', marginBottom: '10px'}}>
          {contentData.footerContent.map((item, index) => (
            <span key={index}>{item}</span>
          ))}
        </div>
      }
      <div className='defaultItemBgDark column center' style={{fontSize: '12px', marginInline: '20px', textAlign: 'center', marginBottom: '10px'}}>
        <span>{configData.CNPJ}</span>
        <span>{configData.CNPJaddress}</span>
        <span>® {configData.defaultStore} - {new Date().getFullYear()}</span>
      </div>
      {/* {configData.defaultStore !== 'GoParts' && */}
      <div className='defaultItemBgDark row center' style={{gap: '8px', width: '100%', padding: '30px 10px'}}>
        <a className='row center' style={{gap: '8px'}} onClick={() => window.open('https://paraempresas.goparts.com.br', '_blank')}>
          <span>Desenvolvido por</span>
          <div className='column'>
            {/* <img className='footerLogo' src={GoPartsLogo}/> */}
            <GoPartsSvg className='footerLogo'/>
            <span style={{fontSize: '11px', color: 'white !important'}}>para Empresas</span>
          </div>
        </a>
      {/* } */}
      </div>
    </div>
  );
} 

export default Footer;
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import Button from '../Common/Button';
import { configData } from '../Whitelabel/websitesData';

function CookieBar() {
    const [visibleCookieBar, setVisibleCookieBar] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [acceptedCookies, setAcceptedCookies] = useState(() => localStorage.getItem('acceptedCookies') === 'true');

    // First mount effect
    useEffect(() => {
        setMounted(true);
        return () => setMounted(false);
    }, []);

    // Visibility effect
    useEffect(() => {
        if (mounted) {
            const timer = setTimeout(() => {
                setVisibleCookieBar(true);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [mounted]);

    const acceptCookieClick = () => {
        // if (configData.defaultStore === 'Iesa') {
        //     window.sendAdoptCommand('open');
        // } else {
        //     setVisibleCookieBar(false);
        //     localStorage.setItem('acceptedCookies', 'true');
        //     setTimeout(() => {
        //         setAcceptedCookies(true);
        //     }, 300);
        // }
        setVisibleCookieBar(false);
        localStorage.setItem('acceptedCookies', 'true');
        setTimeout(() => {
            setAcceptedCookies(true);
        }, 300);
    };

    if (acceptedCookies) return null;

    // Keep the same visibility logic for all stores
    if (!mounted || (!configData.defaultStore === 'Iesa' && acceptedCookies)) return null;

    const isIesa = configData.defaultStore === 'Iesa';

    return (
        <div className={`cookieBarContainer ${!visibleCookieBar ? 'hiddenCookieBar' : 'visibleCookieBar'}`}>
            <div className='cookieBarColumn'>
                <span>
                    {isIesa 
                        ? 'Utilizamos cookies para melhorar sua experiência. Clique para gerenciar suas preferências de privacidade.'
                        : 'Cookies nos ajudam a entregar nossos serviços. Ao usar nossos serviços, você concorda com a '
                    }
                    {!isIesa && <Link to='/privacidade'>Política de Privacidade</Link>}
                </span>
                <Button
                    className='buttonColorThird smallButton'
                    label={isIesa ? 'Mostrar Avisos' : 'Aceitar e Continuar'}
                    onClick={acceptCookieClick}
                />
            </div>
        </div>
    );
}

export default CookieBar;
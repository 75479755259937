import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../App.css';
import './SellerTile.css';
import Button from '../Button';
import ArrowRightIcon from "@mui/icons-material/ArrowRightRounded";
import AddCartIcon from "@mui/icons-material/ShoppingCartCheckout";
import { formatPrice, formatPercent } from '../../Utilities/Formats';
import { BrandsList } from '../../Utilities/BrandsList';
import { SwapVert, Check, Star, StarHalf, StarOutline, KeyboardArrowUp, CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import { configData } from '../../Whitelabel/websitesData';
import { CircularProgress } from '@mui/material';
import { firestore } from '../../firebase' // Adjust the import according to your Firebase configuration
import { query, where, getDocs, collection } from "firebase/firestore";
import { getMktName } from '../../Providers/Sellers';
import { brandKeywords } from '../../Product/ProductPage';


function SellerTile({isSelected, buyButtons, originalPrice = '0', price = '0', calculatedShipping = null, avgScoreDisplay = null, roundedStars = null, isLoadingShipping = false, ...props}) {
  const hasSale = originalPrice && parseFloat(originalPrice) > 0 && parseFloat(originalPrice) > parseFloat(price);

  const [mktName, setMktName] = useState('');
  const [sellerBrand, setSellerBrand] = useState('');

  useEffect(() => {
    const fetchMktName = async () => {
      const mktName = await getMktName(props.name); // Call the utility function and get the market name
      setMktName(mktName); // Set the fetched market name in the state
    };
  
    fetchMktName();
  }, [props.name]);

  const brandKeywords = {
    audi: 'Audi',
    bmw: 'Bmw',
    byd: 'Byd',
    chery: 'Chery',
    chevrolet: 'Chevrolet',
    citroen: 'Citroen',
    fiat: 'Fiat',
    ford: 'Ford',
    'harley-davidson': 'Harley Davidson',
    harley: 'Harley Davidson',
    honda: 'Honda',
    'honda motos': 'Honda Motos',
    hyundai: 'Hyundai',
    jeep: 'Jeep',
    kia: 'Kia',
    'mercedes-benz': 'Mercedes-Benz',
    mini: 'Mini',
    mitsubishi: 'Mitsubishi',
    nissan: 'Nissan',
    peugeot: 'Peugeot',
    renault: 'Renault',
    toyota: 'Toyota',
    volks: 'Volkswagen',  // Add 'volks' for Volkswagen
    vw: 'Volkswagen',     // Add 'vw' for Volkswagen
    volvo: 'Volvo',
    yamaha: 'Yamaha',
    psa: 'Psa',
    ram: 'Ram',
  };

  useEffect(() => {
    const fetchMktName = async () => {
        await getMktName(props.name, setMktName); // Call the utility function
        getSellerBrand(mktName); // Call the refactored function with mktName
    };

    fetchMktName();
  }, [props.name, mktName]);

  const getSellerBrand = (mktName) => {
    // Convert mktName to lowercase for case-insensitive matching
    const lowerCaseMktName = mktName?.toLowerCase();
    
    for (const keyword in brandKeywords) {
        if (lowerCaseMktName?.includes(keyword)) {
          setSellerBrand(brandKeywords[keyword]);
          return;
        }
    }
    // if ((configData.defaultStore === 'UmuaramaHD' || configData.defaultStore === 'UmuaramaMotos')) {
    //   setSellerBrand('Harley Davidson');
    // }

  };

  // Function to calculate lowest and highest delivery price and time
  const getShippingInfo = (shippingOptions) => {
    if (!shippingOptions || shippingOptions.length === 0) {
        return { lowestPrice: '-', highestPrice: '-', lowestTime: '-', highestTime: '-' };
    }

    let lowestPrice = Infinity;
    let highestPrice = -Infinity;
    let lowestTime = Infinity;
    let highestTime = -Infinity;

    shippingOptions.forEach(option => {
      const price = parseFloat(option.deliveryPrice.replace(',', '.')); // Convert price to a float
      const time = parseInt(option.deliveryTime); // Convert time to an integer

      if (price < lowestPrice) lowestPrice = price;
      if (price > highestPrice) highestPrice = price;
      if (time < lowestTime) lowestTime = time;
      if (time > highestTime) highestTime = time;
    });

    return {
      lowestPrice: formatPrice(lowestPrice),
      highestPrice: formatPrice(highestPrice),
      lowestTime: lowestTime === Infinity ? '-' : lowestTime,
      highestTime: highestTime === -Infinity ? '-' : highestTime
    };
  };

  const { lowestPrice, highestPrice, lowestTime, highestTime } = getShippingInfo(calculatedShipping?.shippingOptions);

  const prefixLogos = {
    'AC Delco': 'ACDELCO',
    'Audi': 'GENUINAVWAUDI',
    'BMW': 'GENUINABMWMINI',
    'Bproauto': 'BPROAUTO',
    'Chevrolet': 'GENUINACHEVROLET',
    'Citroen': 'GENUINA',
    'Fiat': 'GENUINAFCA',
    'Ford': 'GENUINAFORD',
    'Harley Davidson': 'GENUINAHD',
    'Hyundai': 'GENUINAKIAHYUNDAI',
    'Jeep': 'GENUINAFCA',
    'Kia': 'GENUINAKIAHYUNDAI',
    'KTM': 'GENUINAKTM',
    'Mini': 'GENUINABMWMINI',
    'Mitsubishi': 'GENUINAMITSUBISHI',
    'Motorcraft': 'MOTORCRAFT',
    'Motrio': 'MOTRIO',
    'Nissan': 'GENUINA',
    'Peugeot': 'GENUINA',
    'Renault': 'GENUINA',
    'Toyota': 'GENUINATOYOTA',
    'Volkswagen': 'GENUINAVWAUDI',
    'Volvo': 'GENUINAVOLVO',
    'Yamaha': 'GENUINAYAMAHA',
  }

  const sellerLogos = [];

  Object.keys(prefixLogos).forEach(key => {
    if (prefixLogos[key] === props.logo) {
      sellerLogos.push(key); // Append key to sellerLogo if the value matches the logo
    }
  });

  return (
    <div className={`sellerTileContainer clickArea ${isSelected ? 'selectedSeller' : 'sellerSelect'}`} onClick={isLoadingShipping ? () => {} : props.onClick}>

      <div className='row' style={{gap: '10px', alignItems: 'center', width: '100%'}}>
        {/* {sellerLogos.map((logo, index) => (
          <img key={index} src={BrandsList[logo]} className='sellerLogo' loading='lazy'/>
        ))} */}
        {/* {props.logo && props.logo != '' && props.logo != 'Genuína' &&
          <img src={BrandsList[props.logo]} alt={props.logo} className='sellerLogo' loading='lazy'/>
          } */}
        <img src={BrandsList[sellerBrand]} alt={sellerBrand} className='sellerLogo' loading='lazy'/>
        <div className='rowToColumn500'>
          <div className='rowToColumn500' style={{justifyContent: 'start', gap: '10px'}}>

            <div className='column'>

              <div className='row'>
                {/* {props.logo && props.logo != '' && props.logo != 'Genuína' &&
                  <img src={BrandsList[props.logo]} alt={props.logo} className='sellerLogoMobile' loading='lazy'/>
                } */}
                <h2 className='tileSellerName'>
                  <span>
                    {(configData.defaultStore == 'GoParts' || configData.defaultStore == 'Peças Kia')
                      ?
                        <span>{mktName}</span>
                      :
                        <span>{props.name}</span>
                    }
                  </span>
                </h2>
              </div>

              {/* {avgScoreDisplay && roundedStars
                ?
                  <div className='row' style={{gap: '6px', borderBottom: '1px solid lightGray', marginBottom: '8px'}}>
                    {props.logo && props.logo != '' && props.logo != 'Genuína' &&
                      <img src={BrandsList[props.logo]} alt={props.logo} className='sellerLogoMobile' loading='lazy'/>
                    }
                    <h2 className='tileSellerName'>{props.name}</h2>
                    {configData.defaultStore != 'UmuaramaHD' &&
                      <div className='row' style={{gap: '4px'}}>

                        <Star className='reviewStarStaticSmall'/>

                        <div className='sellerStarsRow'>
                            {Array(roundedStars).fill().map((_, index) => (
                                <Star className='reviewStarStaticSmall' key={index} />
                            ))}
                            {Array(5 - roundedStars).fill().map((_, index) => (
                                <StarOutline className='reviewStarStaticSmall' key={index + roundedStars} />
                            ))}
                        </div>
                        <span>({avgScoreDisplay})</span>
                      </div>
                    }
                  </div>
                :
                  <div className='row'>
                    {props.logo && props.logo != '' && props.logo != 'Genuína' &&
                      <img src={BrandsList[props.logo]} alt={props.logo} className='sellerLogoMobile' loading='lazy'/>
                    }
                    <h2 className='tileSellerName'>{props.name}</h2>
                  </div>

              } */}

              <div className='rowToColumn500' style={{gap: '10px'}}>

                <div className='sellerInfo' style={{height: '100%', justifyContent: 'center'}}>
                  {price &&
                    <>
                      {hasSale &&
                        <p className='sellerOriginalPrice'>{formatPrice(originalPrice)}</p>
                      }
                      <div className='row' style={{alignItems: 'center', gap: '6px'}}>
                        <h2 className='sellerMainPrice'>{formatPrice(price)}</h2>
                        {hasSale &&
                          <div className='discountHighlight'>
                            <h2>{`${formatPercent(Math.round(((price / originalPrice) - 1) * -1 * 100) / 100)} off`}</h2>
                          </div>
                        }
                      </div>
                    </>
                  }
                  {props.stock &&
                    <div className='column'>
                      {props.stock == 1 &&
                          <span className='sellerHighlight'>Última unidade</span>
                      }
                      {props.stock > 1 &&
                          <>
                              {props.stock > 100
                                  ? <span style={{color: 'var(--grayedText)'}}><span className='sellerHighlight'>+100</span> unidades</span>
                                  : <span style={{color: 'var(--grayedText)'}}><span className='sellerHighlight'>{props.stock.toLocaleString('pt-BR')}</span> unidades</span>
                              }
                          </>
                      }
                    </div>
                  }
                </div>

                {isLoadingShipping &&
                  <div className='row center' style={{height: '100px'}}>
                    <CircularProgress
                        sx={{ color: 'var(--secondColor)' }}
                    />
                  </div>
                }

                {calculatedShipping &&
                    <div className={`row center defaultBorderedItem ${isSelected ? 'selectedSeller' : ''}`} style={{gap: '10px'}}>
                      <span className='grayedText'>Fretes:</span>
                      <div className='column'>
                        {lowestPrice !== highestPrice
                          ?
                            <span>{lowestPrice} a {highestPrice}</span>
                          :
                            <span>{lowestPrice}</span>
                        }
                        {lowestTime !== highestTime
                          ?
                            <span>{lowestTime} a {highestTime == 1 ? `${highestTime} dia útil` : `${highestTime} dias úteis`}</span>
                          :
                            <span>{lowestTime == 1 ? `${lowestTime} dia útil` : `${lowestTime} dias úteis`}</span>
                        }
                      </div>
                    </div>
                }

                {/* {calculatedShipping &&
                    <div className={`column defaultBorderedItem ${isSelected ? 'selectedSeller' : ''}`}>
                      <span className='grayedText'>Fretes a partir de:</span>
                      <span>{lowestPrice}</span>
                      <span>{lowestTime}</span>
                    </div>
                } */}

              </div>
            </div>
          </div>
        </div>

        <div className='column center'>
          {isSelected 
            ? 
              <Button
                className='buttonColorMain smallButton'
                icon={<Check/>}
              />
            : 
              <Button
                className='buttonColorSelectSeller smallButton'
                icon={<CheckBoxOutlineBlank/>}
              />
          }
        </div>

      </div>
    </div>
  );
}

// Define PropTypes
SellerTile.propTypes = {
  originalPrice: PropTypes.string,
  price: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  logo: PropTypes.string,
  calculatedShipping: PropTypes.object // Added for shipping information
};

export default SellerTile;

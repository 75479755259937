import { doc, setDoc, getDoc, collection, serverTimestamp} from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { firestore } from "../firebase"; // Make sure to initialize Firebase and export db from your firebase configuration file
import Address from "./address";

class GPUser {
  constructor({
    email = null,
    cpf = null,
    cnpj = null,
    telefone = null,
    chassis = null,
    password = null,
    cep = null,
    name = null,
    id = null,
    admin = false,
    address = null,
    cookiesAccepted = false,
    dealersDistanceMap = {},
    cards = [],
  } = {}) {
    this.email = email;
    this.cpf = cpf;
    this.cnpj = cnpj;
    this.telefone = telefone;
    this.chassis = chassis;
    this.password = password;
    this.cep = cep;
    this.name = name;
    this.id = id;
    this.admin = admin;
    this.address = address;
    this.cookiesAccepted = cookiesAccepted;
    this.dealersDistanceMap = dealersDistanceMap;
    this.cards = cards;
  }

  get firestoreRef() {
    return doc(firestore, `users/${this.id}`);
  }

  static async fromDocument(document) {
    const user = new GPUser({
      id: document.id,
      name: document.get("name"),
      email: document.get("email"),
    });

    try {
      user.cep = document.get("cep") || "";
    } catch {
      user.cep = "";
    }

    try {
      user.cookiesAccepted = document.get("cookiesAccepted") || false;
    } catch {
      user.cookiesAccepted = false;
    }

    try {
        const userData = document.data();
      let fetchedAddresses = [];

      if (Array.isArray(userData.address)) {
        // Case where address is an array
        fetchedAddresses = userData.address;
      } else if (userData.address) {
        // Case where address is a single object
        fetchedAddresses = [
          {
            name: 'Meu endereço',
            ...userData.address
          }
        ];
      }
      user.address = fetchedAddresses;
    } catch {
      user.address = null;
    }

    try {
      user.cpf = document.get("cpf") || "";
    } catch {
      user.cpf = "";
    }

    try {
      user.cnpj = document.get("cnpj") || "";
    } catch {
      user.cnpj = "";
    }

    try {
      user.telefone = document.get("telefone") || "";
    } catch {
      user.telefone = "";
    }

    try {
      user.chassis = document.get("chassis") || "";
    } catch {
      user.chassis = "";
    }

    try{
      user.cards = document.get("cards") || [];
    } catch {
      user.cards = [];
    }

    return user;
  }

  async saveData() {
    const data = this.toMap()
    await setDoc(this.firestoreRef,data );
  }

  toMap() {
    return {
      name: this.name,
      email: this.email,
      cep: this.cep,
      cookiesAccepted: this.cookiesAccepted,
      cards: this.cards,
      ...(this.address && Array.isArray(this.address) && {
        address: this.address.map(addr => addr) // Since it's already a dictionary
      }),
      ...(this.cpf && { cpf: this.cpf }),
      ...(this.cnpj && { cnpj: this.cnpj }),
      ...(this.telefone && { telefone: this.telefone }),
      ...(this.chassis && { chassis: this.chassis }),
    };
  }

  get cartReference() {
    return collection(this.firestoreRef, "cart");
  }

  get tokensReference() {
    return collection(this.firestoreRef, "tokens");
  }

  setAddress(address) {
    const newAddress = {
      "city": address.city,
      "complement":address.complement,
      "district":address.neighborhood,
      "name":address.name,
      "number":address.streetNumber,
      "state": address.uf,
      "street": address.street,
      "zipCode": address.cep.replace(/[.-]/g, ''),
    }
    this.address.push(newAddress);
    //this.address = address;
    this.saveData();
  }

  removeAddress(index) {
    if (index >= 0 && index < this.address.length) {
        // Remove the address from the array
        this.address.splice(index, 1);
        this.saveData(); // Save the updated address list to Firestore
    } else {
        console.error("Address not found");
    }
  }

  editAddress(index, updatedAddress) {
    if (index >= 0 && index < this.address.length) {
        // Update the address at the specified index
        this.address[index] = { ...this.address[index], ...updatedAddress };
        this.saveData(); // Save the updated address list to Firestore
    } else {
        console.error("Endereço não encontrado");
    }
}

  setCpf(cpf) {
    this.cpf = cpf;
    this.saveData();
  }

  setCnpj(cnpj) {
    this.cnpj = cnpj;
    this.saveData();
  }

  removeCpf() {
    delete this.cpf; // Remove the cpf property
    this.saveData(); // Save the updated user data to Firestore
  }

  removeCnpj() {
    delete this.cnpj; // Remove the cnpj property
    this.saveData(); // Save the updated user data to Firestore
  }

  setTelefone(telefone) {
    this.telefone = telefone;
    this.saveData();
  }

  setName(name) {
    this.name = name;
    this.saveData();
  }

  setCep(cep) {
    this.cep = cep;
    this.saveData();
  }

  setChassis(chassis) {
    this.chassis = chassis;
    this.saveData();
  }

  setCookiesAccepted(value) {
    this.cookiesAccepted = value;
    this.saveData();
  }

  setCard(card){
    this.cards.push(card);
    this.saveData();
  }

  async saveToken() {
    const messaging = getMessaging();
    const token = await getToken(messaging);
    await setDoc(doc(this.tokensReference, token), {
      token,
      updatedAt: serverTimestamp(),
      platform: navigator.platform,
    });
  }
}

export default GPUser;

import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import '../App.css';
import './Checkout.css';
import { setIsSelectingVehicle } from '../reducers/vehicle';
import CheckoutItem from './CheckoutItem';
import Button from '../Common/Button';
import ShippingIcon from "@mui/icons-material/LocalShippingOutlined";
import AddressIcon from "@mui/icons-material/HomeWork";
import PickupIcon from "@mui/icons-material/ShoppingBagOutlined";
import CouponIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import { ArrowRight, Close, Edit, ChevronLeft, ArrowBackSharp, Add, AddShoppingCart, ShoppingCart, QrCode2, CreditCard } from '@mui/icons-material';
import CartIcon from "@mui/icons-material/ShoppingCart";
import { formatPercent, formatPrice } from '../Utilities/Formats';
import { auth } from '../firebase';
import AddressTile from './AddressTile';
import ShippingTile from './ShippingTile';
import { UfList, UserAddresses, ShippingOptions, userCreditCards as initialUserCreditCards, userData, CardOperators } from '../Utilities/Placeholders'; // Ensure the import path is correct
import antifraudIcon from '../images/Icone_Antifraude.png';
import CardTile from './CardTile';
import ClickOutside from '../Utilities/ClickOutside';
import EnterIcon from "@mui/icons-material/Login";
import { contentData, configData } from '../Whitelabel/websitesData';
import { RESET_FROM_CART } from '../reducers/cart';
import { useUser } from '../Providers/UserContext';
import { functions } from '../firebase'; // Ensure the correct path to your firebase.js
import { httpsCallable } from 'firebase/functions';
import { getFirestore, collection, query, where, getDocs,onSnapshot } from 'firebase/firestore';
import { CircularProgress } from '@mui/material';
import { getAddress } from '../Utilities/logisticservices';
import { firestore } from '../firebase';
import { getMktName } from '../Providers/Sellers';
import GoParca_Zen from '../images/artwork/goparca_03_full_mirror_zen_nardo.webp';
import GoParca_Zen_2 from '../images/artwork/goparca_03_full_zen_nardo.webp';
import Ssl from '../images/ssl_selo.webp';
import Lgpd from '../images/LGPD_small.webp';
import ThreeDSecure from '../images/3dSecure.jpg';
import MasterSecure from '../images/masterCardSecureCode.jpg';
import VisaSecure from '../images/verifiedByVisa.jpg';
import ProductSlider from '../Common/Sliders/ProductSlider';
import { baraoHyundaiFilter } from '../Whitelabel/Barao/baraoHyundaiFilter';
import { isValidDocument, getDocumentType } from '../Utilities/DocCheck';


const cardNumPatternDefaults = {
  "VISA": new Set([/^4/]),
  "MASTERCARD": new Set([
    /^5[1-5]/, // Covers all Mastercard numbers from 51-55
    /^2221|2222|2223|2224|2225|2226|2227|2228|2229/,
    /^223|224|225|226|227|228|229/,
    /^23|24|25|26/,
    /^270|271/,
    /^2720/,
  ]),
  "AMEX": new Set([/^34/, /^37/]),
  "DISCOVER": new Set([
    /^6011/,
    /^64[4-9]/,
    /^65/,
  ]),
  "DINERS": new Set([
    /^30[0-5]/,
    /^36/,
    /^38/,
    /^39/,
  ]),
  "JCB": new Set([
    /^35(28|29|30|31|32|33|34|35|36|37|38|39)/,
    /^2131/,
    /^1800/,
  ]),
  "UNIONPAY": new Set([
    /^620/,
    /^624|626/,
    /^62100|62101|62102|62103|62104|62105|62106|62107|62108|62109|6211[0-9]{2}/,
    /^6218[4-7]/,
    /^62185|62186|62187/,
    /^6220[0-5]/,
    /^62201[0-9]{1,3}/,
    /^622018/,
    /^622019|622[0-9]{2}/,
    /^6220[7-9]/,
    /^6221[2-5][0-9]{2}/,
    /^623|624|625|626/,
    /^6270|6272|6276/,
    /^6277[0-9]{2}/,
    /^62778[1-9]|6277[8-9][0-9]/,
    /^628[2-9]/,
    /^629[1-2]/,
    /^810/,
    /^8110|8131/,
    /^8132|8151/,
    /^8152|8163/,
    /^8164|8171/,
  ]),
  // "MAESTRO": new Set([
  //   /^493698/,
  //   /^5[0-6]|5[0-9]/,
  //   /^5066[8-9][0-9]{2}/,
  //   /^56[0-9]|59/,
  //   /^63/,
  //   /^67/,
  // ]),
  "ELO": new Set([
    /^401178/,
    /^401179/,
    /^438935/,
    /^457631|457632/,
    /^431274/,
    /^451416/,
    /^457393/,
    /^504175/,
    /^5066[8-9][0-9]{2}/,
    /^5090[0-9]{3}/,
    /^627780/,
    /^6362[7-9]/,
    /^636368/,
    /^65(0031|0032|0033|0035|0051)/,
    /^6504[0-3]/,
    /^65048[5-8]|6505[3-8]/,
    /^65054[1-8]|6505[4-9]/,
    /^6507[0-8]/,
    /^65072[0-7]/,
    /^6509[0-7][0-9]|65098[0-8]/,
    /^6516[5-9]|6517[0-9]/,
    /^6550[0-1]|65502[1-8]/,
  ]),
  "MIR": new Set([/^220[0-4]/]),
  "HIPER": new Set([
    /^637095/,
    /^637568/,
    /^637599/,
    /^637609/,
    /^637612/,
    /^63743358/,
    /^63737423/,
  ]),
  "HIPERCARD": new Set([/^606282/]),
};

// Function to determine card type
function getCardType(cardNumber) {
  for (const [cardType, patterns] of Object.entries(cardNumPatternDefaults)) {
    for (const pattern of patterns) {
      if (pattern.test(cardNumber)) {
        return cardType;
      }
    }
  }
  return 'Unknown'; // If no patterns match
}
function picpay_get_browser_information() {
  const utc = -(new Date().getTimezoneOffset() / 60);
  return {
    "httpAcceptBrowserValue": navigator.userAgent,
    "httpAcceptContent": document.contentType || 'text/html',
    "httpBrowserLanguage": navigator.language,
    "httpBrowserJavaEnabled": navigator.javaEnabled(),
    "httpBrowserJavaScriptEnabled": true,
    "httpBrowserColorDepth": window.screen.colorDepth.toString(),
    "httpBrowserScreenHeight": window.screen.height.toString(),
    "httpBrowserScreenWidth": window.screen.width.toString(),
    "httpBrowserTimeDifference": utc.toString(),
    "userAgentBrowserValue": navigator.userAgent,
  }
}

function Checkout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation(); // Get the current location

  const { selectedBrand, isSelectingVehicle, selectedYear, selectedModel, selectedVersion, InternalID } = useSelector(state => state.vehicle);

  const validCoupons = configData.coupons;

  const [actualPage, setActualPage] = useState('carrinho');
  const { gpuser, setUser } = useUser();

  const [couponHighlight, setCouponHighlight] = useState(false);
  const [payAddressHighlight, setPayAddressHighlight] = useState(false);

  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
  const defaultAddressIndex = UserAddresses.findIndex((address) => address.isDefault);

  const [selectedShippingOptions, setSelectedShippingOptions] = useState({});
  const [mktNames, setMktNames] = useState({});

  const [isInsertingCoupon, setIsInsertingCoupon] = useState(false);
  const [activeCoupon, setActiveCoupon] = useState(false);
  const [coupon, setCoupon] = useState('');
  const [paymentOption, setPaymentOption] = useState('pix');

  const [clientDoc, setClientDoc] = useState('');
  const [phone, setPhone] = useState('');
  const [vehicleId, setVehicleId] = useState('');

  const [selectedCardIndex, setSelectedCardIndex] = useState(initialUserCreditCards.length > 0 ? 0 : null);
  const [addingNewCard, setAddingNewCard] = useState(false);
  // const [addingNewCard, setAddingNewCard] = useState(initialUserCreditCards.length === 0);

  const [cardNumber, setCardNumber] = useState('');
  const [validThru, setValidThru] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');

  const [isCardNumberValid, setIsCardNumberValid] = useState(null);
  const [isCardHolderNameValid, setIsCardHolderNameValid] = useState(null);
  const [isValidThruValid, setIsValidThruValid] = useState(null);
  const [isVerificationCodeValid, setIsVerificationCodeValid] = useState(null);

  const [isClientDocValid, setIsClientDocValid] = useState(null);
  const [isPhoneValid, setIsPhoneValid] = useState(null);

  const [isAddingPayAddress, setIsAddingPayAddress] = useState(false);
  const [newPayAddressSelected, setNewPayAddressSelected] = useState(false);
  const [isSamePayAddressSelected, setIsSamePayAddressSelected] = useState(false);


  const [isAddingNoLoginAddress, setIsAddingNoLoginAddress] = useState(true);
  const [isAddingAddressLogged, setIsAddingAddressLogged] = useState(false);

  const [showCustomAddress, setShowCustomAddress] = useState(false);
  const [paymentData, setPaymentData] = useState(null);

  const [userCreditCards, setUserCreditCards] = useState(initialUserCreditCards);
  const selectedCard = selectedCardIndex !== null ? userCreditCards[selectedCardIndex] : null;

  const [foundCoupon, setFoundCoupon] = useState(null);
  const [discountValue, setDiscountValue] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [isFreeShipping, setIsFreeShipping] = useState(false);

  const [purchasePrice, setPurchasePrice] = useState(0);

  const [shippingOptions, setshippingOptions] = useState(null);

  const [loadingShippingOptions, setLoadingShippingOptions] = useState(true);

  const shippingBlackList = [
    "Open Veículos Cascavel",
    "VETOR HYUNDAI CASCAVEL",
    "BRIZZA MITSUBISHI CASCAVEL",
    "UMUARAMA TOYOTA CALDAS NOVAS",
    "AUDI CENTER TERESINA",
    "CANADA GM",
    "JAPAN MOTOS MATRIZ",
    "JAPAN NISSAN TERESINA",
    "Via Paris Bairro dos Noivos",
    "ALEMANHA VW",
  ];

  const [selectedParcels, setSelectedParcels] = useState(1); // default to 1 parcel

  const [auth3DS_accessToken, setAuth3DS_accessToken] = useState('');
  const [auth3DS_url, setAuth3DS_url] = useState('');

  const [heightChallenge, setHeightChallenge] = useState('1');
  const [widthChallenge, setWidthChallenge] = useState('1');
  const [auth3DS_challenge_accessToken, setAuth3DS_challenge_accessToken] = useState('');
  const [auth3DS_challenge_url, setAuth3DS_challenge_url] = useState('');

  const [finalizeLoading, setFinalizeLoading] = useState(false);
  const [waitChallenge, setWaitChallenge] = useState(true);

  const [challengeCompleted, setChallengeCompleted] = useState(false);

  const [confirmResult, setConfirmResult] = useState('');
  const [isFinalized, setIsFinalized] = useState(false);

  const [isLoadingChallengeApproval, setIsLoadingChallengeApproval] = useState(false);
  const [isLoadingSummary, setIsLoadingSummary] = useState(false);

  const [searchingCep, setSearchingCep] = useState(false);

  const [cartItemsCheck, setCartItemsCheck] = useState(null);
  const [addressAuxCheck, setAddressAuxCheck] = useState(null);

  const [pixJson, setPixJson] = useState(null);

  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [mktName, setMktName] = useState('');

//   const [relatedProducts, setRelatedProducts] = useState([]);
  
//   const fetchCompatibleProducts = async () => {
//     if (InternalID) {
//         const warehouseRef = collection(firestore, 'gp_warehouse');
//         const q = query(warehouseRef, where('carsList', 'array-contains', InternalID));
//         const querySnapshot = await getDocs(q);
        
//         // Map to only include the .ds_number of each fetched item
//         const compatibleProducts = querySnapshot.docs.map(doc => doc.data().ds_number);
//         setRelatedProducts(compatibleProducts);
//     }
// }

//   useEffect(() => {
//     fetchCompatibleProducts();
//   }, [InternalID]);

//   const relatedProductsData = [
//     {
//       img: 'tabRelated',
//       name: 'Produtos Relacionados',
//       products: relatedProducts
//     },
//   ];

  useEffect(() => {
    setPhone(gpuser?.telefone || '');
    setClientDoc(gpuser?.cpf ? gpuser.cpf : gpuser?.cnpj || '');
  }, [gpuser]);

  useEffect(() => {
    // Push the initial state to the history stack
    window.history.pushState({ page: actualPage }, '', `#${actualPage}`);
    fetchShippingQuotes();

    const handlePopState = (event) => {
      if (event.state && event.state.page) {
        setActualPage(event.state.page);
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    if (actualPage !== window.history.state.page) {
      window.history.pushState({ page: actualPage }, '', `#${actualPage}`);
    }
  }, [actualPage]);

  useEffect(() => {
    // Add event listener for postMessage to handle the completion of the 3DS challenge
    const handleMessage = (event) => {
      //console.log("3DS MESSAGES RECEIVED");
      //console.log("Origin:", event.origin);
      //console.log("Message Data:", event.data);
      // Validate the message origin for security reasons
      if (event.origin === "https://centinelapi.cardinalcommerce.com") {
        // Check if the message contains a JWT or indicates challenge completion
        if (event.data && event.data[0] && event.data[0].CardinalJWT) {
          // setIsLoadingChallengeApproval(true);
          //console.log("3DS challenge completed with JWT:", event.data[0].CardinalJWT);
          

          setChallengeCompleted(true);

          // You can now use the JWT to complete the payment verification process or any other logic
        }
      } else {
        console.warn("Message received from untrusted origin:", event.origin);
      }
    };

    // Add the message event listener
    window.addEventListener("message", handleMessage);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  const scrollToRef = (ref, offset) => {
    const element = document.getElementById(ref);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top;
      window.scrollTo({
        top: elementPosition + window.pageYOffset - offset,
        behavior: 'smooth',
      });
    }
}

  const addNewPayAddress = () => {
    setIsAddingPayAddress(true);
    setNewPayAddressSelected(false);
  }

  const handleSamePayAddressClick = () => {
    setIsSamePayAddressSelected(true);
    setNewPayAddressSelected(false);
  }

  const pixSelect = () => {
    setPaymentOption('pix');
    setAddingNewCard(false);
    setSelectedParcels(1);
    // navigateToPage('pagamento2');
  }

  const tempShippingInfo = useRef({
    tempCart: {},
    tempAddress: {}
  });

  const tempShippingOps = useRef({
    shippingOps: {},
    cart: {},
    address: {}
  });

  const savedAddress = JSON.parse(localStorage.getItem('tempAddress') || '{}');

  // Then use it in your state initialization
  const [noLoginAddress, setNoLoginAddress] = useState({
    streetNumber: JSON.parse(localStorage.getItem('tempAddress') || '{}').streetNumber || '',
    complement: JSON.parse(localStorage.getItem('tempAddress') || '{}').complement || '',
    name: '',
    street: '',
    streetNumber: '',
    neighborhood: '',
    city: '',
    uf: '',
    cep: '',
  });
  
  const [newPayAddress, setNewPayAddress] = useState({
    street: '',
    streetNumber: '',
    complement: '',
    neighborhood: '',
    city: '',
    uf: '',
    cep: '',
  });
  
  const [newLoggedAddress, setNewLoggedAddress] = useState({
    name: '',
    street: '',
    streetNumber: localStorage.getItem('tempAddress.streetNumber') || '',
    complement: localStorage.getItem('tempAddress.complement') || '',
    neighborhood: '',
    city: '',
    uf: '',
    cep: '',
  });

  const [errors, setErrors] = useState({});

  const handleNewPayAddressInputChange = (e) => {
    const { id, value } = e.target;
    const numericValue = id === 'streetNumber' ? value.replace(/[^0-9]/g, '') : value.replace(/[:$!@#%&*]/g, ''); // Allow only numbers for streetNumber
    setNewPayAddress((prevState) => ({
        ...prevState,
        [id]: numericValue,
    }));
};

  const handleNewAddressLogedInputChange = (e) => {
    const { id, value } = e.target;
    const numericValue = id === 'streetNumber' ? value.replace(/[^0-9]/g, '') : value.replace(/[:$!@#%&*]/g, ''); // Allow only numbers for streetNumber
    setNewLoggedAddress((prevState) => ({
        ...prevState,
        [id]: numericValue,
    }));
};

  const handleNoLoginAddressInputChange = (e) => {
    const { id, value } = e.target;
    const numericValue = id === 'streetNumber' ? value.replace(/[^0-9]/g, '') : value.replace(/[:$!@#%&*]/g, ''); // Allow only numbers for streetNumber
    setNoLoginAddress((prevState) => ({
        ...prevState,
        [id]: numericValue,
    }));
};

  const validateField = (field, value) => {
    let errorMessage = '';
    if (!value) {
      errorMessage = 'Campo obrigatório';
    }
    return errorMessage;
  };

  const validateForm = () => {
    const { street, streetNumber, neighborhood, city, uf } = newPayAddress;
    const newErrors = {
      street: validateField('street', street),
      streetNumber: validateField('streetNumber', streetNumber),
      neighborhood: validateField('neighborhood', neighborhood),
      city: validateField('city', city),
    };

    // Validate UF against UfList
    if (!UfList[newPayAddress.uf]) {
      newErrors.uf = '*';
    }

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === '');
  };

  const validateFormNewAddressLogged = () => {
    const { street, streetNumber, neighborhood, city, uf } = newLoggedAddress;
    const newErrors = {
      street: validateField('street', street),
      streetNumber: validateField('streetNumber', streetNumber),
      neighborhood: validateField('neighborhood', neighborhood),
      city: validateField('city', city),
    };

    // Validate UF against UfList
    if (!UfList[newLoggedAddress.uf]) {
      newErrors.uf = '*';
    }

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === '');
  };

  const validateFormNoLoginAddress = () => {
    const { street, streetNumber, neighborhood, city, uf } = noLoginAddress;
    const newErrors = {
      street: validateField('street', street),
      streetNumber: validateField('streetNumber', streetNumber),
      neighborhood: validateField('neighborhood', neighborhood),
      city: validateField('city', city),
    };

    // Validate UF against UfList
    if (!UfList[noLoginAddress.uf]) {
      newErrors.uf = '*';
    }

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === '');
  };

  const handleNewPayAddressSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      //console.log('New pay address:', newPayAddress);
      setNewPayAddressSelected(true);
      setIsAddingPayAddress(false);
      setIsSamePayAddressSelected(false);
    }
  };

  const handleNewAddressLoggedSubmit = (e) => {
    e.preventDefault();
    if (validateFormNewAddressLogged()) {
      //console.log('New logged address:', newLoggedAddress);
      tempShippingInfo.current.tempAddress = { ...newLoggedAddress };
      gpuser.setAddress(newLoggedAddress);
      // alert('!salvar endereço no login para aparecer na lista do checkout!');
      setIsAddingAddressLogged(false);
      setShowCustomAddress(false);
    }
  };

  const handleNoLoginAddressSubmit = (e) => {
    e.preventDefault();
    if (validateFormNoLoginAddress()) {
      //console.log('No login address:', noLoginAddress);
      localStorage.setItem('tempAddress', JSON.stringify(noLoginAddress));
      tempShippingInfo.current.tempAddress = { ...noLoginAddress };
      if (gpuser) {
        gpuser.setAddress(noLoginAddress);
      }
      setIsAddingNoLoginAddress(false);
    }
  };

  const [cep, setCep] = useState(localStorage.getItem('userCep') || '');
  const [formattedCep, setFormattedCep] = useState(() => {
    const savedCep = localStorage.getItem('userCep') || '';
    if (savedCep.length > 0) {
      if (savedCep.length > 5) {
        return `${savedCep.slice(0, 2)}.${savedCep.slice(2, 5)}-${savedCep.slice(5, 8)}`;
      } else if (savedCep.length > 2) {
        return `${savedCep.slice(0, 2)}.${savedCep.slice(2)}`;
      }
    }
    return savedCep;
  });

  useEffect(() => {
    const savedCep = localStorage.getItem('userCep');
    if (savedCep && savedCep.length === 8) {
      setCep(savedCep);
      searchCep(savedCep);
    }
  }, []);

  const renderParcelOptions = () => {
    const { maxParcels, checkoutParcels } = configData.payment;
    const totalAmount = totalPrice - discountValue + (isFreeShipping ? 0 : totalDeliveryPrice);

    let applicableParcels = 1;
    for (const [amount, parcels] of Object.entries(checkoutParcels)) {
        if (totalAmount >= parseFloat(amount)) {
            applicableParcels = parcels;
        } else {
            break;
        }
    }

    const options = [];
    for (let i = 1; i <= Math.min(applicableParcels, maxParcels); i++) {
        const parcelPrice = totalAmount / i;
        options.push(
            <option key={i} value={`${i}`}>{i}x de {formatPrice(parcelPrice)}</option> // Set the value to i
        );
    }
    return options;
  };

  const getApplicableParcelCount = () => {
    const { maxParcels, checkoutParcels } = configData.payment;
    const totalAmount = totalPrice - discountValue + (isFreeShipping ? 0 : totalDeliveryPrice);

    let applicableParcels = 1;
    for (const [amount, parcels] of Object.entries(checkoutParcels)) {
        if (totalAmount >= parseFloat(amount)) {
            applicableParcels = parcels;
        } else {
            break;
        }
    }

    return Math.min(applicableParcels, maxParcels);
  };

  const handleParcelChange = (event) => {
    const selectedValue = event.target.value.split('x')[0]; // Extract the number of parcels
    setSelectedParcels(Number(selectedValue)); // Save the number in selectedParcels as a number
  };

  function startListeningToChallenge(challengeID,cvv,cardholderAuthenticationId,orderdata) {
    // Set up the Firestore query
    //const q = query(collection(db, "picpayevents"), where("ID", "==", challengeID));

    const ordersRef = collection(firestore, 'picpayevents');
    const q = query(ordersRef, where('id', '==', challengeID));
    let paid = ``

    // Start listening to the collection for changes
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (doc) => {
          const data = doc.data();
          //console.log("3DS Challenge result received:", data);

          // Access the status field from the document data
          const status = data.data.status;
          //console.log("Challenge Status:", status);

          // Handle the challenge result here, e.g., update UI, redirect user, etc.
          if (status === "Approved") {
            unsubscribe();
            // Perform actions if the status is Approved
            //console.log("Payment approved!");

            const acquistion_data_ = {
              "chargeId": challengeID,
              "installmentNumber": selectedParcels,
              "installmentType": 1,
              "cvv": cvv,
              "cardholderAuthenticationId": cardholderAuthenticationId,
            };
            const order_json = {
              purchasedata: purchaseData,
              order: orderdata,
              user: gpuser,
              acquistion_data: acquistion_data_,
              storename:configData.defaultStore,
              storeweblink:configData.defaultStoreLink,
              sellercontact:configData.defaultContactEmail
            };
            //console.log('order_json', order_json);
            const acquistion_response = await picpay_3ds_authorizationwithDB(order_json);
            if (acquistion_response.data.charge.chargeStatus === 'NotAuthorized') {
              alert('Falha na autorização do cartão de crédito (7). Revise os dados e tente novamente.');
              setFinalizeLoading(false);
              return;
            }
            //console.log('acquistion_response', acquistion_response);
            //finalize(status);

            //console.log('orderdata credit: ', orderdata);
            // setConfirmResult('pgto-realizado');
            const confirmCreditData = {
              type: 'credit',
              brand: orderdata.brand,
              lastDigits: orderdata.c_num.slice(-4),
              value: orderdata.value,
              parcels: orderdata.parcels,
            }
            finalize(confirmCreditData);
            // You can add more logic here like updating the UI or notifying the user
          } else if (status === "Rejected") {
            alert('Autorização do pagamento rejeitada (8).');
            setFinalizeLoading(false);
            // navigate('/');
            //"Rejected"
            // Handle other status types (e.g., 'Rejected', 'Pending')
            //console.log("Payment status:", status);
            setConfirmResult('erro');
          } else {
            alert('Falha na autorização do cartão de crédito (9). Revise os dados e tente novamente.');
            setFinalizeLoading(false);
            // navigate('/');
            setConfirmResult('erro');
          }
        });
      } else {
        //console.log("No document found with ID:", challengeID);
      }
    });

    // Return the unsubscribe function to stop listening later if needed
    return unsubscribe;
  }

  useEffect(() => {
    if (userCreditCards.length > 0) {
      setSelectedCardIndex(0);
    }
  }, [userCreditCards]);

  const cartItems = useSelector(state => state.cart.carrinho);

  const handlePage = async (direction) => {
    scrollToTop();
    if (direction === 'forward') {
      if (actualPage === 'carrinho') {
        validateCoupon();
        tempShippingInfo.current.tempCart = { ...cartItems };
        setActualPage('endereço');
        gtmBeginCheckout();
        // if (gpuser && gpuser.address.length > 0) {
        //   setIsAddingAddressLogged(true);
        // }

      } else if (actualPage === 'endereço') {
        // if (!isAddingNoLoginAddress && noLoginAddress.street != '') {
        //   if (
        //     !(JSON.stringify(tempShippingOps.current.cart) === JSON.stringify(tempShippingInfo.current.tempCart) &&
        //     tempShippingOps.current.address.cep === tempShippingInfo.current.tempAddress.cep &&
        //     tempShippingOps.current.address.streetNumber === tempShippingInfo.current.tempAddress.streetNumber)
        //   ) {
        //     setLoadingShippingOptions(true);
        //     fetchShippingQuotes();
        //   }
        //   setActualPage('entrega');
        // } else if (gpuser) {
        //   if (
        //     !(JSON.stringify(tempShippingOps.current.cart) === JSON.stringify(tempShippingInfo.current.tempCart) &&
        //     tempShippingOps.current.address.cep === tempShippingInfo.current.tempAddress.cep &&
        //     tempShippingOps.current.address.streetNumber === tempShippingInfo.current.tempAddress.streetNumber)
        //   ) {
        //     setLoadingShippingOptions(true);
        //     fetchShippingQuotes();
        //   }
        //   setActualPage('entrega');
        //   // gtmBeginCheckout();
        // }

        setLoadingShippingOptions(true);
        fetchShippingQuotes();
        setActualPage('entrega');
        
      } else if (actualPage === 'entrega') {
        gtmAddShippingInfo();
        if (gpuser != null) {
          setActualPage('pagamento');
        } else {
          navigate('/login?destination=/checkout');
        }
      } else if (actualPage === 'pagamento') {
        setActualPage('pagamento2');
        if (paymentOption === 'credit') {
          setAddingNewCard(true);
          setVerificationCode('');
        }
      } else if (actualPage === 'pagamento2') {
        gtmAddPaymentInfo();
        if (paymentOption === 'pix') {
          // console.log(totalDeliveryPrice);
          // console.log(maxDeliveryTime);
          setIsLoadingSummary(true);
          handlePixInfoSubmit();
          if (isPixInfoValid()) {
            //console.log('purchaseData:', purchaseData);
            const phoneNumber = phone;
            const phonePart = phoneNumber.match(/\d{4,5}-\d{4}/)[0];
            //console.log(phonePart); // Outputs: '99503-1705'
            const areaCode = phoneNumber ? phoneNumber.match(/\((\d{2})\)/)?.[1] : null;
            const pixDoc = clientDoc;
            let orderdata = {
              name: gpuser.name,
              email: gpuser.email,
              mobile: phonePart.replace(/[.-]/g, ''),
              area_code: String(areaCode),
              cpfcnpj: pixDoc.replace(/[.\-\/]/g, ''),
              // cpfcnpj: gpuser.cpf ? gpuser.cpf.replace(/[.-]/g, '') : gpuser.cnpj.replace(/[./]/g, ''),
    
              documentType: pixDoc.replace(/[.\-\/]/g, '').length == 11 ? "CPF" : pixDoc.replace(/[.\-\/]/g, '').length == 14 ? "CNPJ" : null,
              // documentType: gpuser.cpf ? "CPF" : gpuser.cnpj ? "CNPJ" : null,
              street: purchaseData.clientAddress.street,
              number: purchaseData.clientAddress.number,
              neighborhood: purchaseData.clientAddress.district,
              city: purchaseData.clientAddress.city,
              UF: purchaseData.clientAddress.state,
              zipCode: purchaseData.clientAddress.zipCode,
              complement: purchaseData.clientAddress.complement.replace(/[,():;!@#$%&]/g, '') || 'sem complemento',
              parcels: 1,
              value: purchasePrice,
              ptype: "pix",
              description: "GoParts",
              storename:configData.defaultStore,
              storeweblink:configData.defaultStoreLink,
              sellercontact:configData.defaultContactEmail,
              deliveryPrice:totalDeliveryPrice,
              deliveryETD:maxDeliveryTime
            };
            const order_json = {
              purchasedata: purchaseData,
              order: orderdata,
              user: gpuser,
            };
            setPixJson(order_json);

            setActualPage('resumo');
            setIsLoadingSummary(false);
          } else {
            setIsLoadingSummary(false);
          }
        } else if (paymentOption === 'credit' && !addingNewCard) {
          // Define purchaseData with payment information

          const newCreditPaymentData = {
            type: 'credit',
            card: selectedCard, // Add selected card data here
          }
          setPaymentData(newCreditPaymentData);
          const purchaseData = {
            products: cartItems,
            clientAddress: selectedAddress,
            shipping: selectedShippingOptions,
            discount: foundCoupon,
            payment: paymentData,
          };
      
          // Log the purchaseData to check its content
          //console.log('purchaseData before RESUMO credit:', purchaseData);
      
          // Proceed to the next page
          setActualPage('resumo');
        }
      } else if (actualPage === 'resumo') {

        setFinalizeLoading(true);

        if(purchaseData.payment.type === 'pix') {
          const result = await createOrderPicPay(pixJson);
          finalize(result);
          setFinalizeLoading(false);
        } else if (purchaseData.payment.type === 'credit') {
          const phoneNumber = gpuser.telefone || phone;
          const phonePart = phoneNumber.match(/\d{4,5}-\d{4}/)[0];
          //console.log(phonePart); // Outputs: '99503-1705'
          const areaCode = phoneNumber ? phoneNumber.match(/\((\d{2})\)/)?.[1] : null;
          const [em, rawYear] = validThru.split('/');
          const ey = `20${rawYear}`;
          const value = parseFloat(purchasePrice.toFixed(2));
          const billingAddress = userCreditCards[0].address
          let orderdata = {
            // Personal Info
            name: gpuser.name.replace(/[^\p{L}\s]/gu, '').trim(), // ^[\p{L} ]+$
            email: gpuser.email,
            mobile: phonePart.replace(/\D/g, ''),
            area_code: String(areaCode),
            
            // Document
            cpfcnpj: clientDoc.replace(/[^A-Z0-9]/g, ''), // ^\d{11}$|^\d{14}$|^[A-Z0-9]{8,9}$
            documentType: clientDoc?.replace(/\D/g, '').length === 11 ? "CPF" : clientDoc?.replace(/\D/g, '').length === 14 ? "CNPJ" : null,
            
            // Billing Address - all using ^(?!\s*$)[\p{L}\d .'-]+$ except where noted
            street: billingAddress.street.replace(/[^\p{L}\d .'-]/gu, '').trim(),
            number: billingAddress.number.toLowerCase() === 's/n' ? 
              's/n' : 
              billingAddress.number.replace(/[^\p{L}\d .-]/gu, '').trim(), // ^(?!\s*$)((?i)s/n|[\p{L}\d .-]+)$
            neighborhood: billingAddress.district.replace(/[^\p{L}\d .'-]/gu, '').trim(),
            city: billingAddress.city.replace(/[^\p{L}\d .'-]/gu, '').trim(),
            UF: billingAddress.state.replace(/[^\p{L}]/gu, '').trim().slice(0, 2).toUpperCase(), // ^[\p{L} ]+$ and exactly 2 chars
            country: "Brazil".replace(/[^\p{L}\s]/gu, '').trim(), // ^[\p{L} ]+$
            zipCode: billingAddress.zipCode.replace(/\D/g, ''), // ^[0-9]+$
            complement: (billingAddress.complement || 'sem complemento').replace(/[^\p{L}\d .'-]/gu, '').trim(),
            
            // Card Info
            c_num: cardNumber.replace(/\D/g, '').slice(0, 16), // ^\d+$ and length 13-16
            cardHolderName: cardHolderName.replace(/[^\p{L}\s]/gu, '').trim(), // ^[\p{L} ]+$
            sc: verificationCode.replace(/\D/g, '').slice(0, 4), // ^\d+$ and length 3-4
            
            // Expiration
            ey: parseInt(ey), // >= 2000 and <= 9999
            em: parseInt(em), // >= 1 and <= 12
            
            // Card Brand - must be one of [VISA, MASTERCARD, AMEX, ELO, HIPERCARD, ALELO]
            brand: getCardType(cardNumber.replace(/\D/g, '')).toUpperCase(),
            
            // Other fields unchanged
            parcels: selectedParcels,
            value: value,
            ptype: "credit",
            p_type: "credit",
            description: "GoParts",
            storename: configData.defaultStore,
            storeweblink: configData.defaultStoreLink,
            sellercontact: configData.defaultContactEmail,
            deliveryPrice: totalDeliveryPrice,
            deliveryETD: maxDeliveryTime,
          };
          try {
            //console.log('userCreditCards[0].address: ', userCreditCards[0].address);
            //console.log('finalize order data: ', orderdata);
            const customer = {
              name: orderdata.name,
              email: orderdata.email,
              documentType: orderdata.documentType,
              document: orderdata.cpfcnpj,
              phone: {
                countryCode: "55",
                areaCode: orderdata.area_code,
                number: orderdata.mobile,
                type: "MOBILE",
              },
            };
            const card = {
              cardNumber: orderdata.c_num,
              cvv: orderdata.sc,
              cardholderDocument: orderdata.cpfcnpj,
              cardholderName: cardHolderName,
              expirationMonth: orderdata.em,
              expirationYear: orderdata.ey,
              brand: getCardType(orderdata.c_num),
            };
            const billing_address = {
              street: orderdata.street,
              number: orderdata.number,
              neighborhood: orderdata.neighborhood,
              city: orderdata.city,
              state: orderdata.UF,
              country: "Brazil",
              zipCode: orderdata.zipCode,
              complement: orderdata.complement,
            };
            const card_setup = {
              cardNumber: orderdata.c_num,
              cvv: orderdata.sc,
              cardholderDocument: orderdata.cpfcnpj,
              cardholderName: cardHolderName,
              expirationMonth: orderdata.em,
              expirationYear: orderdata.ey,
              brand: getCardType(orderdata.c_num),
              billingAddress: billing_address,
            };
            const card_enrollment = {
              number: orderdata.c_num,
              cvv: orderdata.sc,
              cardholderDocument: orderdata.cpfcnpj,
              cardholderName: cardHolderName,
              expirationMonth: orderdata.em,
              expirationYear: orderdata.ey,
              brand: getCardType(orderdata.c_num),
              cardType: 0,
              isPrivateLabel: false,
              billingAddress:billing_address,
            };
            const setup_request = {
              card: card_setup,
              value: orderdata.value,
            };
            const order_json = {
              purchasedata: purchaseData,
              order: orderdata,
              user: gpuser,
            };
            const setup_response = await picpay3DSSetup(setup_request);
            //console.log('setup_response: ', setup_response);
            //console.log(setup_response.data.chargeId);
            //console.log(setup_response.data.transactions[0].accessToken);
            //console.log(setup_response.data.transactions[0].deviceDataCollectionUrl);
            if (!setup_response.data) {
              alert('Falha na identificação do cartão de crédito (1). Revise os dados e tente novamente.');
              setFinalizeLoading(false);
              return;
            }
            setAuth3DS_accessToken(setup_response.data.transactions[0].accessToken);
            setAuth3DS_url(setup_response.data.transactions[0].deviceDataCollectionUrl);

            //console.log('auth3DS_accessToken_1st: ', auth3DS_accessToken);
            //console.log('auth3DS_url_1st: ', auth3DS_url);
            
            const enroll_request = {
              chargeId: setup_response.data.chargeId,
              customer: customer,
              amount: orderdata.value,
              card: card_enrollment,
              browser: picpay_get_browser_information(),
            };
            const enroll_response = await picpay3DSEnrollment(enroll_request);
            if (!enroll_response.data) {
              alert('Falha no reconhecimento do cartão de crédito (2). Revise os dados e tente novamente.');
              setFinalizeLoading(false);
              return;
            }
            //console.log('enroll_response', enroll_response);
            if(enroll_response.data.chargeStatus == "PreAuthorized"){
              //console.log("desafio")
              if(enroll_response.data.transactions[0].cardholderAuthenticationStatus == "Challenged"){
                setHeightChallenge(enroll_response.data.transactions[0].heightChallenge);
                setWidthChallenge(enroll_response.data.transactions[0].widthChallenge);
                setAuth3DS_challenge_accessToken(enroll_response.data.transactions[0].accessToken);
                setAuth3DS_challenge_url(enroll_response.data.transactions[0].stepUpUrl)
                startListeningToChallenge(enroll_response.data.chargeId,enroll_request.card.cvv,setup_response.data.transactions[0].cardholderAuthenticationId,orderdata)
              } else if (enroll_response.data.transactions[0].cardholderAuthenticationStatus == "Rejected"){
                alert('Pagamento rejeitado. Verifique com seu banco ou tente novamente com outro cartão.');
                setFinalizeLoading(false);
                return;
              } else if (enroll_response.data.transactions[0].cardholderAuthenticationStatus == "Approved"){
                const acquistion_data_ = {
                  "chargeId": enroll_response.data.chargeId,
                  "installmentNumber": selectedParcels,
                  "installmentType": 1,
                  "cvv": enroll_request.card.cvv,
                  "cardholderAuthenticationId": setup_response.data.transactions[0].cardholderAuthenticationId,
                };
                const order_json = {
                  purchasedata: purchaseData,
                  order: orderdata,
                  user: gpuser,
                  acquistion_data: acquistion_data_,
                  storename:configData.defaultStore,
                  storeweblink:configData.defaultStoreLink,
                  sellercontact:configData.defaultContactEmail
                };
                //console.log('order_json', order_json);
                const acquistion_response = await picpay_3ds_authorizationwithDB(order_json);
                if (acquistion_response.data.charge.chargeStatus === 'NotAuthorized') {
                  alert('Falha na autorização do cartão de crédito (3). Revise os dados e tente novamente.');
                  setFinalizeLoading(false);
                  return;
                }
                const confirmCreditData = {
                  type: 'credit',
                  brand: orderdata.brand,
                  lastDigits: orderdata.c_num.slice(-4),
                  value: orderdata.value,
                  parcels: orderdata.parcels,
                }
                finalize(confirmCreditData);
              }
            }
            else if (enroll_response.data.chargeStatus == "Authorized"){
              const acquistion_data_ = {
                "chargeId": enroll_response.data.chargeId,
                "installmentNumber": selectedParcels,
                "installmentType": 1,
                "cvv": enroll_request.card.cvv,
                "cardholderAuthenticationId": setup_response.data.transactions[0].cardholderAuthenticationId,
              };
              const order_json = {
                purchasedata: purchaseData,
                order: orderdata,
                user: gpuser,
                acquistion_data: acquistion_data_,
                storename:configData.defaultStore,
                storeweblink:configData.defaultStoreLink,
                sellercontact:configData.defaultContactEmail
              };
              //console.log('order_json', order_json);
              const acquistion_response = await picpay_3ds_authorizationwithDB(order_json);
              if (acquistion_response.data.charge.chargeStatus === 'NotAuthorized') {
                alert('Falha na autorização do cartão de crédito (4). Revise os dados e tente novamente.');
                setFinalizeLoading(false);
                return;
              }
              //console.log('acquistion_response', acquistion_response);
              //finalize(status);
  
              //console.log('orderdata credit: ', orderdata);
              // setConfirmResult('pgto-realizado');
              const confirmCreditData = {
                type: 'credit',
                brand: orderdata.brand,
                lastDigits: orderdata.c_num.slice(-4),
                value: orderdata.value,
                parcels: orderdata.parcels,
              }
              finalize(confirmCreditData);
            } else if (enroll_response.data.chargeStatus == "NotAuthorized") {
              alert('Pagamento não autorizado (5). Verifique com seu banco ou tente novamente com outro cartão.');
              setFinalizeLoading(false);
              return;
            } else if (!auth3DS_challenge_url) {
              alert('Falha na comunicação com o seu banco (6). Verifique com seu banco ou tente novamente com outro cartão.');
              setFinalizeLoading(false);
              return;
            }
            // AQUI ESTA CORRETO BEGIN
            //const result = await createOrderPicPay(order_json);
            ////console.log(result);
            // AQUI ESTA CORRETO END
          } catch (err) {
            console.error('Error calling createOrderPicPay:', err);
            setFinalizeLoading(false);
          } finally {
            // setLoadingShippingOptions(false);
            //console.log('loading shipping 01');
          }

        }
        // finalize();
      }
    } else if (direction === 'backward') {
      if (actualPage === 'resumo') {
        if (!finalizeLoading) {
          setActualPage('pagamento2');
        }
      } else if (actualPage === 'pagamento2') {
        handleAddNewCard(false);
        setActualPage('pagamento');
      } else if (actualPage === 'pagamento') {
        if (
          !(JSON.stringify(tempShippingOps.current.cart) === JSON.stringify(tempShippingInfo.current.tempCart) &&
          tempShippingOps.current.address.cep === tempShippingInfo.current.tempAddress.cep &&
          tempShippingOps.current.address.streetNumber === tempShippingInfo.current.tempAddress.streetNumber)
        ) {
          setLoadingShippingOptions(true);
          fetchShippingQuotes();
        }
        setActualPage('entrega');
      } else if (actualPage === 'entrega') {
        setActualPage('endereço');
      } else if (actualPage === 'endereço') {
        setActualPage('carrinho');
      } else if (actualPage === 'carrinho') {
        // Optionally handle the case when you are already at the first page
        //console.log('Already at the first page');
      }
    }
  };

  // useEffect(() => {
  //   console.log('auth3DS_challenge_url: ', auth3DS_challenge_url);
  // }, [auth3DS_challenge_url]);

  const navigateToPage = (page) => {
    setActualPage(page);
  };


  const getSelectedAddress = () => {
    if (selectedAddressIndex !== null && selectedAddressIndex >= 0 && gpuser && selectedAddressIndex < gpuser.address.length) {
      return gpuser.address[selectedAddressIndex];
    }
    return null; // Handle cases where selectedAddressIndex is out of bounds or not set
  };

  const selectedAddress = getSelectedAddress();

  const totalPrice = cartItems.reduce((total, item) => {
    total += (item.price * item.itemQty);

    return total;
  }, 0);

  // Group items by seller and fetch market names
  let cartItemsBySellers = useMemo(() => {
    const groupedItems = {};

    cartItems.forEach(item => {
      const sellerName = item.sellerInfo.name;
      if (!groupedItems[sellerName]) {
        groupedItems[sellerName] = [];
      }
      groupedItems[sellerName].push(item);
    });

    return Object.entries(groupedItems).map(([sellerName, items]) => ({
      sellerName,
      items,
    }));
  }, [cartItems]);

  // Fetch market names for each seller
  useEffect(() => {
    const fetchMktNames = async () => {
      const mktNamePromises = cartItemsBySellers.map(async sellerGroup => {
        const sellerName = sellerGroup.sellerName;
        const mktName = await getMktName(sellerName); // Call without setMktName
        return { sellerName, mktName };
      });
  
      const results = await Promise.all(mktNamePromises);
      const mktNamesMap = {};
      results.forEach(({ sellerName, mktName }) => {
        mktNamesMap[sellerName] = mktName;
      });
      setMktNames(mktNamesMap); // Update state with the fetched market names
    };
  
    if (cartItemsBySellers.length > 0) {
      fetchMktNames();
    }
  }, [cartItemsBySellers]);

  // Function to scroll to the next seller div by id with vertical offset
  const scrollToNextSeller = (currentIndex) => {
    const offset = 120;
    const nextIndex = currentIndex + 1;
    const nextSellerId = `seller-${nextIndex}`;
    const nextSellerDiv = document.getElementById(nextSellerId);

    if (nextSellerDiv) {
      // Get the position of the next seller div
      const rect = nextSellerDiv.getBoundingClientRect();
      // Calculate the position with offset
      const scrollPosition = window.scrollY + rect.top - offset;
      // Smooth scroll to the calculated position
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    } else {
      scrollToTop();
      navigateToPage('pagamento');
    }
  };

  // // Set initial selected shipping options when component mounts
  // useEffect(() => {
  //   const storedSelectedOptions = JSON.parse(sessionStorage.getItem('selectedShippingOptions')) || {};
  //   const initialSelectedOptions = {};

  //   // Check if stored options match current cart items
  //   const currentShippingOptionsMatch = cartItemsBySellers.every(sellerGroup => {
  //       return storedSelectedOptions[sellerGroup.sellerName] === ShippingOptions[0].name;
  //   });

  //   // Only set initial options if they don't match the stored options
  //   if (!currentShippingOptionsMatch) {
  //       cartItemsBySellers.forEach(sellerGroup => {
  //           initialSelectedOptions[sellerGroup.sellerName] = ShippingOptions[0].name;
  //       });
  //       setSelectedShippingOptions(initialSelectedOptions);
  //   } else {
  //       // If they match, set the selected options from sessionStorage
  //       setSelectedShippingOptions(storedSelectedOptions);
  //   }
  // }, [cartItemsBySellers]);

  const handleCouponChange = (e) => {
    const value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
    setCoupon(value);
  };

  const handleInsertCoupon = () => {
    setIsInsertingCoupon(true);
  }

  const validateCoupon = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    
    // if (coupon === '') {
    //   // Optionally, show an error or message for empty coupon field
    //   alert('Please enter a coupon code.');
    //   return;
    // }
  
    const foundCoupon = validCoupons.find(c => c.coupon === coupon);
    if (foundCoupon) {
      const discountPercent = foundCoupon.discountPercent || 0;
      const discountPrice = foundCoupon.discountPrice || 0;
      setDiscountedPrice(discountPrice);
      const discountFromPercent = totalPrice * discountPercent;
      const discountValue = discountFromPercent + discountPrice;
  
      const updatedCoupon = {
        ...foundCoupon,
        discountValue: discountValue
      };

      setFoundCoupon(updatedCoupon);
      setDiscountValue(Math.round(discountValue * 100) / 100); // Round to two decimals
      setDiscountPercent(discountPercent);
      setIsFreeShipping(updatedCoupon.freeShipping);
      setActiveCoupon(true);
      //console.log('+Coupon Added! ', updatedCoupon);
      //console.log('purchasePrice ', purchasePrice);
    } else {
      //console.log('invalid coupon');
      setCouponHighlight(true);
      setTimeout(() => {
        setCouponHighlight(false);
      }, 600);
      setCoupon('');
      setDiscountValue(0);
      setDiscountPercent(0);
      setIsFreeShipping(false);
      setActiveCoupon(false);
      // cancelCoupon();
    }
  };

  // useEffect(() => {
  //   console.log('coupon: ', coupon)
  // }, [coupon]);

  const handleShippingOptionClick = (sellerName, optionName) => {
    setSelectedShippingOptions(prevState => {
        const newState = {
            ...prevState,
            [sellerName]: optionName
        };
        
        // Save the updated selected options to sessionStorage
        sessionStorage.setItem('selectedShippingOptions', JSON.stringify(newState));
        
        // Calculate purchase price after state has been updated
        return newState;
    });

    // Use a separate function to calculate purchase price
    calculatePurchasePrice();
    
    if (activeCoupon && foundCoupon.freeShipping) {
        setIsFreeShipping(true);
    } else {
        setIsFreeShipping(false);
    }
};

  // Calculate sum of delivery prices and highest delivery time
  const calculateSummary = () => {
    let totalDeliveryPrice = 0;
    let maxDeliveryTime = 0;

    //console.log(cartItemsBySellers);

    cartItemsBySellers.forEach((map, index) => {
      //console.log(`Map ${index + 1}:`);
      for (const [key, value] of Object.entries(map)) {
        //console.log(`${key}: ${value}`);
      }
    });

    cartItemsBySellers.forEach(seller => {
      //console.log(`Seller: ${seller.sellerName}`);
      const orderPerSeller = seller;

      // Check if the sellerName exists in selectedShippingOptions to avoid summing multiple times
      if (selectedShippingOptions[seller.sellerName]) {
        const optionName = selectedShippingOptions[seller.sellerName];
        const selectedOption = orderPerSeller.shippingop?.find(option => option.name === optionName);

        if (selectedOption) {
          const price = parseFloat(selectedOption.deliveryPrice ? selectedOption.deliveryPrice.replace(',', '.') : 0);
          const time = selectedOption.deliveryTime ? parseInt(selectedOption.deliveryTime, 10) : 0;

          totalDeliveryPrice += price;

          if (time > maxDeliveryTime) {
            maxDeliveryTime = time;
          }
        }
      }
    });

    return { totalDeliveryPrice, maxDeliveryTime };
  };

  const { totalDeliveryPrice, maxDeliveryTime } = calculateSummary();

  useState(() => {
    setSelectedAddressIndex(defaultAddressIndex);
  }, [defaultAddressIndex]);

  const handleAddressClick = async (index) => {
    setSelectedAddressIndex(index); // Update selected address index

    // Translate keys from gpuser.address[index] to the desired format
    const { zipCode: cep, number: streetNumber, ...rest } = gpuser.address[index]; // Destructure and rename keys
    tempShippingInfo.current.tempAddress = {
        cep, // Translated key
        streetNumber, // Translated key
        ...rest // Spread the rest of the properties
    };

    // await fetchShippingQuotes();
    // navigateToPage('entrega');
  };

  const handleNoLoginAddressClick = () => {
    tempShippingInfo.current.tempAddress = { ...noLoginAddress };
    setIsAddingNoLoginAddress(true);
  };

  const cancelCoupon = () => {
    setCoupon('');
    setDiscountValue(0);
    setDiscountPercent(0);
    setIsFreeShipping(false);
    setActiveCoupon(false);
    setIsInsertingCoupon(false);
    //console.log('-Coupon Canceled!');
    //console.log('purchasePrice ', purchasePrice);
  };

  const calculatePurchasePrice = () => {
    // Use the most recent values of totalPrice, discountValue, etc.
    const updatedTotalPrice = totalPrice;
    const updatedTotalDeliveryPrice = calculateSummary().totalDeliveryPrice;
    let updatedPurchasePrice = updatedTotalPrice;
  
    if (discountValue !== 0) {
      updatedPurchasePrice -= discountValue;
    }
  
    // Function to round up to two decimal places
    const roundUpToTwoDecimals = (value) => {
        return Math.ceil(value * 100) / 100;
    };
  
    updatedPurchasePrice = isFreeShipping ? updatedPurchasePrice : updatedPurchasePrice + updatedTotalDeliveryPrice;
  
    // Round the updated purchase price to two decimal places
    updatedPurchasePrice = roundUpToTwoDecimals(updatedPurchasePrice);
  
    setPurchasePrice(updatedPurchasePrice);
  };

  useEffect(() => {
    calculatePurchasePrice();
    // validateCoupon();
  }, [selectedShippingOptions, isFreeShipping, totalDeliveryPrice, cartItems, discountValue]);
  

  const handleClientDocChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');
  
    if (value.length > 14) value = value.slice(0, 14);
  
    let formattedValue = value;
  
    // Format based on length - CPF or CNPJ
    if (value.length <= 11) {
      // CPF formatting
      formattedValue = value.replace(/(\d{3})(\d)/, '$1.$2')
                           .replace(/(\d{3})(\d)/, '$1.$2')
                           .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      // CNPJ formatting
      formattedValue = value.replace(/(\d{2})(\d)/, '$1.$2')
                           .replace(/(\d{3})(\d)/, '$1.$2')
                           .replace(/(\d{3})(\d)/, '$1/$2')
                           .replace(/(\d{4})(\d{1,2})$/, '$1-$2');
    }
  
    setClientDoc(formattedValue);
    const isValid = isValidDocument(formattedValue);
    setIsClientDocValid(isValid);
  };
  
  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters
    let formattedValue = value;
  
    if (value.length > 2 && value.length <= 6) {
      formattedValue = `(${value.slice(0, 2)})${value.slice(2)}`;
    } else if (value.length > 6 && value.length <= 10) {
      formattedValue = `(${value.slice(0, 2)})${value.slice(2, 6)}-${value.slice(6)}`;
    } else if (value.length > 10) {
      formattedValue = `(${value.slice(0, 2)})${value.slice(2, 7)}-${value.slice(7, 11)}`;
    }
    setPhone(formattedValue);
  
    // Validate phone number
    const isValid = validatePhone(formattedValue);
    setIsPhoneValid(isValid);
    //console.log('Client Phone Valid:', isValid);
  };
  
  const handleVehicleIdChange = (e) => {
    let value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
  
    if (value.length <= 7) {
      // Format as Brazilian license plate: AAA-0000
      value = value.replace(/(.{3})(.)/, '$1-$2');
    }
  
    // Limit to 17 characters (chassis length)
    if (value.length > 17) {
      value = value.slice(0, 17);
    }
  
    setVehicleId(value);
  };

  // Validation function to validate CPF or CNPJ
  const validateClientDoc = (doc) => {
    // Remove caracteres de formatação
    const cleanedDoc = doc.replace(/\D/g, '');
    
    // Verifica se o comprimento do documento é 11 (CPF) ou 14 (CNPJ)
    return cleanedDoc.length === 11 || cleanedDoc.length === 14;
  };

  // Validation function to validate phone number
  const validatePhone = (phone) => {
    // Remove caracteres de formatação
    const cleanedPhone = phone.replace(/\D/g, '');
    
    // Números de telefone brasileiros devem ter 10 ou 11 dígitos
    return cleanedPhone.length === 10 || cleanedPhone.length === 11;
  };

  // Function to check if PIX information is valid
  const isPixInfoValid = () => {
    const clientDocValid = isValidDocument(clientDoc);
    const phoneValid = validatePhone(phone);

    if (!clientDocValid) {
        const docType = getDocumentType(clientDoc);
        if (docType === null) {
            alert("CPF/CNPJ inválido");
        } else {
            alert(`${docType} inválido`);
        }
        setIsLoadingSummary(false); // Make sure to stop loading if validation fails
        return false;
    }

    if (!phoneValid) {
        alert("Telefone inválido");
        setIsLoadingSummary(false);
        return false;
    }

    return true;
  };
  
  const handlePixInfoSubmit = (e) => {
    if (e && e.preventDefault) {
        e.preventDefault();
    }
    const clientDocValid = isValidDocument(clientDoc);
    const phoneValid = validatePhone(phone);
  
    setIsClientDocValid(clientDocValid);
    setIsPhoneValid(phoneValid);
  
    if (clientDocValid && phoneValid) {
        const newPaymentData = {
            type: 'pix',
            doc: clientDoc,
            phone: phone,
            purchasePrice: purchasePrice,
        };
        setPaymentData(newPaymentData);
    }
    // Remove the alerts from here since isPixInfoValid will handle them
};

  const handleAddNewCard = (value) => {
    scrollToTop();
    setAddingNewCard(value);
  };

  const handleEditCard = () => {
    setVerificationCode('');
  };

  const handleNewCard = () => {
    setCardNumber('');
    setCardHolderName('');
    setValidThru('');
    setVerificationCode('');
    setClientDoc('');
    setPhone('');
    setVehicleId('');
  };

  const handleCardNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 16) {
      const formattedValue = value.replace(/(\d{4})(?=\d)/g, '$1 ');
      setCardNumber(formattedValue);
  
      // Validate card number
      const isValid = validateCardNumber(formattedValue);
      setIsCardNumberValid(isValid);
      //console.log('Card Number Valid:', isValid);
    }
  };


  const handleValidThruChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 4) {
      const formattedValue = value.replace(/(\d{2})(?=\d)/, '$1/');
      setValidThru(formattedValue);
  
      // Validate valid thru date
      const isValid = validateValidThru(formattedValue);
      setIsValidThruValid(isValid);
      //console.log('Valid Thru Valid:', isValid);
    }
  };


  const handleVerificationCodeChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 4) {
      setVerificationCode(value);
  
      // Validate verification code
      const isValid = validateVerificationCode(value);
      setIsVerificationCodeValid(isValid);
      //console.log('Verification Code Valid:', isValid);
    }
  };

  const handleCardHolderNameChange = (e) => {
    const value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
    setCardHolderName(value);
  
    // Validate card holder name
    const isValid = validateCardHolderName(value);
    setIsCardHolderNameValid(isValid);
    //console.log('Card Holder Name Valid:', isValid);
  };

  const handleCepChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters
    setCep(value);
    if (value.length === 0 || value.length === 8){
      localStorage.setItem('userCep', value);
    } else {
      localStorage.setItem('userCep', '');
    }
    let formattedValue = value;
    if (value.length > 2 && value.length <= 5) {
      formattedValue = `${value.slice(0, 2)}.${value.slice(2)}`;
    } else if (value.length > 5) {
      formattedValue = `${value.slice(0, 2)}.${value.slice(2, 5)}-${value.slice(5, 8)}`;
    }
  
    setFormattedCep(formattedValue);
  };

  const searchCep = async (cep) => {
    setSearchingCep(true);
    
    let newaddress = {
        name: '',
        logradouro: '',
        number: '',
        bairro: '',
        cidade: '',
        estado: '',
        cep: ''
    };

    if (cep.length === 8) {
        try {
            const response = await getAddress(cep);
            
            // If we get a successful response
            if (response && response.success && response.data) {
                newaddress = { ...newaddress, ...response.data };
                
                setNoLoginAddress({
                    name: newaddress.name || '',
                    street: newaddress.logradouro || '',
                    streetNumber: savedAddress.streetNumber || '',
                    complement: savedAddress.complement || '',
                    neighborhood: newaddress.bairro || '',
                    city: newaddress.cidade || '',
                    uf: newaddress.estado || '',
                    cep: newaddress.cep || ''
                });
                
                setNewLoggedAddress({
                    name: newaddress.name || '',
                    street: newaddress.logradouro || '',
                    streetNumber: savedAddress.streetNumber || '',
                    complement: savedAddress.complement || '',
                    neighborhood: newaddress.bairro || '',
                    city: newaddress.cidade || '',
                    uf: newaddress.estado || '',
                    cep: newaddress.cep || ''
                });
                
                setErrors(prev => ({ ...prev, cep: '' })); // Clear CEP error
            } else {
                // Handle unsuccessful response
                setErrors(prev => ({ ...prev, cep: response?.error || 'CEP não encontrado' }));
                // Initialize empty form
                setNoLoginAddress({
                    name: '',
                    street: '',
                    streetNumber: '',
                    complement: '',
                    neighborhood: '',
                    city: '',
                    uf: '',
                    cep: cep
                });
                
                setNewLoggedAddress({
                    name: '',
                    street: '',
                    streetNumber: '',
                    complement: '',
                    neighborhood: '',
                    city: '',
                    uf: '',
                    cep: cep
                });
            }
        } catch (error) {
            // Handle any errors in the API call
            console.error('Error fetching address:', error);
            setErrors(prev => ({ ...prev, cep: 'Erro ao buscar CEP. Por favor, preencha manualmente.' }));
            // Initialize empty form
            setNoLoginAddress({
                name: '',
                street: '',
                streetNumber: '',
                complement: '',
                neighborhood: '',
                city: '',
                uf: '',
                cep: cep
            });
            
            setNewLoggedAddress({
                name: '',
                street: '',
                streetNumber: '',
                complement: '',
                neighborhood: '',
                city: '',
                uf: '',
                cep: cep
            });
        }
        
        // Always show the form, regardless of success or failure
        setShowCustomAddress(true);
    } else {
        setShowCustomAddress(false);
    }
    
    setSearchingCep(false);
  };

  const searchCepPay = async (cep) => {
    setSearchingCep(true);
    
    let newaddress = {
        name: '',
        logradouro: '',
        number: '',
        bairro: '',
        cidade: '',
        estado: '',
        cep: ''
    };

    if (cep.length === 8) {
        try {
            const response = await getAddress(cep);
            
            // If we get a successful response
            if (response && response.success && response.data) {
                newaddress = { ...newaddress, ...response.data };
                
                setNewPayAddress({
                    name: newaddress.name || '',
                    street: newaddress.logradouro || '',
                    streetNumber: newaddress.number || '',
                    complement: '',
                    neighborhood: newaddress.bairro || '',
                    city: newaddress.cidade || '',
                    uf: newaddress.estado || '',
                    cep: newaddress.cep || ''
                });
                
                setErrors(prev => ({ ...prev, cep: '' })); // Clear CEP error
            } else {
                // Handle unsuccessful response
                setErrors(prev => ({ ...prev, cep: response?.error || 'CEP não encontrado' }));
                // Initialize empty form
                setNewPayAddress({
                    name: '',
                    street: '',
                    streetNumber: '',
                    complement: '',
                    neighborhood: '',
                    city: '',
                    uf: '',
                    cep: cep
                });
            }
        } catch (error) {
            // Handle any errors in the API call
            console.error('Error fetching address:', error);
            setErrors(prev => ({ ...prev, cep: 'Erro ao buscar CEP. Por favor, preencha manualmente.' }));
            // Initialize empty form
            setNewPayAddress({
                name: '',
                street: '',
                streetNumber: '',
                complement: '',
                neighborhood: '',
                city: '',
                uf: '',
                cep: cep
            });
        }
        
        // Always show the form, regardless of success or failure
        setShowCustomAddress(true);
    } else {
        setShowCustomAddress(false);
    }
    
    setSearchingCep(false);
  };

  const validateCardNumber = (number) => {
    // Remove spaces or hyphens if present
    const cleanedNumber = number.replace(/[\s-]/g, '');
  
    // Check if the cleaned card number is a string of digits and has the right length
    const regex = /^\d{13,19}$/;
    return regex.test(cleanedNumber);
  };
  
  const validateCardHolderName = (name) => {
    // Check if the name is a string with at least one character
    const regex = /^[A-Za-z\s]+$/; // Allow letters and spaces
    return regex.test(name) && name.trim().length > 0;
  };
  
  const validateValidThru = (thru) => {
    // Check if the date is in MM/YY format
    const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
    if (!regex.test(thru)) {
      return false;
    }
  
    // Extract month and year
    const [month, year] = thru.split('/').map(num => parseInt(num, 10));
    const currentYear = new Date().getFullYear() % 100; // Last two digits of the current year
    const currentMonth = new Date().getMonth() + 1; // Current month (1-indexed)
  
    // Check if the card is not expired
    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      return false;
    }
  
    return true;
  };
  
  const validateVerificationCode = (code) => {
    // Check if the verification code is a 3 or 4 digit number
    const regex = /^\d{3,4}$/;
    return regex.test(code);
  };

  const saveNewCard = (e) => {
    e.preventDefault();
    // Perform validation checks
    const cardNumberValid = validateCardNumber(cardNumber);
    const cardHolderNameValid = validateCardHolderName(cardHolderName);
    const validThruValid = validateValidThru(validThru);
    const verificationCodeValid = validateVerificationCode(verificationCode);
    const clientDocValid = isValidDocument(clientDoc); // Changed this line
    const phoneValid = validatePhone(phone);
  
    // Update validation state
    setIsCardNumberValid(cardNumberValid);
    setIsCardHolderNameValid(cardHolderNameValid);
    setIsValidThruValid(validThruValid);
    setIsVerificationCodeValid(verificationCodeValid);
    setIsClientDocValid(clientDocValid);
    setIsPhoneValid(phoneValid);
  
    // Check overall form validity
    if (!clientDocValid) {
      const docType = getDocumentType(clientDoc);
      if (docType === null) {
        alert("CPF/CNPJ inválido");
      } else {
        alert(`${docType} inválido`);
      }
      return;
    }

    if (
      cardNumberValid &&
      cardHolderNameValid &&
      validThruValid &&
      verificationCodeValid &&
      clientDocValid &&
      phoneValid
    ) {
      // Create a new card object and save it
      const newCard = {
        cardNumber,
        cardHolderName,
        validThru,
        verificationCode,
        clientDoc,
        phone,
        vehicleId,
        isDefault: false,
        address: {},
        saveToLogin: false,
      };
  
      setUserCreditCards((prevCards) => [newCard, ...prevCards]);
      setCardNumber('');
      setCardHolderName('');
      setValidThru('');
      setVerificationCode('');
      setClientDoc('');
      setPhone('');
      setVehicleId('');

      const normalizeAddress = (address) => {
        //console.log('newPayAddress before normalizing: ', newPayAddress); // Logs global object
        //console.log('Address received in normalizeAddress: ', address);   // Logs parameter passed in
    
        return {
            name: address.name || "Default", 
            number: address.streetNumber, // Map streetNumber to number
            street: address.street,       // Add street field here
            district: address.neighborhood, 
            complement: address.complement, 
            city: address.city,
            state: address.uf, 
            zipCode: address.cep.replace(/-/g, ''), 
            lat: address.lat || 0, 
            long: address.long || 0, 
        };
    };
    
    // Ensure you are calling the function with the expected newPayAddress
    if (!newPayAddressSelected && isSamePayAddressSelected) {
        newCard.address = selectedAddress;
    }
    if (newPayAddressSelected && !isSamePayAddressSelected) {
        newCard.address = normalizeAddress(newPayAddress);
    }
    if (!newPayAddressSelected && !isSamePayAddressSelected) {
        alert('Informe o endereço de cobrança do cartão.');
        scrollToRef('payAddressScroll', 200);
        setPayAddressHighlight(true);
        setTimeout(() => {
            setPayAddressHighlight(false);
        }, 1000);
        return;
    }

    
    //console.log('newCard.address after normalizing: ', newCard.address);
    

      // const confirmCardSave = window.confirm('Deseja salvar este cartão para compras futuras?');
  
      // if (confirmCardSave) {
      //   newCard.saveToLogin = true;
      // }
      newCard.saveToLogin = true;

      handleAddNewCard(false);
      scrollToTop();
    } else {
      scrollToTop();
      //console.log('Form is invalid');
    }
  };

  // Populate form with card data if userCreditCards is not empty
  useEffect(() => {
    if (userCreditCards.length > 0) {
      const card = userCreditCards[0]; // Always taking the first card for edit
      setCardNumber(card.cardNumber || '');
      setCardHolderName(card.cardHolderName || '');
      setValidThru(card.validThru || '');
      setVerificationCode(card.verificationCode || '');
      setClientDoc(card.clientDoc || '');
      setPhone(card.phone || '');
      setVehicleId(card.vehicleId || '');
    }
  }, [userCreditCards]);

  const selectCard = (index) => {
    setSelectedCardIndex(index);
    // Log the selected card information
    //console.log('Selected card:', selectedCard);
  };
  const getShippingQuotes = httpsCallable(functions, 'getShippingQuotes');
  // const getShippingQuotes = httpsCallable(functions, 'getShippingQuotes2');

  // PICPAY FUNCTIONS BEGIN
  const createOrderPicPay = httpsCallable(functions, 'createOrderPicPay');
  const addCardToSafe = httpsCallable(functions, 'picpay_add_card_to_safe');
  const picpay3DSSetup= httpsCallable(functions, 'picpay_3ds_setup');
  const picpay3DSEnrollment= httpsCallable(functions, 'picpay_3ds_enrollment');

  const picpay3DSAuthorization= httpsCallable(functions, 'picpay_3ds_authorization');
  const picpay_3ds_authorizationwithDB= httpsCallable(functions, 'picpay_3ds_authorizationwithDB');
  
  const saveOrderToDb = httpsCallable(functions, 'saveOrderToDb');
  // PICPAY FUNCTIONS END

  function transformShippingData(dataArray) {
    const result = [];
    const serviceNames = {};
  
    // Process each item in the dataArray
    dataArray.forEach(itemObj => {
      const data = itemObj.data; // Access the nested data array
  
      data.forEach(item => {
        const key = Object.keys(item)[0]; // Get the service name key
        if (key !== "errormessage" && key !== "errorMsgServices") {
          if (!serviceNames[key]) {
            serviceNames[key] = { deliveryPrice: 0, deliveryTime: 0, count: 0 };
          }
          const service = serviceNames[key];
          const price = item[key].total || item[key].valor;
          const time = item[key].prazo;
  
          service.deliveryPrice += price;
          service.deliveryTime = Math.max(service.deliveryTime, time);
          service.count++;
        }
      });
    });
  
    // Filter out services that are not present in all valid entries
    const totalValidEntries = dataArray.length;
    for (const [name, { deliveryPrice, deliveryTime, count }] of Object.entries(serviceNames)) {
      if (count === totalValidEntries) {
        result.push({
          name: name.charAt(0).toUpperCase() + name.slice(1),
          deliveryPrice: deliveryPrice.toFixed(2).replace(".", ","),
          deliveryTime: (deliveryTime + configData.customShipping.deliveryTime).toString()
        });
      }
    }
    // setLoadingShippingOptions(false);
    // //console.log('Loading shipping 03');
    return result;
  }

  // Add this constant with CEP ranges for BH metro area cities (add near the top of the file)
  const bhMetroAreaCEPs = {
    'BELO HORIZONTE': [
        ['30000000', '31999999']
    ],
    'CONTAGEM': [
        ['32000000', '32399999']
    ],
    'SANTA LUZIA': [
        ['33000000', '33199999']
    ],
    'NOVA LIMA': [
        ['34000000', '34099999']
    ],
    'VESPASIANO': [
        ['33200000', '33299999']
    ],
    'IBIRITÉ': [
        ['32400000', '32449999']
    ],
    'BETIM': [
        ['32600000', '32699999']
    ],
    'RIBEIRÃO DAS NEVES': [
        ['33800000', '33899999']
    ],
    'SABARÁ': [
        ['34500000', '34599999']
    ],
    'MATEUS LEME': [
        ['35670000', '35679999']
    ],
    'SARZEDO': [
        ['32450000', '32459999']
    ],
    'JUATUBA': [
        ['35675000', '35679999']
    ],
    'BRUMADINHO': [
        ['35460000', '35469999']
    ]
  };

  // Add this function to check if CEP is in BH metro area (add after the CEP ranges)
  const isInBHMetroArea = (cep) => {
    // Remove any non-numeric characters from CEP
    const cleanCep = cep.replace(/\D/g, '');
    
    // Check each city's CEP ranges
    for (const city in bhMetroAreaCEPs) {
        for (const [start, end] of bhMetroAreaCEPs[city]) {
            if (cleanCep >= start && cleanCep <= end) {
                return true;
            }
        }
    }
    return false;
  };

  // Add this helper function to check if CEP is specifically in BH or Contagem
  const isInBHOrContagem = (cep) => {
    const cleanCep = cep.replace(/\D/g, '');
    
    // Check only BH and Contagem ranges
    for (const city of ['BELO HORIZONTE', 'CONTAGEM']) {
        for (const [start, end] of bhMetroAreaCEPs[city]) {
            if (cleanCep >= start && cleanCep <= end) {
                return true;
            }
        }
    }
    return false;
  };

  // Update the fetchShippingQuotes function
  const fetchShippingQuotes = async () => {
    setLoadingShippingOptions(true);

    let shippingOptions = '';
    try {
        // Get the delivery CEP first
        let deliveryCep;
        if (gpuser) {
            if (selectedAddress != null) {
                deliveryCep = selectedAddress.cep ?? selectedAddress.zipCode;
            } else {
                deliveryCep = gpuser.cep;
            }
        } else {
            deliveryCep = noLoginAddress.cep.replace(/[.-]/g, '') || '';
        }

        // Check if CEP is in BH metro area
        if (isInBHMetroArea(deliveryCep)) {
            // Process each seller individually
            for (const element of cartItemsBySellers) {
                // Check if current seller is one of the RECREIO sellers
                if (configData.defaultStore === 'Recreio' && 
                    (element.sellerName === 'RECREIO BH VW' || element.sellerName === 'RECREIO VW MG - MILA')) {
                    
                    // Check if CEP is in BH or Contagem for free delivery
                    const deliveryPrice = isInBHOrContagem(deliveryCep) ? '0.00' : '9.90';
                    
                    // Apply local delivery with appropriate price
                    const localDeliveryOption = [{
                        name: 'Entrega Local',
                        deliveryPrice: deliveryPrice,
                        deliveryTime: configData.defaultStore === 'GoParts' ? '1 a 2' : '1'
                    }];
                    
                    element.shippingop = localDeliveryOption;
                } else {
                    // Calculate regular shipping for non-RECREIO sellers
                    const regularShippingOptions = await calculateRegularShipping(element, deliveryCep);
                    element.shippingop = regularShippingOptions;
                }
            }
        } else {
            // If not in BH metro area, calculate regular shipping for all sellers
            for (const element of cartItemsBySellers) {
                const regularShippingOptions = await calculateRegularShipping(element, deliveryCep);
                element.shippingop = regularShippingOptions;
            }
        }

        // Get the shipping options from the last processed seller
        if (cartItemsBySellers.length > 0) {
            shippingOptions = cartItemsBySellers[cartItemsBySellers.length - 1].shippingop;
        }

        // Update temp shipping info
        tempShippingOps.current.cart = {...tempShippingInfo.current.tempCart};
        tempShippingOps.current.address = {...tempShippingInfo.current.tempAddress};
        tempShippingOps.current.shippingOps = {...shippingOptions};

    } catch (error) {
        console.error('Error fetching shipping quote:', error);
        throw error;
    } finally {
        setLoadingShippingOptions(false);
    }
};

// Helper function to calculate regular shipping for a seller
const calculateRegularShipping = async (element, deliveryCep) => {
    const dealersQuery = query(
        collection(getFirestore(), 'dealers'),
        where('nome', '==', element.sellerName)
    );

    const snapProducts = await getDocs(dealersQuery);
    
    if (snapProducts.empty) {
        throw new Error('No dealer found with the specified name');
    }

    const doc = snapProducts.docs[0];
    let freightoptions = [];

    let cepAux = doc.get('cep');
    let cepOrigem = cepAux.replace(/-/g, '');

    let cnpjDestinatario;
    if (gpuser) {
        cnpjDestinatario = gpuser.cpf ? gpuser.cpf.replace(/-/g, '') : gpuser.cnpj.replace(/-/g, '');
    } else {
        cnpjDestinatario = "39456281000127";
    }

    for (const item of element.items) {
        const requestData = {
            cepOrigem: cepOrigem,
            cepDestino: deliveryCep,
            frap: 0,
            peso: String(item.measures.weight / 1000),
            pesoG: String(item.measures.weightCubic / 1000),
            comprimento: String(item.measures.depth),
            largura: String(item.measures.width),
            altura: String(item.measures.height),
            modalidade: 3,
            tpentrega: "D",
            tpseguro: "N",
            vldeclarado: String(item.price),
            volumes: 1,
            vlrMercadoria: String(item.price),
            cnpjRemetente: "39456281000127",
            cnpjDestinatario: cnpjDestinatario.replace(/\./g, ''),
            seller: element.sellerName
        };

        try {
            if (deliveryCep) {
                const result = await getShippingQuotes(requestData);

                result.data = result.data.filter(option => {
                    return !(shippingBlackList.includes(element.sellerName) && option.jadlog);
                });
                
                result.data.forEach(option => {
                    if (option.jadlog) {
                        option.jadlog.total *= item.itemQty * 1.2;
                    }
                    if (option.correios) {
                        option.correios.valor = parseFloat(option.correios.valor.replace(',', '.')) * item.itemQty * 1.4;
                    }
                    if (option.jtex) {
                        option.jtex.total *= item.itemQty * 1.2;
                    }
                });

                freightoptions.push(result);
            }
        } catch (err) {
            console.error('Error calling getShippingQuotes:', err);
        }
    }
    return transformShippingData(freightoptions);
};

  const purchaseData = {
    products: cartItems,
    clientAddress: selectedAddress,
    shipping: selectedShippingOptions,
    discount: discountValue || 0,
    coupon: foundCoupon ? foundCoupon.coupon : '',
    // discount: 0,
    payment: paymentData,
    discountPrice: discountedPrice || 0,
  };

  // useEffect(() => {
  //   console.log('Updated paymentData:', paymentData);
  //   console.log('Updated purchaseData:', purchaseData);
  //   console.log('userCreditCards:', userCreditCards);
  // }, [paymentData, purchaseData, userCreditCards]);

  const finalize = (result) => {
    if (isFinalized) return;
    // setWaitChallenge(false);
      gtmPurchase();
      setIsFinalized(true);
      if ((paymentData.type === 'credit' && result !== '') || (paymentData.type === 'pix' && (result.data !== null))) {
        dispatch({ type: RESET_FROM_CART });
        navigate(`/confirmacao/pgto-realizado`, { 
          state: { 
            orderdata: result,
            purchaseData: purchaseData,
          }
      });
    } else {
      navigate(`/confirmacao/erro`);
    }
  };

  const openVehicleSelect = () => {
    if ((configData.defaultStore === 'UmuaramaHD' || configData.defaultStore === 'UmuaramaMotos')) {
      navigate('/produtos/Peças');
    } else if (selectedVersion) {
      navigate('/categorias');
    } else {
      dispatch(setIsSelectingVehicle(!isSelectingVehicle));
    }
  };

  useEffect(() => {
    if (actualPage === 'resumo') {
      const script = document.createElement('script');

      script.innerHTML = `
        (function (a, b, c, d, e, f, g) {
          a['CsdpObject'] = e; a[e] = a[e] || function () {
            (a[e].q = a[e].q || []).push(arguments)
          }, a[e].l = 1 * new Date(); f = b.createElement(c),
          g = b.getElementsByTagName(c)[0]; f.async = 1; f.src = d; g.parentNode.insertBefore(f, g)
        })(window, document, 'script', '//device.clearsale.com.br/p/fp.js', 'csdp');
        csdp('app', 'cqcz7mrzdzi1fb8bumr0');
        csdp('outputsessionid', 'AdditionalInfo');
      `;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [actualPage]);

  useEffect(() => {
    //console.log('auth3DS_accessToken: ', auth3DS_accessToken);
    //console.log('auth3DS_url: ', auth3DS_url);
    if (auth3DS_url !== '' && auth3DS_accessToken !== '') {
      // Automatically submit the form when the URL and token are present
      document.getElementById('ddc-form').submit();
    }
  }, [auth3DS_accessToken, auth3DS_url]);

  useEffect(() => {
    //console.log('auth3DS_challenge_url: ', auth3DS_challenge_url);
    //console.log('auth3DS_challenge_accessToken: ', auth3DS_challenge_accessToken);
    if (auth3DS_challenge_url !== '' && auth3DS_challenge_accessToken !== '') {
      // Automatically submit the form when the URL and token are present
      document.getElementById('step-up-form').submit();
      // startTimer();
    }
  }, [heightChallenge, widthChallenge,auth3DS_challenge_accessToken,auth3DS_challenge_url]);

  // function startTimer() {
  //   // Define the timer durations
  //   let interval;
  //   const intervalTime = 3000; // 3 seconds
  //   // const totalDuration = 60000; // 1 minute (for testing)
  //   const totalDuration = 210000; // 3 minutes 30 seconds (210000 ms)
  //   let elapsedTime = 0; // Initialize elapsed time
  
  //   // Function to format time in mm:ss
  //   function formatTime(ms) {
  //     const totalSeconds = Math.floor(ms / 1000);
  //     const minutes = Math.floor(totalSeconds / 60);
  //     const seconds = totalSeconds % 60;
  //     return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  //   }
  
  //   // Function to get the current timestamp in a readable format
  //   function getCurrentTimestamp() {
  //     const now = new Date();
  //     return `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
  //   }
  
  //   // Function to stop the timer early if waitChallenge becomes false
  //   function stopTimer() {
  //     clearInterval(interval);
  //     //console.log(`Timer ended at [${getCurrentTimestamp()}], waitChallenge = false`);
  //     finalize(confirmResult);
  //   }
  
  //   // Log when the timer starts
  //   //console.log(`Challenge Timer started at [${getCurrentTimestamp()}]`);
  
  //   // Start the interval that logs every 3 seconds with a timer stamp
  //   interval = setInterval(() => {
  //     if (waitChallenge) {
  //       elapsedTime += intervalTime; // Update elapsed time
  //       //console.log(`Interval ${formatTime(elapsedTime)}/${formatTime(totalDuration)}`);

  //     } else {
  //       stopTimer(); // Stop timer if waitChallenge becomes false
  //     }
  //   }, intervalTime);
  
  //   // Set a 3m30s timeout to stop the timer
  //   setTimeout(() => {
  //     if (waitChallenge) {
  //       clearInterval(interval);
  //       //console.log(`Challenge Timer ended at [${getCurrentTimestamp()}]`);
  //       finalize(confirmResult);
  //     }
  //   }, totalDuration);
  // }

  const gtmViewCart = () => {
    if (window.dataLayer) {
      const products = cartItems.map(item => ({
        id: item.id,
        name: item.title,
        quantity: item.quantity,
        price: item.price,
      }));

      window.dataLayer.push({
        event: 'view_cart',
        ecommerce: {
          currency: 'BRL',
          value: totalPrice,
          items: products,
        },
      });
    } else {
      console.warn("dataLayer is not defined");
    }
  };

  useEffect(() => {
    if (location.pathname === '/checkout') {
      gtmViewCart();
    }
  }, [cartItems, location]);


  const gtmBeginCheckout = () => {
    if (window.dataLayer) {
      const products = cartItems.map(item => ({
        id: item.id,
        name: item.title,
        quantity: item.quantity,
        price: item.price,
      }));

      window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
          currency: 'BRL',
          value: totalPrice,
          items: products,
        },
      });
    } else {
      console.warn("dataLayer is not defined");
    }
  };
  
  const gtmAddShippingInfo = () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'add_shipping_info',
        ecommerce: {
          currency: 'BRL',
          shipping: Object.entries(selectedShippingOptions).map(([seller, shippingTier]) => ({
            seller: seller,         // Name or ID of the seller
            shipping_tier: shippingTier, // Shipping method for this seller
          })),
        }
      });
    } else {
      console.warn("dataLayer is not defined");
    }
  };
  
  const gtmAddPaymentInfo = () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'add_payment_info',
        ecommerce: {
          currency: 'BRL',
          value: totalPrice,
          payment_method: paymentOption,
          payment_parcel_quantity: selectedParcels
        }
      });
    } else {
      console.warn("dataLayer is not defined");
    }
  };

  const gtmPurchase = () => {
    if (window.dataLayer) {
      const products = cartItems.map(item => ({
        id: item.id,
        name: item.title,
        quantity: item.itemQty,
        price: item.price,
      }));

      window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
              affiliation: configData.defaultStore,
              currency: 'BRL',
              value: purchasePrice,
              items: products,
              payment_method: paymentOption,
              payment_parcel_quantity: selectedParcels,
              coupon: activeCoupon,
          },
      });
    } else {
        console.warn("dataLayer is not defined");
    }
};

const checkUserInfo = () => {
  if (gpuser.telefone === '' || (gpuser.cpf === '' && gpuser.cnpj === '')) {
    navigate('/minha-conta#meus-dados');
  }
}

useEffect(() => {
  if (gpuser) {
    checkUserInfo();
  }
}, [gpuser])

useEffect(() => {
  const hasShippingOptions = cartItemsBySellers.some(sellerGroup => sellerGroup.shippingop && sellerGroup.shippingop.length > 0);
  
  if (hasShippingOptions) {
      setLoadingShippingOptions(true);
      fetchShippingQuotes();
  }
}, [cartItemsBySellers]);

// useEffect(() => {
//   console.log('isAddingNoLoginAddress: ', isAddingNoLoginAddress);
//   console.log('isAddingAddressLogged: ', isAddingAddressLogged);
//   console.log('selectedAddress: ', selectedAddress);
// }, [isAddingNoLoginAddress, isAddingAddressLogged, selectedAddress]);

useEffect(() => {
  if (gpuser) {
    setIsAddingNoLoginAddress(false);
  }
}, [gpuser]);

  // const gtmApplyCoupon = () => {
  //   if (window.dataLayer) {
  //     window.dataLayer.push({
  //       event: 'apply_coupon',
  //       ecommerce: {
  //         coupon: activeCoupon,
  //         value: totalPrice,
  //       }
  //     });
  //   } else {
  //     console.warn("dataLayer is not defined");
  //   }
  // };

  // useEffect(() => {
  //   gtmApplyCoupon();
  //   //console.log('foundCoupon: ', foundCoupon);
  // }, [foundCoupon]);

  // useEffect(() => {
  //   console.log('selectedAddress: ', selectedAddress);
  //   console.log('gpuser: ', gpuser);
  //   console.log('localStorage.tempAddress: ', localStorage.tempAddress);
  //   console.log('noLoginAddress: ', noLoginAddress);
  // }, [selectedAddress, gpuser, localStorage, noLoginAddress]);

  // useEffect(() => {
  //   console.log('phoneVerify: ', phone)
  // }, [phone]);

  // useEffect(() => {
  //   console.log('tempShippingInfo: ', tempShippingInfo.current);
  //   console.log('tempShippingOps: ', tempShippingOps.current);
  // }, [tempShippingInfo, tempShippingOps.current]);

  // useEffect(() => {
  //   console.log('selectedShippingOptions: ', selectedShippingOptions);
  //   console.log('cartItemsBySellers: ', cartItemsBySellers);
  // }, [selectedShippingOptions, cartItemsBySellers]);

  return (
    <div className="main fadeIn">

        {/* <div className='pageContent'>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
            <div className='column' style={{padding: 'var(--mediumPadding)', gap: '10px', maxWidth: '640px', alignItems: 'center', textAlign: 'center'}}>
              <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="var(--secondColor)"><path d="M764-80q-6 0-11-2t-10-7L501-331q-5-5-7-10t-2-11q0-6 2-11t7-10l85-85q5-5 10-7t11-2q6 0 11 2t10 7l242 242q5 5 7 10t2 11q0 6-2 11t-7 10l-85 85q-5 5-10 7t-11 2Zm0-72 43-43-200-200-43 43 200 200ZM195-80q-6 0-11.5-2T173-89l-84-84q-5-5-7-10.5T80-195q0-6 2-11t7-10l225-225h85l38-38-175-175h-57L80-779l99-99 125 125v57l175 175 130-130-67-67 56-56H485l-18-18 128-128 18 18v113l56-56 169 169q15 15 23.5 34.5T870-600q0 20-6.5 38.5T845-528l-85-85-56 56-52-52-211 211v84L216-89q-5 5-10 7t-11 2Zm0-72 200-200v-43h-43L152-195l43 43Zm0 0-43-43 22 21 21 22Zm569 0 43-43-43 43Z"/></svg>
              <h2>Esta loja está em desenvolvimento</h2>
              <p>Para comprar produtos genuínos Harley-Davidson e outras autopeças das concessionárias Umuarama, acesse a loja oficial:</p>
              <Button
                className='buttonColorThird smallButton'
                iconStart={<ShoppingCart style={{fontSize: '18px'}}/>}
                label='Pecas Umuarama'
                onClick={() => window.open('https://loja.grupoumuarama.com.br/', '_blank')}
              />
            </div>
          </div>
        </div> */}
      
        <div className='pageContent'>

          {cartItems.length > 0
            ?
              <div className='rowToColumn checkoutContainer' style={{gap: '20px', alignItems: 'start'}}>

                {actualPage === 'carrinho' &&
                  <div className='card fadeIn' style={{width: '100%', gap: '20px'}}>
                    <div className='column' style={{padding: 'var(--mediumPadding)', gap: '10px'}}>
                      <h2>Carrinho</h2>
                            {cartItems.map(product => (
                                <CheckoutItem
                                    key={product.id}
                                    id={product.id}
                                    hasSale={product.hasSale}
                                    title={product.title}
                                    titleComplement={product.supplier}
                                    originalPrice={product.originalPrice}
                                    price={product.price}
                                    img={product.img}
                                    itemQty={product.itemQty}
                                    stock={product.sellerInfo.stock}
                                    onClick={() => navigate(`/${product.id}`)}
                                />
                            ))}
                    </div>
                  </div>
                }

                {actualPage === 'endereço' &&
                  <div className='column fadeIn' style={{width: '100%', gap: '16px'}}>
                    <div className='card' style={{width: '100%', gap: '20px'}}>
                      <div className='column' style={{padding: 'var(--mediumPadding)', gap: 'var(--mediumRowGap)'}}>
                        <div className='row' style={{alignItems: 'center'}}>
                          <a className='grayedText' onClick={() => handlePage('backward')}><ArrowBackSharp style={{fontSize: '18px', marginRight: '4px'}}/></a>
                          <h2>Endereço de entrega</h2>
                        </div>
                        {gpuser != null && gpuser.address.length > 0
                          ?
                            <>
                              <div className='column' style={{gap: 'var(--mediumRowGap)'}}>
                                {gpuser.address.map((address, index) => (
                                  <AddressTile
                                    key={index} // Ensure each element has a unique key prop
                                    name={address.name}
                                    address={`${address.street}, ${address.number} - ${address.district}, ${address.city} - ${address.state}, ${address.zipCode}`}
                                    isSelected={index === selectedAddressIndex}
                                    onClick={() => {handleAddressClick(index)}}
                                  />
                                ))}
                              </div>
                              {/* <div className='row' style={{justifyContent: 'center'}}>
                                <a className='colorTextLink' onClick={() => setIsAddingAddressLogged(true)}>Outro endereço de entrega</a>
                              </div> */}
                              <Button
                                className='buttonColorSecond smallButton'
                                iconStart={<Add style={{fontSize: '16px'}}/>}
                                label='Endereço de entrega'
                                onClick={() => setIsAddingAddressLogged(true)}
                              />
                              {/* <div className='row' style={{justifyContent: 'center'}}>
                                <a className='colorTextLink' onClick={() => navigate('/minha-conta#meus-dados')}>Editar meus endereços</a>
                              </div> */}
                            </>
                          :
                            <div className='column' style={{gap: 'var(--mediumRowGap)'}}>
                              {isAddingNoLoginAddress
                                ?
                                  <>
                                    {!showCustomAddress &&
                                      (
                                        !searchingCep ?
                                        <div className='row fadeIn' style={{ gap: '10px' }}>
                                            <input
                                              id='CEP'
                                              type='text'
                                              placeholder='00.000-000'
                                              value={formattedCep}
                                              onChange={handleCepChange}
                                              onKeyDown={(e) => e.key === 'Enter' && searchCep(cep)}
                                            />
                                            <Button
                                              className='buttonColorMain smallButton'
                                              label='Buscar CEP'
                                              onClick={() => searchCep(cep)}
                                            />
                                            {errors.cep && <span className='error'>{errors.cep}</span>}
                                          </div>
                                        :
                                          <div className='column center'>
                                            <CircularProgress
                                              style={{ padding: '20px' }}
                                              sx={{
                                                color: 'var(--secondColor)',
                                              }}
                                            />
                                            <span>Buscando endereço...</span>
                                          </div>
                                      )
                                    }
                                    {showCustomAddress &&
                                      <div className='column fadeIn' style={{gap: '10px'}}>
                                        <div className='row clickArea' style={{padding: '6px', gap: '10px'}}>
                                          <span>CEP: {formattedCep}</span><a onClick={() => setShowCustomAddress(false)}>alterar</a>
                                        </div>
                                        <form className='column' style={{gap: '10px'}} onSubmit={handleNoLoginAddressSubmit}>
                                          <input
                                            id='name'
                                            type='text'
                                            placeholder='Nome do endereço'
                                            value={noLoginAddress.name}
                                            onChange={handleNoLoginAddressInputChange}
                                            // required
                                          />
                                          {errors.street && <span className='error'>{errors.street}</span>}
                                          <input
                                            id='street'
                                            type='text'
                                            placeholder='Rua ou Avenida'
                                            value={noLoginAddress.street}
                                            onChange={handleNoLoginAddressInputChange}
                                            required
                                          />
                                          {errors.street && <span className='error'>{errors.street}</span>}
                                          
                                          <div className='row' style={{gap: '10px'}}>
                                            <input
                                              style={{flex: '1'}}
                                              id='streetNumber'
                                              type='text'
                                              placeholder='Número'
                                              value={noLoginAddress.streetNumber}
                                              onChange={handleNoLoginAddressInputChange}
                                              required
                                            />
                                            {errors.streetNumber && <span className='error'>{errors.streetNumber}</span>}
                                            
                                            <input
                                              style={{flex: '2'}}
                                              id='complement'
                                              type='text'
                                              placeholder='Complemento (opcional)'
                                              value={noLoginAddress.complement}
                                              onChange={handleNoLoginAddressInputChange}
                                            />
                                          </div>
                                          
                                          <input
                                            id='neighborhood'
                                            type='text'
                                            placeholder='Bairro'
                                            value={noLoginAddress.neighborhood}
                                            onChange={handleNoLoginAddressInputChange}
                                            required
                                          />
                                          {errors.neighborhood && <span className='error'>{errors.neighborhood}</span>}
                                          
                                          <div className='row' style={{gap: '10px'}}>
                                            <input
                                              style={{flex: '4'}}
                                              id='city'
                                              type='text'
                                              placeholder='Cidade'
                                              value={noLoginAddress.city}
                                              onChange={handleNoLoginAddressInputChange}
                                              required
                                            />
                                            {errors.city && <span className='error'>{errors.city}</span>}
                                            <input
                                              style={{display: 'flex', flex: '1'}}
                                              id='uf'
                                              type='text'
                                              placeholder='UF'
                                              value={noLoginAddress.uf}
                                              onChange={(e) => {
                                                const value = e.target.value.slice(0, 2).toUpperCase(); // Restrict to 2 characters and convert to uppercase
                                                setNoLoginAddress((prevState) => ({
                                                  ...prevState,
                                                  uf: value,
                                                }));
                                              }}
                                              maxLength={2}
                                              required
                                            />
                                            {errors.uf && <span className='error'>{errors.uf}</span>}
                                          </div>

                                          <Button
                                            className='buttonColorMain smallButton'
                                            label='Salvar'
                                            type='submit'
                                          />
                                        </form>
                                        {/* <div className='row' style={{justifyContent: 'center'}}>
                                          <a onClick={() => setIsAddingPayAddress(false)}>cancelar</a>
                                        </div> */}
                                      </div>
                                    }
                                  </>
                                :
                                  <>
                                    <AddressTile
                                      name={noLoginAddress.name}
                                      address={`${noLoginAddress.street}, ${noLoginAddress.streetNumber} - ${noLoginAddress.neighborhood}, ${noLoginAddress.city} - ${noLoginAddress.uf}, ${noLoginAddress.cep}`}
                                      isSelected={true}
                                      onClick={() => {handleNoLoginAddressClick()}}
                                    />
                                    <div className='row' style={{justifyContent: 'center'}}>
                                      <a onClick={() => setIsAddingNoLoginAddress(true)}>alterar</a>
                                    </div>
                                  </>
                              }
                            </div>
                        }
                      </div>
                    </div>

                    {gpuser === null &&
                      <div className='column center' style={{gap: '10px'}}>
                        <span>Já sou cliente. <a onClick={() => navigate('/login?destination=/checkout')}>Fazer login</a></span>
                        <span>Ainda não sou cliente. <a onClick={() => navigate('/signup?destination=/checkout')}>Criar conta</a></span>
                      </div>
                    }
                    {/* {gpuser === null &&
                      <div className='column center' style={{gap: '10px'}}>
                        <span>Já sou cliente. <a onClick={() => navigate('/login?destination=/checkout')}>Fazer login</a></span>
                        <span>Ainda não sou cliente. <a onClick={() => navigate('/signup?destination=/checkout')}>Criar conta</a></span>
                      </div>
                    } */}

                  </div>
                }

                {actualPage === 'endereço' && isAddingAddressLogged &&
                  <div className='defaultDialog fadeIn'>
                    <ClickOutside onOutsideClick={() => setIsAddingAddressLogged(false)}>
                      <div className='column cardFormContainer defaultDialogContainer' style={{gap: '10px'}}>
                        <h2>Endereço de entrega</h2>
                        {!showCustomAddress &&
                          (
                            !searchingCep ?
                            <div className='row fadeIn' style={{ gap: '10px' }}>
                              <input
                                id='CEP'
                                type='text'
                                placeholder='00.000-000'
                                value={formattedCep}
                                onChange={handleCepChange}
                                onKeyDown={(e) => e.key === 'Enter' && searchCep(cep)}
                              />
                              <Button
                                className='buttonColorMain smallButton'
                                label='Buscar CEP'
                                onClick={() => searchCep(cep)}
                              />
                              {errors.cep && <span className='error'>{errors.cep}</span>}
                            </div>
                            :
                              <div className='column center'>
                                <CircularProgress
                                  style={{ padding: '20px' }}
                                  sx={{
                                  color: 'var(--secondColor)',
                                  }}
                                />
                                <span>Buscando endereço...</span>
                              </div>
                          )
                        }
                        {showCustomAddress &&
                          <div className='column fadeIn' style={{gap: '10px'}}>
                            <div className='row clickArea' style={{padding: '6px', gap: '10px'}}>
                              <span>{formattedCep}</span><a className='colorTextLink' onClick={() => setShowCustomAddress(false)}>alterar CEP</a>
                            </div>
                            <form className='column' style={{gap: '10px'}} onSubmit={handleNewAddressLoggedSubmit}>
                              <input
                                id='name'
                                type='text'
                                placeholder='"Casa" ou "Trabalho"'
                                value={newLoggedAddress.name}
                                onChange={handleNewAddressLogedInputChange}
                                required
                              />
                              {errors.street && <span className='error'>{errors.street}</span>}
                              
                              <input
                                id='street'
                                type='text'
                                placeholder='Rua ou Avenida'
                                value={newLoggedAddress.street}
                                onChange={handleNewAddressLogedInputChange}
                                required
                              />
                              {errors.street && <span className='error'>{errors.street}</span>}
                              
                              <div className='row' style={{gap: '10px'}}>
                                <input
                                  style={{flex: '1'}}
                                  id='streetNumber'
                                  type='text'
                                  placeholder='Número'
                                  value={newLoggedAddress.streetNumber}
                                  onChange={handleNewAddressLogedInputChange}
                                  required
                              />
                              {errors.streetNumber && <span className='error'>{errors.streetNumber}</span>}
                              
                              <input
                                  style={{flex: '2'}}
                                  id='complement'
                                  type='text'
                                  placeholder='Complemento (opcional)'
                                  value={newLoggedAddress.complement}
                                  onChange={handleNewAddressLogedInputChange}
                                />
                              </div>
                              
                              <input
                                id='neighborhood'
                                type='text'
                                placeholder='Bairro'
                                value={newLoggedAddress.neighborhood}
                                onChange={handleNewAddressLogedInputChange}
                                required
                              />
                              {errors.neighborhood && <span className='error'>{errors.neighborhood}</span>}
                              
                              <div className='row' style={{gap: '10px'}}>
                                <input
                                  style={{flex: '4'}}
                                  id='city'
                                  type='text'
                                  placeholder='Cidade'
                                  value={newLoggedAddress.city}
                                  onChange={handleNewAddressLogedInputChange}
                                  required
                                />
                                {errors.city && <span className='error'>{errors.city}</span>}
                                <input
                                  style={{display: 'flex', flex: '1'}}
                                  id='uf'
                                  type='text'
                                  placeholder='UF'
                                  value={newLoggedAddress.uf}
                                  onChange={(e) => {
                                    const value = e.target.value.slice(0, 2).toUpperCase(); // Restrict to 2 characters and convert to uppercase
                                    setNewLoggedAddress((prevState) => ({
                                      ...prevState,
                                      uf: value,
                                    }));
                                  }}
                                  maxLength={2}
                                  required
                                />
                                {errors.uf && <span className='error'>{errors.uf}</span>}
                              </div>

                              <Button
                                className='buttonColorMain smallButton'
                                label='Salvar'
                                type='submit'
                              />
                            </form>
                            <div className='row' style={{justifyContent: 'center'}}>
                              <a onClick={() => {setIsAddingAddressLogged(false); setShowCustomAddress(false)}}>cancelar</a>
                            </div>
                          </div>
                        }
                      </div>
                    </ClickOutside>
                  </div>
                }

                {actualPage === 'entrega' &&
                  <div className='column fadeIn' style={{width: '100%', gap: '16px'}}>
                    <div className='card' style={{width: '100%', gap: '20px'}}>
                      <div className='column' style={{padding: 'var(--mediumPadding)', gap: 'var(--mediumRowGap)'}}>
                        <div className='row' style={{alignItems: 'center'}}>
                          <a className='grayedText' onClick={() => handlePage('backward')}><ArrowBackSharp style={{fontSize: '18px', marginRight: '4px'}}/></a>
                          <h2>Opções de envio</h2>
                        </div>
                        {cartItemsBySellers.map((sellerGroup, sellerIndex) => (
                          <div key={sellerIndex} id={`seller-${sellerIndex}`} className='column' style={{gap: 'var(--mediumRowGap)', borderTop: '1px solid var(--lightGray)', padding: '10px 0 10px 0'}}>
                            <h3>
                              {`Origem${cartItemsBySellers.length > 1 ? ` ${sellerIndex+1}/${cartItemsBySellers.length}: ` : ': '}${(configData.defaultStore == 'GoParts' || configData.defaultStore == 'Peças Kia') ? mktNames[sellerGroup.sellerName] : sellerGroup.sellerName}`}
                            </h3>
                            <div className='row' style={{alignItems: 'center', gap: '10px', flexWrap: 'wrap'}}>
                              {sellerGroup.items.map((product, productIndex) => (
                                <div key={productIndex} className='row defaultBorderedItem' style={{gap: '4px', padding: '4px', alignItems: 'center'}}>
                                  <span>{product.itemQty}x</span>
                                  <img src={product.img} style={{height: '32px'}}/>
                                </div>
                              ))}
                            </div>
                            {!loadingShippingOptions
                              ?
                                <>
                                  <div className='column' style={{gap: 'var(--smallRowGap)'}}>
                                    {sellerGroup.shippingop && sellerGroup.shippingop.length === 0 ? (
                                        <span className='grayedText'>
                                            Transportadoras indisponíveis para o CEP '{cep}'. Entre em contato para solicitar uma cotação ou selecione 'Retirar no local'.
                                        </span>
                                    ) : (
                                        sellerGroup.shippingop && sellerGroup.shippingop.map((option, index) => (
                                            <ShippingTile
                                                key={index}
                                                id={String(index)}
                                                name={option.name}
                                                shippingAddress={gpuser ? (selectedAddress?.name || "Novo") : "Novo"}
                                                isSelected={selectedShippingOptions[sellerGroup.sellerName] === option.name}
                                                deliveryPrice={option.deliveryPrice}
                                                deliveryTime={option.deliveryTime}
                                                onClick={() => {
                                                    handleShippingOptionClick(sellerGroup.sellerName, option.name);
                                                    // scrollToNextSeller(sellerIndex);
                                                }}
                                            />
                                        ))
                                    )}
                                    <ShippingTile
                                      key='99'
                                      id='pickup'
                                      name={
                                        <div className="column">
                                          <span>Retirar no local</span>
                                          <span className='grayedText'>*entraremos em contato quando estiver pronto para retirada.</span>
                                        </div>
                                      }
                                      shippingAddress={sellerGroup.sellerName}
                                      isSelected={selectedShippingOptions[sellerGroup.sellerName] === 'pickup'}
                                      deliveryPrice=''
                                      deliveryTime=''
                                      onClick={() => {
                                        handleShippingOptionClick(sellerGroup.sellerName, 'pickup')
                                      }}
                                    />
                                  </div>
                                </>
                              :
                                <div className='column center'>
                                  <CircularProgress
                                    style={{ padding: '20px' }}
                                    sx={{
                                      color: 'var(--secondColor)',
                                    }}
                                  />
                                  <span>Calculando opções de entrega...</span>
                                </div>
                            }
                          </div>
                        ))}
                        <span className='grayedText'>*Prazo de entrega contado após faturamento e despacho do envio. Prazo médio de emissão de NF é de até 2 dias.</span>
                      </div>
                    </div>

                    {/* {gpuser === null &&
                      <div className='column center' style={{gap: '10px'}}>
                        <span>Já sou cliente. <a onClick={() => navigate('/login?destination=/checkout')}>Fazer login</a></span>
                        <span>Ainda não sou cliente. <a onClick={() => navigate('/signup?destination=/checkout')}>Criar conta</a></span>
                      </div>
                    } */}

                  </div>
                }



                {(actualPage === 'pagamento' && !addingNewCard) &&
                  <>
                    <div className='column fadeIn' style={{width: '100%', gap: '16px'}}>
                      <div className='card' style={{width: '100%', gap: '20px'}}>
                        <div className='column' style={{padding: '10px', gap: '10px'}}>
                          <div className='row' style={{alignItems: 'center'}}>
                            <a className='grayedText' onClick={() => handlePage('backward')}><ArrowBackSharp style={{fontSize: '18px', marginRight: '4px'}}/></a>
                            <h2>Forma de pagamento</h2>
                          </div>
                          <div className={`row defaultBorderedItem clickArea ${paymentOption === 'pix' ? 'selectedItem' : 'itemSelect'}`} style={{gap: '10px', justifyContent: 'space-between', alignItems: 'center'}}
                            onClick={() => pixSelect()}
                          >
                            <div className='row' style={{alignItems: 'center', gap: '6px'}}>
                              <QrCode2 style={{fontSize: '32px'}}/>
                              <div className='column'>
                                <span>PIX</span>
                                <h3>Aprovação imediata</h3>
                              </div>
                            </div>
                            <div className='column' style={{alignItems: 'end', gap: '4px', whiteSpace: 'nowrap'}}>
                              <span>{formatPrice((totalPrice - discountValue + (isFreeShipping ? 0 : totalDeliveryPrice)))}</span>
                            </div>
                          </div>
                          <div className={`row defaultBorderedItem clickArea ${paymentOption === 'credit' ? 'selectedItem' : 'itemSelect'}`} style={{gap: '10px', justifyContent: 'space-between', alignItems: 'center'}}
                            onClick={() => {
                              setPaymentOption('credit');
                              // navigateToPage('pagamento2');
                            }}
                          >
                            <div className='row' style={{alignItems: 'center', gap: '6px'}}>
                              <CreditCard style={{fontSize: '32px'}}/>
                              <div className='column'>
                                <span>Cartão de Crédito</span>
                              </div>
                            </div>
                            <div className='column' style={{alignItems: 'end', gap: '4px', whiteSpace: 'nowrap'}}>
                              {getApplicableParcelCount() > 1 &&
                                  <span>em até {getApplicableParcelCount()} vezes</span>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                }

                {(actualPage === 'pagamento2' && !addingNewCard) &&
                  <>
                    <div className='column fadeIn' style={{width: '100%', gap: '16px'}}>
                      <div className='card' style={{width: '100%', gap: '20px'}}>
                        <div className='column' style={{padding: '10px', gap: '10px'}}>
                          <div className='row'>
                            <a className='grayedText' onClick={() => handlePage('backward')}><ArrowBackSharp style={{fontSize: '18px', marginRight: '4px'}}/></a>
                            {paymentOption === 'pix' &&
                              <h2>PIX</h2>
                            }
                            {paymentOption === 'credit' && 
                              <h2>Cartão de Crédito</h2>
                            }
                          </div>
                          {paymentOption === 'pix' &&
                            <form id='pixInfo' className='column fadeIn' style={{gap: '10px'}}>
                              <h3>Dados do comprador:</h3>
                              <input
                                id='clientDocPix'
                                type='text'
                                placeholder='CPF ou CNPJ'
                                value={clientDoc}
                                onChange={handleClientDocChange}
                                onBlur={handleClientDocChange}
                                style={{ borderColor: isClientDocValid === false && 'red' }}
                              />
                              {clientDoc.replace(/\D/g, '').length > 11 && isClientDocValid && (
                                <span className='grayedText' style={{marginLeft: '6px'}}>*Para compras interestaduais com CNPJ, poderá haver cobrança adicional de imposto.</span>
                              )}
                              <input
                                id='phonePix'
                                type='text'
                                placeholder='Telefone'
                                value={phone}
                                onChange={handlePhoneChange}
                                onBlur={handlePhoneChange}
                                style={{ borderColor: isPhoneValid === false && 'red' }}
                              />
                              <input
                                id='vehicleIdPix'
                                type='text'
                                placeholder='Chassis ou placa (opcional)'
                                value={vehicleId}
                                onChange={handleVehicleIdChange}
                              />
                            </form>
                          }
                          {paymentOption === 'credit' &&
                            <div className='column fadeIn' style={{gap: '10px'}}>

                              {/* <h3>Cartão de Crédito:</h3> */}
                              {userCreditCards.length > 0 && (
                                userCreditCards.slice(0, 1).map((card, index) => (
                                  <CardTile
                                    key={index}
                                    cardNumber={card.cardNumber}
                                    isDefault={card.isDefault}
                                    isSelected={selectedCardIndex === 0}
                                    // isSelected={false} //ui visual alternative; doesnt affect card selection functionality
                                    onClick={() => {handleAddNewCard(true); handleEditCard()}}
                                    // onClick={() => selectCard(0)}
                                  />
                                ))
                              )}
                              {userCreditCards.length == 0 &&
                                <div className='row' style={{justifyContent: 'center'}}>
                                  {/* <a className='colorTextLink' onClick={() => handleAddNewCard(true)}>Adicionar novo cartão</a> */}
                                  <Button
                                    className='buttonColorSecond smallButton'
                                    iconStart={<Add/>}
                                    label='Adicionar cartão'
                                    // label='Novo cartão'
                                    onClick={() => {handleAddNewCard(true); handleNewCard()}}
                                  />
                                </div>
                              }

                              {userCreditCards.length > 0 &&
                                <div className='column fadeIn' style={{gap: '10px'}}>
                                  <h3>Parcelas:</h3>
                                  <div className='row'>
                                    <select value={selectedParcels} onChange={handleParcelChange}>
                                      {renderParcelOptions()}
                                    </select>
                                  </div>
                                </div>
                              }

                              {/* <div className='row' style={{justifyContent: 'center'}}>
                                <a className='colorTextLink' onClick={() => navigate('/minha-conta#meus-dados')}>Editar meus cartões</a>
                              </div> */}
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </>
                }

                {(actualPage === 'pagamento2' && addingNewCard) &&
                  <>
                    <div className='column fadeIn' style={{width: '100%', maxWidth: '840px', marginInline: 'auto', gap: '16px'}}>
                      <div className='card' style={{width: '100%', gap: '20px'}}>
                        <div className='column' style={{padding: 'var(--mediumPadding)', gap: '10px'}}>
                            <div className='row' style={{alignItems: 'center'}}>
                              <a className='grayedText' onClick={() => handlePage('backward')}><ArrowBackSharp style={{fontSize: '18px', marginRight: '4px'}}/></a>
                              <h2>Cartão de Crédito</h2>
                              {/* <h2>Novo Cartão de Crédito</h2> */}
                            </div>  
                            <form id='newCard' className='cardRowContainer' onSubmit={saveNewCard}>
                              <div className='column' style={{flex: '1'}}>
                                <div className='cardFormContainer'>
                                  <div className='cardShape cardColor'>
                                    <div className='row' style={{gap: '10px'}}>
                                      <div className='column' style={{flex: '3', gap: 'var(--mediumRowGap)'}}>
                                        <div className='column'>
                                          <span>Número:</span>
                                          <input
                                            id='cardNumber'
                                            type='text'
                                            placeholder='0000 0000 0000 0000'
                                            value={cardNumber}
                                            onChange={handleCardNumberChange}
                                            onBlur={handleCardNumberChange}
                                            style={{ borderColor: isCardNumberValid === false && 'red' }}
                                            />
                                        </div>

                                        <div className='column'>
                                          <span>Nome do titular:</span>
                                          <input
                                            id='cardHolderName'
                                            type='text'
                                            placeholder='Conforme escrito no cartão'
                                            value={cardHolderName}
                                            onChange={handleCardHolderNameChange}
                                            onBlur={handleCardHolderNameChange}
                                            style={{ borderColor: isCardHolderNameValid === false && 'red' }}
                                          />
                                        </div>
                                      </div>
                                      {/* <div className='antifraudContainer'>
                                        <img src={antifraudIcon}/>
                                        <div className='column' style={{gap: '2px'}}>
                                          <h3>PAGAMENTO<br/>SEGURO</h3>
                                          <span>com sistema<br/>antifraude</span>
                                        </div>
                                      </div> */}
                                    </div>

                                    <div className='row' style={{gap: '10px'}}>
                                      <div className='column' style={{flex: '2'}}>
                                        <span>Validade:</span>
                                        <input
                                          id='validThru'
                                          type='text'
                                          placeholder='00/00'
                                          value={validThru}
                                          onChange={handleValidThruChange}
                                          onBlur={handleValidThruChange}
                                          style={{ borderColor: isValidThruValid === false && 'red' }}
                                        />
                                      </div>
                                      <div className='column' style={{flex: '3'}}>
                                        <span>Código de Verificação:</span>
                                        <input
                                          id='verificationCode'
                                          type='text'
                                          placeholder='3 ou 4 dígitos'
                                          value={verificationCode}
                                          onChange={handleVerificationCodeChange}
                                          onBlur={handleVerificationCodeChange}
                                          style={{ borderColor: isVerificationCodeValid === false && 'red' }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='column' style={{flex: '1', gap: '10px'}}>
                                <div className='cardShape'>
                                  <div className='column'>
                                    <span>CPF ou CNPJ do titular do cartão</span>
                                    <input
                                      id='clientDocCredit'
                                      type='text'
                                      placeholder='Obrigatório'
                                      value={clientDoc}
                                      onChange={handleClientDocChange}
                                      onBlur={handleClientDocChange}
                                      style={{ borderColor: isClientDocValid === false && 'red' }}
                                    />
                                    {clientDoc.replace(/\D/g, '').length > 11 && isClientDocValid && (
                                      <span className='grayedText' style={{marginLeft: '6px'}}>*Para compras interestaduais com CNPJ, poderá haver cobrança adicional de imposto.</span>
                                    )}
                                  </div>
                                  <div className='column'>
                                    <span>Telefone do titular do cartão:</span>
                                    <input
                                      id='phoneCredit'
                                      type='text'
                                      placeholder='Obrigatório'
                                      value={phone}
                                      onChange={handlePhoneChange}
                                      onBlur={handlePhoneChange}
                                      style={{ borderColor: isPhoneValid === false && 'red' }}
                                    />
                                  </div>
                                  <div className='column'>
                                    <span>Chassis ou placa do veículo:</span>
                                    <input
                                      id='vehicleIdCredit'
                                      type='text'
                                      placeholder='Opcional'
                                      value={vehicleId}
                                      onChange={handleVehicleIdChange}
                                      onBlur={handleVehicleIdChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>

                            <div className='column' style={{gap: '10px'}} id='payAddressScroll'>
                              <h3>Endereço de cobrança</h3>
                              <AddressTile
                                id='0'
                                name='É o mesmo da entrega'
                                address={`${selectedAddress.street}, ${selectedAddress.number}${selectedAddress.complement ? ' ' + selectedAddress.complement : ''} - ${selectedAddress.district}, ${selectedAddress.city} - ${selectedAddress.state}, ${selectedAddress.zipCode}`}                                isSelected={isSamePayAddressSelected}
                                highlight={payAddressHighlight}
                                onClick={() => handleSamePayAddressClick()}
                              />
                              {!newPayAddressSelected
                                ?
                                  <div className={`column defaultBorderedItem clickArea cardFormContainer itemSelect ${payAddressHighlight ? 'highlight' : ''}`} style={{gap: '10px', paddingTop: 'var(--mediumPadding)', paddingBottom: 'var(--mediumPadding)'}} onClick={() => addNewPayAddress()}>
                                    <span>Endereço de cobrança é outro</span>
                                  </div>
                                :
                                  <AddressTile
                                    name='Endereço de cobrança é outro'
                                    address={`${newPayAddress.street}, ${newPayAddress.streetNumber}${newPayAddress.complement ? ' ' + newPayAddress.complement : ''} - ${newPayAddress.neighborhood}, ${newPayAddress.city} - ${newPayAddress.uf}, ${newPayAddress.cep}`}
                                    isSelected={newPayAddressSelected}
                                    onClick={() => addNewPayAddress()}
                                  />
                              }
                            </div>
                            {/* <Button
                              className='buttonColorMain smallButton'
                              label='Concluir'
                              onClick={() => saveNewCard}
                            />
                            <div className='row' style={{justifyContent: 'center'}}>
                              <a onClick={() => handleAddNewCard(false)}>cancelar</a>
                            </div> */}
                        </div>
                      </div>
                    </div>
                  </>
                }

                {(actualPage === 'pagamento2' && addingNewCard) && isAddingPayAddress &&
                  <div className='defaultDialog fadeIn'>
                    <ClickOutside onOutsideClick={() => setIsAddingPayAddress(false)}>
                      <div className='column cardFormContainer defaultDialogContainer' style={{gap: '10px'}}>
                        <h2>Outro endereço de cobrança</h2>
                        {!showCustomAddress &&
                          <div className='row fadeIn' style={{ gap: '10px' }}>
                            <input
                              id='CEP'
                              type='text'
                              placeholder='00.000-000'
                              value={formattedCep}
                              onChange={handleCepChange}
                              onKeyDown={(e) => e.key === 'Enter' && searchCepPay(cep)}
                            />
                            <Button
                              className='buttonColorMain smallButton'
                              // iconEnd={<CouponIcon />}
                              label='Buscar CEP'
                              onClick={() => searchCepPay(cep)}
                            />
                          </div>
                        }
                        {showCustomAddress &&
                          <div className='column fadeIn' style={{gap: '10px'}}>
                            <div className='row clickArea' style={{padding: '6px', gap: '10px'}}>
                              <span>{formattedCep}</span><a className='colorTextLink' onClick={() => setShowCustomAddress(false)}>alterar CEP</a>
                            </div>
                            <form className='column' style={{gap: '10px'}} onSubmit={handleNewPayAddressSubmit}>
                              <input
                                id='street'
                                type='text'
                                placeholder='Rua ou Avenida'
                                value={newPayAddress.street}
                                onChange={handleNewPayAddressInputChange}
                                required
                              />
                              {errors.street && <span className='error'>{errors.street}</span>}
                              
                              <div className='row' style={{gap: '10px'}}>
                                <input
                                  style={{flex: '1'}}
                                  id='streetNumber'
                                  type='text'
                                  placeholder='Número'
                                  value={newPayAddress.streetNumber}
                                  onChange={handleNewPayAddressInputChange}
                                  required
                                />
                                {errors.streetNumber && <span className='error'>{errors.streetNumber}</span>}
                                
                                <input
                                  style={{flex: '2'}}
                                  id='complement'
                                  type='text'
                                  placeholder='Complemento (opcional)'
                                  value={newPayAddress.complement}
                                  onChange={handleNewPayAddressInputChange}
                                />
                              </div>
                              
                              <input
                                id='neighborhood'
                                type='text'
                                placeholder='Bairro'
                                value={newPayAddress.neighborhood}
                                onChange={handleNewPayAddressInputChange}
                                required
                              />
                              {errors.neighborhood && <span className='error'>{errors.neighborhood}</span>}
                              
                              <div className='row' style={{gap: '10px'}}>
                                <input
                                  style={{flex: '4'}}
                                  id='city'
                                  type='text'
                                  placeholder='Cidade'
                                  value={newPayAddress.city}
                                  onChange={handleNewPayAddressInputChange}
                                  required
                                />
                                {errors.city && <span className='error'>{errors.city}</span>}
                                <input
                                  style={{display: 'flex', flex: '1'}}
                                  id='uf'
                                  type='text'
                                  placeholder='UF'
                                  value={newPayAddress.uf}
                                  onChange={(e) => {
                                    const value = e.target.value.slice(0, 2).toUpperCase(); // Restrict to 2 characters and convert to uppercase
                                    setNewPayAddress((prevState) => ({
                                      ...prevState,
                                      uf: value,
                                    }));
                                  }}
                                  maxLength={2}
                                  required
                                />
                                {errors.uf && <span className='error'>{errors.uf}</span>}
                              </div>

                              <Button
                                className='buttonColorMain smallButton'
                                label='Salvar'
                                type='submit'
                              />
                            </form>
                            <div className='row' style={{justifyContent: 'center'}}>
                              <a onClick={() => setIsAddingPayAddress(false)}>cancelar</a>
                            </div>
                          </div>
                        }
                      </div>
                    </ClickOutside>
                  </div>
                }

                {actualPage === 'resumo' &&
                  <div className='column fadeIn' style={{width: '100%', gap: '16px'}}>
                    <div className='card' style={{width: '100%', gap: '20px'}}>
                      <div className='column' style={{padding: '10px', gap: '10px'}}>
                        <div className='row' style={{alignItems: 'center'}}>
                          {!finalizeLoading
                            ?
                              <a className='grayedText' onClick={() => handlePage('backward')}><ArrowBackSharp style={{fontSize: '18px', marginRight: '4px'}}/></a>
                            :
                              <a className='grayedText disableButton'><ArrowBackSharp style={{fontSize: '18px', marginRight: '4px'}}/></a>
                          }
                          <h2>Resumo</h2>
                          <input type="hidden" id="AdditionalInfo" value=""/>
                        </div>
                        <div className='column' style={{gap: '10px'}}>
                          <div className='column defaultBorderedItem' style={{gap: '10px'}}>
                            {/* <h3>Produtos:</h3> */}
                            {cartItemsBySellers.map((sellerGroup, sellerIndex) => (
                              <div className='summaryProductList'>
                                <h3>{(configData.defaultStore == 'GoParts' || configData.defaultStore == 'Peças Kia') ? mktNames[sellerGroup.sellerName] : sellerGroup.sellerName} - <span style={{color: 'var(--mainColor)'}}>
                                  {selectedShippingOptions[sellerGroup.sellerName] === 'pickup' ? <mark>Retirar na loja</mark> : <mark>{selectedShippingOptions[sellerGroup.sellerName]}</mark> } </span>
                                  {selectedShippingOptions[sellerGroup.sellerName] !== 'pickup' &&
                                    <span>({selectedAddress.name}: {selectedAddress.street})</span>
                                  }
                                </h3>
                                <div className='row' style={{gap: '6px'}}>
                                  {sellerGroup.items.map((product) => (
                                    <div className='row defaultBorderedItem' style={{alignItems: 'center', gap: '6px', padding: '2px', paddingInline: '6px'}}>
                                      <span className='grayedText' style={{whiteSpace: 'nowrap'}}>{product.itemQty} x </span>
                                      {configData.defaultStore === 'Peças Kia' && baraoHyundaiFilter.includes(product.id)
                                        ?
                                          <img src={contentData.missingPic} className='summaryProductRowImg'/>
                                        :
                                          <img src={product.img} className='summaryProductRowImg'/>
                                      }
                                      <div className='column'>
                                        <span translate="no">{product.title}</span>
                                        <span className='grayedText'>{formatPrice(product.price)}</span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                {/* <div className='column' style={{gap: '10px'}}>
                                  {sellerGroup.items.map((product) => (
                                    <div className='row' style={{alignItems: 'center', gap: '10px'}}>
                                      <span className='grayedText' style={{whiteSpace: 'nowrap'}}>{product.itemQty} x </span>
                                      <img src={product.img} className='summaryProductRowImg'/>
                                      <div className='column'>
                                        <h3 className=''>
                                          {product.title} {product.titleComplement} <span className='grayedText'>- {product.id}</span>
                                        </h3>                                      
                                        <div className='row' style={{gap: '6px'}}>
                                          {product.hasSale &&
                                            <p className='originalPrice'>{formatPrice(product.originalPrice*product.itemQty)}</p>
                                          }
                                          <p className='mainPrice' style={{fontWeight: '100'}}>{formatPrice(product.price*product.itemQty)}</p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                  <h3>Envio: <span style={{color: 'var(--mainColor)'}}>{selectedShippingOptions[sellerGroup.sellerName]}</span></h3>
                                </div> */}
                              </div>
                            ))}
                          </div>
                          <div className='column defaultBorderedItem' style={{gap: '10px'}}>
                            {gpuser &&
                              <p><span className='grayedText'>Conta: </span><span className='textColor'>{gpuser.email}</span></p>
                            }
                            <p><span className='grayedText'>Produtos: </span><span className='textColor'>{formatPrice(totalPrice)}</span></p>
                            <p><span className='grayedText'>Envio: </span>
                              {totalDeliveryPrice > 0
                                ? <span className='textColor'>{formatPrice(totalDeliveryPrice)}</span>
                                : <span className='greenText'>Grátis</span>
                              }
                            </p>
                            {discountValue !== 0 && discountPercent === 0 &&
                              <p><span className='grayedText'>Desconto: </span><span className='greenText'>{formatPrice(discountValue)}</span></p>
                            }
                            {discountValue !== 0 && discountPercent !== 0 &&
                              <p><span className='grayedText'>Desconto: </span><span className='greenText'>{formatPercent(discountPercent)} ({formatPrice(discountValue)})</span></p>
                            }
                            {activeCoupon && 

                              <div className='row' style={{justifyContent: 'space-between'}}>
                                <span className='grayedText'>
                                  Cupom: <span style={{ color: 'var(--mainColor)' }}>{coupon}</span>
                                </span>
                                {actualPage !== 'resumo' &&
                                  <a onClick={() => cancelCoupon()}>remover</a>
                                }
                              </div>
                            }
                            <p><span className='grayedText'>Forma de pagamento: </span><span className='textColor'>{`${paymentOption === 'pix' ? 'PIX' : ''} ${paymentOption === 'credit' ? 'Cartão de Crédito' : ''} `}</span></p>
                            {paymentOption === 'credit' &&
                              <p><span className='grayedText'>Parcelas: </span><span className='textColor'>{selectedParcels}x de {formatPrice(purchasePrice/selectedParcels)}</span></p>
                            }
                            <p><mark>Total: {formatPrice(purchasePrice)}</mark></p>
                            {/* <p><mark><span className='grayedText'>Total: </span><span className='textColor'>{formatPrice(purchasePrice)}</span></mark></p> */}
                          </div>
                          {paymentOption === 'credit' && selectedCard && (
                            <div className='column' style={{ gap: '10px' }}>
                              <CardTile
                                key={selectedCard.cardNumber}
                                cardNumber={selectedCard.cardNumber}
                                isDefault={selectedCard.isDefault}
                                isSelected={false}
                                disabled={true}
                                onClick={() => {}}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                }

                {(cartItems.length > 0) &&
                  <div className='card checkoutSummary'>
                    <div className='column' style={{padding: 'var(--mediumPadding)', gap: 'var(--mediumRowGap)'}}>
                      {/* <h2>Resumo</h2> */}
                      {/* {actualPage !== 'carrinho' &&
                        <div className='row clickArea' style={{gap: '10px', alignItems: 'center', flexWrap: 'wrap'}} onClick={() => navigateToPage('carrinho')}>
                          {cartItems.map(product => (
                            <div className='row defaultBorderedItem' style={{gap: '4px', padding: '4px', alignItems: 'center'}}>
                              <span>{product.itemQty}x</span>
                              <img src={product.img} style={{height: '32px'}}/>
                            </div>
                          ))}
                          <a className='colorTextLink hiddenLink'>alterar</a>
                        </div>
                      } */}
                      {actualPage !== 'resumo' && !addingNewCard &&
                        <>
                          {cartItems.length > 0 &&
                            <p><span className='grayedText'>Produtos: </span><span className='textColor'>{formatPrice(totalPrice)}</span></p>
                          }
                          {actualPage !== 'carrinho' && actualPage !== 'endereço' &&  discountValue !== 0 && discountPercent === 0 &&
                            <p><span className='grayedText'>Desconto: </span><span className='greenText'>{formatPrice(discountValue)}</span></p>
                          }
                          {actualPage !== 'carrinho' && actualPage !== 'endereço' &&  discountValue !== 0 && discountPercent !== 0 &&
                            <p><span className='grayedText'>Desconto: </span><span className='greenText'>{formatPercent(discountPercent)} ({formatPrice(discountValue)})</span></p>
                          }
                          {actualPage !== 'carrinho' && actualPage !== 'endereço' && (
                            <>
                              {!activeCoupon ? (
                                <>
                                  {!isInsertingCoupon ? (
                                    <>
                                      {actualPage !== 'resumo' && 
                                        <a className='fadeIn' onClick={handleInsertCoupon}>
                                          {/* <CouponIcon/> */}
                                          Inserir cupom
                                        </a>
                                      }
                                    </>
                                  ) : (
                                    <form className='row center fadeIn' style={{ gap: '10px' }} onSubmit={validateCoupon}>
                                      <input
                                        id='couponField'
                                        type='text'
                                        placeholder='Cupom...'
                                        value={coupon}
                                        onChange={handleCouponChange}
                                        className={`${couponHighlight ? 'highlight' : ''}`}
                                      />
                                      {coupon !== '' ? (
                                        <Button
                                          className='buttonColorMain smallButton'
                                          label='Validar'
                                          type='submit'
                                        />
                                      ) : (
                                        <a style={{ color: 'var(--textColor)' }} onClick={cancelCoupon}>fechar</a>
                                      )}
                                    </form>
                                  )}
                                </>
                              ) : (
                                <div className='row fadeIn' style={{justifyContent: 'space-between'}}>
                                  <span className='grayedText'>
                                    Cupom: <span style={{ color: 'var(--mainColor)' }}>{coupon}</span>
                                  </span>
                                  {actualPage !== 'resumo' &&
                                    <a onClick={() => cancelCoupon()}>remover</a>
                                  }
                                </div>
                              )}
                            </>
                          )}
                          {actualPage !== 'carrinho' && actualPage !== 'endereço' &&
                            <p><span className='grayedText'>Envio: </span>
                              {!isFreeShipping
                                ? <span className='textColor'>{formatPrice(totalDeliveryPrice)}</span>
                                : <span className='greenText'>Grátis</span>
                              }
                            </p>
                          }
                        </>
                      }

                      {(actualPage === 'pagamento' || actualPage === 'pagamento2') && !addingNewCard &&
                        <span className='grayedText'>Total: <span className='mainPrice'>{formatPrice(purchasePrice)}</span></span>
                      }

                      {actualPage === 'resumo' && paymentOption === 'credit' &&
                        <div className='column center' style={{gap: '10px'}}>
                          {/* <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="var(--secondColor)"><path d="m436-347 228-228-42-41-183 183-101-101-44 44 142 143Zm44 266q-140-35-230-162.5T160-523v-238l320-120 320 120v238q0 152-90 279.5T480-81Zm0-62q115-38 187.5-143.5T740-523v-196l-260-98-260 98v196q0 131 72.5 236.5T480-143Zm0-337Z"/></svg> */}
                          <p><span className='grayedText'>Atenção: </span><span className='textColor'>para sua segurança, o aplicativo do seu banco pode solicitar uma verificação adicional através do seu celular.</span></p>
                          <div className='row center' style={{gap: '24px'}}>
                            <img style={{height: '48px'}} src={ThreeDSecure} alt='3D Secure' loading='lazy'/>
                            <img style={{height: '32px'}} src={MasterSecure} alt='MasterSecure' loading='lazy'/>
                            <img style={{height: '32px'}} src={VisaSecure} alt='VisaSecure' loading='lazy'/>
                          </div>
                        </div>
                      }

                      {!addingNewCard
                        ?
                          <>
                            {!finalizeLoading
                              ?
                                <>
                                  {!(loadingShippingOptions && actualPage === 'entrega')
                                    ?
                                      <>
                                        {(actualPage === 'entrega' && (Object.keys(selectedShippingOptions).length === 0 || Object.keys(selectedShippingOptions).length !== cartItemsBySellers.length))
                                          ?
                                            <Button
                                              className='buttonColorMain largeButton disableButton'
                                              label='Selecione uma opção'
                                            />
                                          :
                                            (actualPage === 'endereço' && (isAddingNoLoginAddress || isAddingAddressLogged) && !selectedAddress)
                                              ?
                                                <Button
                                                  className='buttonColorMain largeButton disableButton'
                                                  label='Adicione um endereço'
                                                />
                                              :
                                                <Button
                                                  className={`${actualPage === 'resumo' ? 'buttonColorThird' : 'buttonColorMain'} largeButton `}
                                                  label={`${actualPage === 'resumo' ? 'Finalizar Compra' : 'Continuar'}`}
                                                  onClick={() => handlePage('forward')}
                                                />
                                            
                                        }
                                      </>
                                    :
                                      <Button
                                        className='buttonColorMain largeButton disableButton'
                                        label='Calculando'
                                      />
                                  }
                                </>
                              :
                                <div className='column center'>
                                  <CircularProgress
                                    style={{ padding: '20px' }}
                                    sx={{
                                      color: 'var(--secondColor)',
                                    }}
                                  />
                                  <span>Processando seu pagamento...</span>
                                </div>
                            }

                            {actualPage !== 'carrinho' && !finalizeLoading &&
                              <a className='grayedText !important' style={{textAlign: 'center'}} onClick={() => handlePage('backward')}>Voltar</a>
                              // <Button
                              //   className='buttonColorSecond smallButton'
                              //   label='Voltar'
                              //   onClick={() => handlePage('backward')}
                              // />
                            }
                          </>
                        :
                          <>
                            <Button
                              className='buttonColorThird largeButton'
                              label='Usar este cartão'
                              onClick={saveNewCard}
                            />
                            <a className='grayedText !important' style={{textAlign: 'center'}} onClick={() => handlePage('backward')}>Voltar</a>
                          </>
                      }
                    </div>
                  </div>
                }
              </div>
            :
              <div className='pageContent center column' style={{gap: '10px'}}>
                <h2>Nenhum produto no carrinho :(</h2>
                <Button
                    className='buttonColorMain smallButton'
                    iconStart={<AddShoppingCart style={{fontSize: '18px'}}/>}
                    label='Comprar agora'
                    onClick={openVehicleSelect}
                />
              </div>
          }

          {actualPage === 'resumo' &&
            <div className='rowToColumn800 homeSectonsBorder paymentContainer' style={{backgroundColor: 'var(--veryLightGray) !important'}}>
              {(configData.defaultStore === 'GoParts') &&
                <div className='homeSectionContainer center payRowLeft'>
                    <img src={GoParca_Zen} alt='GoParts_GoParça_Segurança' className='paymentImg' loading='lazy'/>
                </div>
              }
              <div className='homeSectionContainer center payRowRight'>
                  <h2 style={{fontSize: '24px'}}>Compre com Segurança e Tranquilidade</h2>
                  <div className='row' style={{gap: '10px', alignItems: 'center'}}>
                      <div className='column' style={{gap: '10px', alignItems: 'start'}}>
                          <span>Compre peças originais para o seu veículo sem preocupações, em um ambiente digital seguro contra falsificações e má procedência.</span>
                          <span>Confira nossa <Link to='/privacidade'>Política de Privacidade</Link></span>
                      </div>
                      {configData.defaultStore === 'GoParts' &&
                          <img src={GoParca_Zen_2} alt='GoParts_GoParça_Segurança' className='paymentImgMobile' loading='lazy'/>
                      }
                  </div>
                  <div className='row' style={{flexWrap: 'wrap', gap: '20px'}}>
                      {CardOperators.map((item, index) => (
                          <img 
                              key={index} // Add a unique key here
                              src={item} 
                              alt={`${item.split('/').pop().split('.')[0]} logo`} 
                              className='cardOpImg' 
                              loading='lazy'
                          />
                      ))}
                  </div>
                  <div className='row' style={{flexWrap: 'wrap', gap: '20px', alignItems: 'center', borderTop: '1px solid rgba(0, 0, 0, 0.12)', paddingTop: '20px'}}>
                      <div className='antifraudContainer' style={{backgroundColor: 'var(--lightGray)'}}>
                          <img src={antifraudIcon} alt='GoParts_Antifraude' loading='lazy'/>
                          <div className='column center' style={{gap: '2px'}}>
                              <h3>PAGAMENTO<br/>SEGURO</h3>
                              {/* <span>com sistema<br/>antifraude</span> */}
                          </div>
                      </div>
                      <img src={Lgpd} alt='GoParts_LGPD' className='payImg' loading='lazy'/>
                      <img src={Ssl} alt='GoParts_SSL' className='payImg' loading='lazy'/>
                      {/* <img src={Pix} className='payImg' loading='lazy'/> */}
                  </div>
              </div>
          </div>
          }

          {/* {actualPage === 'carrinho' && relatedProducts.length > 0 && InternalID &&
            <div className='homeSectionContainer' style={{backgroundColor: 'transparent'}}>
              <ProductSlider data={relatedProductsData}/>
            </div>
          } */}

        </div>
      
      {auth3DS_url != '' && auth3DS_accessToken != '' &&
        <>
          {/* {console.log("3DS URL and Access Token are set. URL:", auth3DS_url, "Access Token:", auth3DS_accessToken)} */}
          <iframe name="ddc-iframe" height="1" width="1" style={{display: 'none'}}> </iframe>
          <form id="ddc-form" target="ddc-iframe" method="POST" action={auth3DS_url}>
            <input type="hidden" name="JWT" value={auth3DS_accessToken}/>
          </form>
        </>
      }
      {auth3DS_challenge_url != '' && auth3DS_challenge_accessToken != '' && finalizeLoading && (
        <div className='creditChallengeContainer' style={{backgroundColor: 'var(--mainColor)'}}>
          <div className='column center' style={{marginBottom: '20px'}}>
            <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="var(--secondColor)"><path d="m436-347 228-228-42-41-183 183-101-101-44 44 142 143Zm44 266q-140-35-230-162.5T160-523v-238l320-120 320 120v238q0 152-90 279.5T480-81Zm0-62q115-38 187.5-143.5T740-523v-196l-260-98-260 98v196q0 131 72.5 236.5T480-143Zm0-337Z"/></svg>
            <h2 style={{color: 'white'}}>Siga as instruções do seu banco:</h2>
          </div>
          <div style={{backgroundColor: 'white'}}>
            <iframe name="step-up-iframe" height={heightChallenge} width={widthChallenge}></iframe>
          </div>
          <form id="step-up-form" target="step-up-iframe" method="POST" action={auth3DS_challenge_url}>
            <input type="hidden" name="JWT" value={auth3DS_challenge_accessToken} />
          </form>
        </div>
      )}
      {isLoadingChallengeApproval || isLoadingSummary &&
        <div className='pageOverlay'>
          <div className='column center'>
            <CircularProgress
            style={{ padding: '20px' }}
            sx={{
              color: 'var(--secondColor)',
            }}
            />
          </div>
        </div>
      }
    </div>
  );
}

export default Checkout;




import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './Header.css';
import HeaderNavigation from './HeaderNavigation';
import Logo from '../Common/Logo';
import Search from './Search';
import { contentData, configData } from '../Whitelabel/websitesData';
import { Home } from '@mui/icons-material';

function Header() {
  const [hasText, setHasText] = useState(false);
  const [isIframe, setIsIframe] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (configData.defaultStore === 'Carlider') {
      setIsIframe(window.self !== window.top);
    }
  }, []);

  return (
    <div className='headerBackground'>
    {/* <div className='headerBackground' style={{marginTop: isIframe ? '67px' : '0'}}> */}
      <div className="headerContainer">
        <NavLink to='/'>
          {!isIframe
          ?
            <Logo
              type={configData.defaultStore === 'GoParts'
                ? 'mainLogoWhite'
                : 'mainLogo'
              }
              class='headerLogo'
            />
          :
            <Home style={{color: 'var(--secondColor)', fontSize: '24px', cursor: 'pointer', marginRight: '6px'}} onClick={() => navigate('/')}/>
          }
        </NavLink>
        <NavLink to='/' className={`${!hasText ? 'visible' : 'notVisible'}`}>
          <Logo
            type={configData.defaultStore === 'GoParts'
              ? 'bannerLogoWhite'
            : 'squareLogo'
          }
            class='headerLogo'
          />
        </NavLink>
        {/* {!(configData.defaultStore === 'UmuaramaHD' || configData.defaultStore === 'UmuaramaMotos') &&
          <Search setHasText={setHasText}/>
        } */}
        <Search setHasText={setHasText}/>
        <div className={`${!hasText ? 'visible' : 'notVisible'}`}>
          <HeaderNavigation />
        </div>
      </div>
    </div>
  );
}

export default Header;

/**
 * Validates a Brazilian CPF number
 * @param {string} cpf - The CPF number to validate (can include punctuation)
 * @returns {boolean} - True if CPF is valid, false otherwise
 */
export const isValidCPF = (cpf) => {
    // Remove any non-numeric characters
    const cleanCPF = cpf.replace(/\D/g, '');
    // console.log('Clean CPF:', cleanCPF);
  
    // Check if it has 11 digits
    if (cleanCPF.length !== 11) {
        // console.log('Invalid length');
        return false;
    }
  
    // Check if all digits are the same
    if (/^(\d)\1{10}$/.test(cleanCPF)) {
        // console.log('All digits are the same');
        return false;
    }
  
    // Calculate first check digit
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cleanCPF.charAt(i)) * (10 - i);
    }
    let checkDigit1 = 11 - (sum % 11);
    if (checkDigit1 >= 10) checkDigit1 = 0;
  
    // Verify first check digit
    if (checkDigit1 !== parseInt(cleanCPF.charAt(9))) return false;
  
    // Calculate second check digit
    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cleanCPF.charAt(i)) * (11 - i);
    }
    let checkDigit2 = 11 - (sum % 11);
    if (checkDigit2 >= 10) checkDigit2 = 0;
  
    // Verify second check digit
    return checkDigit2 === parseInt(cleanCPF.charAt(10));
  };
  
  /**
   * Validates a Brazilian CNPJ number
   * @param {string} cnpj - The CNPJ number to validate (can include punctuation)
   * @returns {boolean} - True if CNPJ is valid, false otherwise
   */
  export const isValidCNPJ = (cnpj) => {
    // Remove any non-numeric characters
    const cleanCNPJ = cnpj.replace(/\D/g, '');
  
    // Check if it has 14 digits
    if (cleanCNPJ.length !== 14) return false;
  
    // Check if all digits are the same
    if (/^(\d)\1{13}$/.test(cleanCNPJ)) return false;
  
    // First check digit calculation
    let size = 12;
    let numbers = cleanCNPJ.substring(0, size);
    let digits = cleanCNPJ.substring(size);
    let sum = 0;
    let pos = size - 7;
  
    for (let i = size; i >= 1; i--) {
      sum += parseInt(numbers.charAt(size - i)) * pos--;
      if (pos < 2) pos = 9;
    }
  
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(0))) return false;
  
    // Second check digit calculation
    size = 13;
    numbers = cleanCNPJ.substring(0, size);
    digits = cleanCNPJ.substring(size);
    sum = 0;
    pos = size - 7;
  
    for (let i = size; i >= 1; i--) {
      sum += parseInt(numbers.charAt(size - i)) * pos--;
      if (pos < 2) pos = 9;
    }
  
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    return result === parseInt(digits.charAt(0));
  };
  
  /**
   * Validates either a CPF or CNPJ number
   * @param {string} document - The document number to validate (can include punctuation)
   * @returns {boolean} - True if document is a valid CPF or CNPJ, false otherwise
   */
  export const isValidDocument = (document) => {
    const cleanDoc = document.replace(/\D/g, '');
    // console.log('isValidDocument called with:', cleanDoc);
    
    if (cleanDoc.length === 11) {
        const isValid = isValidCPF(cleanDoc);
        // console.log('CPF validation result:', isValid);
        return isValid;
    }
    
    if (cleanDoc.length === 14) {
        const isValid = isValidCNPJ(cleanDoc);
        // console.log('CNPJ validation result:', isValid);
        return isValid;
    }
    
    return false;
  };
  
  /**
   * Gets the type of document (CPF or CNPJ)
   * @param {string} document - The document number (can include punctuation)
   * @returns {'CPF' | 'CNPJ' | null} - The type of document or null if invalid
   */
  export const getDocumentType = (document) => {
    const cleanDoc = document.replace(/\D/g, '');
    
    if (cleanDoc.length === 11 && isValidCPF(cleanDoc)) {
      return 'CPF';
    }
    
    if (cleanDoc.length === 14 && isValidCNPJ(cleanDoc)) {
      return 'CNPJ';
    }
    
    return null;
  };
import mainLogo from '../images/UmuaramaMotos/logo-umuarama_motopecas.png';
import mainLogoWhite from '../images/UmuaramaMotos/logo-umuarama_motopecas_white.png';
import squareLogoWhite from '../images/UmuaramaMotos/logo-umuarama_motopecas_Square_white.png';
import banner00 from '../images/UmuaramaMotos/UHD_banner_home_02.webp';
import banner01 from '../images/UmuaramaMotos/UDH_banner_harley_01.1.webp';
import banner02 from '../images/UmuaramaMotos/U_ktm_bg_04.1.webp';
import banner03 from '../images/UmuaramaMotos/U_triumph_bg_03.1.webp';

export const configData = {
    defaultStore: 'UmuaramaMotos',
    defaultStoreLink: 'loja.umuaramamotos.com.br',
    launchDate: '15/05/2023',
    defaultContactEmail: 'ecommerce@umuaramamotos.com.br',
    whatsappNumber: '62 99936 2311',
    CNPJname: 'Umuarama Administracao e Participacao Concessionaria LTDA',
    CNPJ: '26.481.915/0001-50',
    CNPJaddress: 'Rua Tamboril, n. 91, Qd. 127, R. L-3, Setor - Santa Genoveva, Goiânia - GO, 74672-540',
    launchDate: '07/09/2024',

    multiContact: {},
    searchByPlate: false,

    coupons: [
        // {
        //     coupon: '10PORCENTO',
        //     discountPrice: 0,
        //     discountPercent: 0.1,
        //     freeShipping: false,
        // },
        // {
        //     coupon: '10REAIS',
        //     discountPrice: 10,
        //     discountPercent: 0,
        //     freeShipping: false,
        // },
        // {
        //     coupon: 'FRETEGRATIS',
        //     discountPrice: 0,
        //     discountPercent: 0,
        //     freeShipping: true,
        // },
    ],

    brands: [
        // 'audi',
        // 'bmw',
        // 'chery',
        // 'chevrolet',
        // 'citroen',
        // 'fiat',
        // 'ford',
        'harley-davidson',
        // 'honda',
        // 'honda motos',
        // 'hyundai',
        // 'jeep',
        // 'kia',
        // 'mercedes-benz',
        // 'mini',
        // 'mitsubishi',
        // 'nissan',
        // 'peugeot',
        // 'renault',
        // 'toyota',
        // 'volkswagen',
        // 'volvo',
        // 'yamaha',
    ],

    homologBrands: [
        // 'motorcraft',
        // 'motrio',
        // 'acdelco',
        // 'mopar',
        // 'bproauto',
    ],

    storeSellersWarehouses: [
        'UMUARAMA HARLEY GOIANIA',
        "UMUARAMA HARLEY UBERLANDIA", //Concessionária Harley Davidson - Uberlândia MG
        "UMUARAMA KTM GOIANIA",
        "TRIUMPH UBERLANDIA - UMUARAMA",
    ],

    disabledSellers: [],

    endYear: 2025,
    startYear: 1996,

    payment: {
        maxParcels: 6,
        checkoutParcels: {
            0: 1,
            50: 2,
            200: 3,
            300: 4,
            500: 5,
            1000: 6,
        }
    },
    
    customShipping: {
        deliveryTime: 1,    
    }
}

export const contentData = {
    mainLogo: mainLogoWhite,
    squareLogo: squareLogoWhite,
    banner01: banner01,
    banner02: banner02,
    banner03: banner03,
    bannerLogoWhite: mainLogoWhite,
    aboutUsBg: banner00,
    paymentImg: mainLogo,
    paymentImgMobile: mainLogo,

    bannerHighlight: 'Compre peças <span style="font-weight: bold;">originais</span> compatíveis com sua <span style="font-weight: bold;">Harley-Davidson</span>, <span style="font-weight: bold;">KTM</span> ou <span style="font-weight: bold;">Triumph</span>, direto das concessionárias <span style="font-weight: bold;">Umuarama</span>.',
    aboutUs: {
        title: 'Quem somos',
        content: [
            'Foi em 1993, que a primeira concessionária Umuarama surgiu na cidade de Araguaína, Tocantins, com a bandeira Fiat. O novo negócio revolucionou o mercado local, que refletia os tempos áureos do recém lançado Uno Mille (1990), que apesar de não ser tão potente, caiu nas graças do público pela boa relação custo/benefício e pelo baixo consumo.',
            'Em 2005, a segunda concessionária Umuarama chegou em Araguaína, levando a sofisticação e imponência da Toyota. Naquela época, o Corolla, que tinha Brad Pitt como garoto propaganda e INXS na trilha do comercial, consolidou-se como o maior sucesso de vendas da marca no Brasi. Este passo foi um marco evolutivo para a Umuarama que ampliou sua atuação, conquistando espaço nos estados do Maranhão e Pará. Foi em 2011 que a Umuarama Fiat também chegou na cidade de Redenção no Pará e, em 2013, na cidade de Parauapebas.',
            'Em 2019, novos negócios foram incorporados às concessionárias, levando um pacote completo de soluções e serviços para que todos os clientes pudessem vivenciar a melhor experiência ao escolher o seu novo carro ou moto: surgiu assim as áreas de Carros seminovos, Consórcios e Seguros. Hoje, são mais de 22 concessionárias em 5 estados brasileiros – Pará, Maranhão, Tocantins, Goiás e Minas Gerais -, representando grandes marcas do mercado automobilístico mundial: Toyota, Fiat, Volkswagen, Kia, Citroën, Harley-Davidson e KTM.',
        ],
        link: 'https://goias.umuaramaharleydavidson.com.br/quem-somos/',
        linkCta: 'Saiba mais',
    },

    socialMedia: {
        Instagram: 'https://www.instagram.com/umuaramaharley/',
        Facebook: 'https://www.facebook.com/umuarama.harleydavidson?paipv=0&eav=Afa0hek-Ed663_4ZZhmR4nBDtRMiK90LXdpheFzEKFYYNDrXc46OfltGQCvLbz9ulKk&_rdr',
        LinkedIn: '',
        Youtube: '',
        Twitter: '',
    },

    footerContent: [
        ''
    ],
}
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import './Pedidos.css';
import Button from '../Common/Button';
import { formatPrice, formatDate, formatTime, formatWeekday } from '../Utilities/Formats';
import ExpandDownIcon from "@mui/icons-material/ArrowDropDown";
import ExpandUpIcon from "@mui/icons-material/ArrowDropUp";
import CopyIcon from "@mui/icons-material/ContentCopy";
import { getDoc, doc } from 'firebase/firestore'; // Import Firestore functions
import { firestore } from '../firebase'; // Import the Firestore instance
import qrcode from '../images/qrcode_placeholder.webp';
import { Cancel, Chat, KeyboardArrowDown, KeyboardArrowUp, LocalShippingOutlined, SettingsBackupRestore, Star, StarBorderOutlined } from '@mui/icons-material';
import ClickOutside from '../Utilities/ClickOutside';
import { handleWhatsappClick } from '../Utilities/Whatsapp';
import { configData, contentData } from '../Whitelabel/websitesData';
import { baraoHyundaiFilter } from '../Whitelabel/Barao/baraoHyundaiFilter';
import { getMktName } from '../Providers/Sellers'; // Add this import
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function OrderItem(props) {
    const navigate = useNavigate();
    const [isExpandedInfo, setIsExpandedInfo] = useState(false);
    const [productDetails, setProductDetails] = useState([]);
    const [visibleShippingTracker, setVisibleShippingTracker] = useState(true);
    const [reviewingOrder, setReviewingOrder] = useState(false);
    const [processedShippingOptions, setProcessedShippingOptions] = useState({});

    const [hasReview, setHasReview] = useState(false); //update based on if review exists for current order and user

    const qrCode = props.payment.qrcode || '';
    const pixCode = props.payment.pixCode || '';

    //placeholder existing review
    // const existingReview = {
    //   order: props.id,
    //   score: 4,
    //   text: 'Peça realmente original de fábrica, demorou um pouco pra chegar, mas o preço era o melhor do Brasil!'
    // }
    
    const existingReview = ''

    const generatePixLink = (qrCode) => {
      
    }

    useEffect(() => {
      if (qrCode !== '') {
        generatePixLink(qrCode);
      }
    }, [qrCode]);


    // useEffect(() => {
    //   if (props.shippingIDs) {
    //     setVisibleShippingTracker(true)
    //   }
    // }, [props.shippingIDs])

    useEffect(() => {
      if (existingReview != '') {
        setHasReview(true);
      }
    }, [existingReview]);

    const toggleExpand = () => {
      setIsExpandedInfo(!isExpandedInfo);
    };

    useEffect(() => {
      const fetchProductDetails = async () => {
        const products = await Promise.all(
          props.items.map(async (item) => {
            const productDoc = await getDoc(doc(firestore, 'gp_warehouse', item.pid));
            if (productDoc.exists()) {
              return { id: item.id, qty: item.qty, ...productDoc.data() };
            } else {
              console.error(`Product with id ${item.id} not found in Firestore`);
              return { id: item.id, qty: item.qty, title: 'Unknown Product', img: [] };
            }
          })
        );
        setProductDetails(products);
      };

      fetchProductDetails();
    }, [props.items]);

    const orderStatus = {
      4: {
        label: 'Novo pedido',
        bgcolor: 'gray',
        color: 'white'
      },
      1: {
        label: 'Preparação',
        bgcolor: 'gray',
        color: 'white'
      },
      2: {
        label: 'Em transporte',
        bgcolor: 'var(--secondColor)',
        color: 'white'
      },
      3: {
        label: 'Entregue',
        bgcolor: 'var(--greenText)',
        color: 'white'
      },
      0: {
        label: 'Cancelado',
        bgcolor: 'var(--lightGray)',
        color: 'gray'
      }
    };

    let orderDate;

    try {
      orderDate = props.date.toDate();
    } catch (error) {
      console.error('Error converting date:', error);
      // Provide a fallback value if conversion fails, e.g., the current date
      orderDate = new Date(); 
    }

    const [newUserReview, setNewUserReview] = useState({
      order: props.id,
      score: 0,  // Initially no stars selected
      text: '',  // Initially no text
    });

    // Function to handle star click
    const handleStarClick = (score) => {
      setNewUserReview({ ...newUserReview, score });
    };

    // Function to handle text input
    const handleTextChange = (event) => {
      const textarea = event.target;
      textarea.style.height = 'auto';  // Reset the height first
      textarea.style.height = `${textarea.scrollHeight}px`;  // Set the height to the scroll height
    
      setNewUserReview({ ...newUserReview, text: event.target.value });  // Update the state with the new text
    };

    // Function to handle form submission
    const handleReviewSubmit = () => {
      const reviewConfirm = window.confirm('Tem certeza que deseja enviar esta avaliação?')
      if (reviewConfirm) {
        //console.log('User Review Submitted:', newUserReview);
        setReviewingOrder(false);
      }
    };

    const prepareAddressData = (address) => {
      const addressParts = [
        address['street'],
        address['number'],
        address['district'],
        address['city'],
        address['state'],
        address['zipCode']
      ];
      
      // Filter out any parts that are null, undefined, or empty, then join with a space
      const addressString = addressParts.filter(part => part).join(' ');
  
      return addressString;
    };

    const [trackingCode, setTrackingCode] = useState('placeholderTrackingCode');

    const copyTrackingCode = async (code) => {
      try {  
          if (code) {
              // Check if Clipboard API is available
              if (navigator.clipboard && navigator.clipboard.writeText) {
                  await navigator.clipboard.writeText(code);
                  toast.success('Código de rastreio copiado!');
              } else {
                  // Fallback for older browsers or if Clipboard API is not available
                  const textArea = document.createElement("textarea");
                  textArea.value = code;
                  document.body.appendChild(textArea);
                  textArea.select();
                  document.execCommand('copy');
                  document.body.removeChild(textArea);
                  toast.success('Código de rastreio copiado!');
              }
          } else {
              toast.error('Falha ao copiar código de rastreio :(');
          }
      } catch (error) {
          toast.error('Falha ao copiar código de rastreio :(');
      }
    };

    const handleShare = async () => {
      try {
          //console.log('Attempting to copy PIX code:', pixCode); // Debugging log
  
          if (pixCode) {
              // Check if Clipboard API is available
              if (navigator.clipboard && navigator.clipboard.writeText) {
                  await navigator.clipboard.writeText(pixCode);
                  // alert('Código PIX copiado!');
              } else {
                  // Fallback for older browsers or if Clipboard API is not available
                  const textArea = document.createElement("textarea");
                  textArea.value = pixCode;
                  document.body.appendChild(textArea);
                  textArea.select();
                  document.execCommand('copy');
                  document.body.removeChild(textArea);
                  // alert('Código PIX copiado!');
              }
          } else {
              console.error('PIX code is not defined or empty'); // Debugging log
              alert('Código PIX não está disponível');
          }
      } catch (error) {
          console.error('Erro ao copiar código PIX:', error);
          alert('Falha ao copiar código PIX :(');
      }
  };

    const externalShippingLink = (code) => {
      const baseUrl = 'https://transportadora-placeholder/';
      const fullUrl = `${baseUrl}?codigo=${code}`;
      window.open(fullUrl, '_blank');
    };

    const handleShippingTrackerClick = (productId) => {
      setTrackingCode(`product=${productId}`); //get tracking code from product id
      setVisibleShippingTracker(true);
    };

    const handleReviewClick = () => {
      setReviewingOrder(true);
    }
    
    const handleReviewCancel = () => {
      setNewUserReview({
        score: 0,
        text: '',
      });
      setReviewingOrder(false);
    }

    // Add this new useEffect to process shipping options
    useEffect(() => {
        const processShippingOptions = async () => {
            if (!props.shippingOptions) return;

            const getCarrierUrl = (carrier) => {
                switch (carrier) {
                    case 'Jadlog':
                        return 'https://www.jadlog.com.br/siteInstitucional/tracking.jad';
                    case 'Correios':
                        return 'https://rastreamento.correios.com.br/app/index.php';
                    default:
                        return null;
                }
            };

            const processed = {};
            for (const [store, carrier] of Object.entries(props.shippingOptions)) {
                // Only get market name if it's Goparts or Peças Kia
                let storeName;
                if (configData.defaultStore === 'GoParts' || configData.defaultStore === 'Peças Kia') {
                    storeName = await getMktName(store);
                } else {
                    storeName = store;
                }
                
                // Process carrier text
                const processedCarrier = carrier.toLowerCase() === 'pickup' 
                    ? <>
                        <span>Retirar no local</span>
                        <span className="grayedText"> *entraremos em contato quando estiver pronto.</span>
                      </>
                    : carrier;

                // Create a structured object with all necessary data
                processed[storeName || store] = {
                    carrier: processedCarrier,
                    trackingId: props.shippingIDs?.[store] || null,
                    carrierUrl: getCarrierUrl(processedCarrier)
                };
            }
            setProcessedShippingOptions(processed);
        };

        processShippingOptions();
    }, [props.shippingOptions, props.shippingIDs]);

    return (
      <>
        <div className='card column'>
          <div onClick={toggleExpand} className='orderHeader clickArea'>

            <div className='row' style={{width: '100%', gap: '20px', alignItems: 'center', justifyContent: 'space-between'}}>
              <div className='row' style={{gap: '20px', alignItems: 'center'}}>
                <span className='textColor bold'>Nº {props.id}</span>
                <span className='grayedText'>{formatDate(orderDate, true)}, {formatWeekday(orderDate, true)}</span>
              </div>

              <div className='row' style={{alignItems: 'center'}}>
                <p className='orderStatusHighlight' style={{color: `${orderStatus[props.status].color}`, backgroundColor: `${orderStatus[props.status].bgcolor}`, textAlign: 'end'}}>{orderStatus[props.status].label}</p>
                {isExpandedInfo ? <KeyboardArrowUp style={{color: 'gray'}} /> : <KeyboardArrowDown style={{color: 'gray'}}/>}
              </div>

            </div>

            {!isExpandedInfo &&
              <div className='orderHeaderProducts defaultBorderedItem'>
                {productDetails.map((product, index) => (
                  <div key={product.id} className='orderHeaderProductContainer'>
                    {configData.defaultStore === 'Peças Kia' && baraoHyundaiFilter.includes(product.ds_number)
                      ?
                        <img src={contentData.missingPic} className='orderHeaderImg'/>
                      :
                        <img src={product.imhash[0]} alt={product.desc || "Product image"} className='orderHeaderImg'/>
                    }
                    <div className='column'>
                      
                      {/* <span><span style={{color: 'var(--greenText)'}}>Entregue</span> no dia 05 de setembro</span> */}
                      <h3 className='orderHeaderProductTitle' translate="no">{props.items[index].quantity}x {product.desc}</h3>
                      
                    </div>
                  </div>
                ))}
              </div>
            }
          </div>

          {isExpandedInfo && (
            <div className='column'>

              {isExpandedInfo &&
                <div style={{marginInline: '10px'}}>
                  <div className='orderHeaderProducts defaultBorderedItem'>
                    {productDetails.map((product, index) => (
                      <div key={product.id} className='orderHeaderProductContainer' style={{justifyContent: 'space-between'}}>
                        <div className='orderHeaderProductContainer2'>
                          {configData.defaultStore === 'Peças Kia' && baraoHyundaiFilter.includes(product.ds_number)
                            ?
                              <img src={contentData.missingPic} className='orderHeaderImg'/>
                            :
                              <img src={product.imhash[0]} alt={product.desc || "Product image"} className='orderHeaderImg'/>
                          }
                          <div className='column'>
                            
                            {/* <span><span style={{color: 'var(--greenText)'}}>Entregue</span> no dia 05 de setembro</span> */}
                            <h3 className={`${props.status === 2 ? 'orderHeaderProductTitle2' : 'orderHeaderProductTitle'}`} translate="no">{props.items[index].quantity}x {product.desc}</h3>
                            
                          </div>
                        </div>
                        {props.status === 2 &&
                          <Button
                            className='buttonColorSecond smallButton fadeIn'
                            label='acompanhar envio'
                            iconStart={<LocalShippingOutlined style={{fontSize: '18px'}}/>}
                            onClick={() => handleShippingTrackerClick(product.id)}
                          />
                        }
                      </div>
                    ))}
                  </div>

                  {/* {props.status === 2 && visibleShippingTracker &&
                    <div className='defaultDialog fadeIn'>
                      <ClickOutside onOutsideClick={() => setVisibleShippingTracker(false)}>
                        <div className='column center defaultDialogContainer' style={{gap: '10px'}}>
                          <div className='circleContainerMain'>
                            <LocalShippingOutlined style={{fontSize: '32px'}}/>
                          </div>
                          <span>Seu produto está indo até você! :)</span>
                          {processedShippingOptions && Object.entries(processedShippingOptions).map(([store, info]) => (
                            info.trackingId && (
                              <div key={store} className='column center' style={{gap: '4px'}}>
                                <span className='grayedText'>{`Código de rastreio ${store}:`}</span>
                                <Button
                                  className='buttonColorSecond smallButton'
                                  label={info.trackingId}
                                  iconEnd={<CopyIcon/>}
                                  onClick={() => copyTrackingCode(info.trackingId)}
                                />
                                {info.carrierUrl && (
                                  <Button
                                    className='buttonColorMain smallButton'
                                    label={`Consultar ${info.carrier}`}
                                    onClick={() => window.open(info.carrierUrl, '_blank')}
                                  />
                                )}
                              </div>
                            )
                          ))}
                          <a onClick={() => setVisibleShippingTracker(false)}>voltar</a>
                        </div>
                      </ClickOutside>
                    </div>
                  } */}

                  {props.status === 3 && reviewingOrder &&
                    <div className='defaultDialog fadeIn'>
                      <ClickOutside onOutsideClick={() => handleReviewCancel()}>
                        <div className='column center defaultDialogContainer' style={{ gap: '10px' }}>
                          <div className='circleContainerMain'>
                            {newUserReview.score > 0
                            ?
                              newUserReview.score
                            :
                              <Star style={{ fontSize: '32px' }} />
                            }
                          </div>
                          <span>Avalie o seu pedido Nº {props.id}</span>
                          
                          {/* Star rating component */}
                          <div className='row'>
                            {[1, 2, 3, 4, 5].map((num) => (
                              <div key={num} onClick={() => handleStarClick(num)}>
                                {num <= newUserReview.score ? (
                                  <Star className='reviewStar selectedStar'/>
                                ) : (
                                  <StarBorderOutlined className='reviewStar'/>
                                )}
                              </div>
                            ))}
                          </div>
                          
                          {/* Text input for optional review */}
                          <textarea
                            value={newUserReview.text}
                            onChange={handleTextChange}
                            placeholder='Deixe sua avaliação (opcional)'
                            className='reviewTextField'
                            rows={1}  // Start with one row
                          />

                          {/* Submit button */}
                          <Button
                            className='buttonColorMain smallButton'
                            label='Avaliar'
                            onClick={handleReviewSubmit}
                          />

                          {/* Back link */}
                          <a onClick={() => handleReviewCancel()}>voltar</a>
                        </div>
                      </ClickOutside>
                    </div>
                  }

                </div>
              }

              {/* <div className='column defaultBorderedItem' style={{margin: '0 10px 10px 10px', gap: '6px'}}>
                <span className='grayedText'>Pedido em transporte com: <span className='textColor bold'>[nomeDaTransportadora]</span></span>
                <div className='row' style={{alignItems: 'center', gap: '4px'}}>
                  <span className='grayedText'>Código de rastreio:</span>
                  <Button
                    className='buttonColorSecond smallButton'
                    label='[trackingCode]'
                    iconEnd={<CopyIcon/>}
                  />
                </div>
                <a>Consulte o status na transportadora</a>
              </div> */}

              {hasReview &&
                <div className='column center defaultBorderedItem fadeIn' style={{margin: '10px 10px 0 10px', }}>
                  <span>Sua avaliação:</span>
                  {/* <div className='circleContainerMain'>
                    {existingReview.score}
                  </div> */}
                  <div className='row center' style={{gap: '6px'}}>
                    <h1>{existingReview.score}/5</h1>
                    {Array(existingReview.score).fill().map((_, index) => (
                      <Star className='reviewStarStatic' key={index} />
                    ))}
                    {Array(5 - existingReview.score).fill().map((_, index) => (
                      <StarBorderOutlined className='reviewStarStatic' key={index} />
                    ))}
                  </div>
                  <div className='reviewTextDisplay'>
                    <span style={{fontStyle: 'italic'}}>"{existingReview.text}"</span>
                  </div>
                </div>
              }

              <div className='actionsContainer fadeIn'>
                <Button
                  className='buttonColorMain smallButton'
                  label='Solicitar atendimento'
                  onClick={() => handleWhatsappClick(null, null, null, null, props.id, null)}
                  />
                {props.status === 4 &&
                  <Button
                    className='buttonColorSecond smallButton'
                    label='Cancelar pedido'
                    onClick={() => {
                      const payId = props.payment.type === 'credit' ? props.payment.merchantChargeId : props.payment.payId;
                      const amount = Math.round(props.payment.totalPrice * 100);
                      const id = props.id; // Ensure you are using the passed ID
                      // console.log('Canceling order with:', { payId, amount, id }); // Debugging log
                      props.onCancelOrder(payId, amount, id); // Call the passed function with the correct parameters
                    }}
                  />
                }
                {/* {props.status === 2 &&
                  <Button
                    className='buttonColorMain smallButton'
                    label='Rastrear entrega'
                    iconStart={<LocalShippingOutlined/>}
                    onClick={() => setVisibleShippingTracker(true)}
                  />
                } */}
                {/* {props.status !== 0 &&
                  <Button
                    className='buttonColorSecond smallButton'
                    label='Troca ou devolução'
                  />
                } */}
                {props.status === 3 && !hasReview &&
                  <Button
                    className='buttonColorThird smallButton'
                    label='Avaliar pedido'
                    iconStart={<Star style={{fontSize: '18px'}}/>}
                    onClick={() => handleReviewClick()}
                  />
                }
              </div>
              
              <div className='column defaultBorderedItem fadeIn' style={{marginInline: '10px'}}>
                <div className='orderDetails'>
                  <div className='column'>
                    <div className='orderDetailsRow'>
                      <span className='grayedText'>Data e hora:</span>
                      <span>{formatDate(orderDate)}, {formatWeekday(orderDate)} às {formatTime(orderDate)}</span>
                    </div>
                    <div className='orderDetailsRow'>
                      <span className='grayedText'>Endereço:</span>
                      <span style={{textAlign: 'end'}}>{prepareAddressData(props.address)}</span>
                    </div>
                    
                    <div className='orderDetailsRow' style={{gap: '6px'}}>
                      <span className='grayedText'>Envios:</span>
                      <div className='orderDetailsRow column' style={{gap: '4px'}}>

                        {Object.entries(processedShippingOptions).map(([store, data]) => (
                          <div key={store} className='orderDetailsRow defaultBorderedItem' style={{gap: '4px', padding: '8px', marginBottom: '0', alignItems: 'start', textAlign: 'start', justifyContent: 'end'}}>
                            <span className='grayedText'>{store}: </span>
                            {data.carrierUrl && (props.status === 1 || props.status === 2) ? (
                              <a 
                                href={data.carrierUrl}
                                target="_blank" 
                                rel="noopener noreferrer"
                                // className='textColor'
                                // style={{textDecoration: 'none'}}
                              >
                                {data.carrier}
                              </a>
                            ) : (
                              <span>{data.carrier}</span>
                            )}
                            {data.trackingId && data.carrier !== 'Retirar no local' && (props.status === 1 || props.status === 2) && (
                              <>
                                {/* <span className='grayedText'> - rastreio: 
                                  <span style={{color: 'var(--textColor)', cursor: 'pointer'}} 
                                    onClick={() => copyTrackingCode(data.trackingId)}
                                    title="Clique para copiar"
                                  >
                                    {data.trackingId}
                                  </span>
                                </span> */}
                                <Button
                                  className='buttonColorGray smallButton'
                                  label={data.trackingId}
                                  iconEnd={<CopyIcon style={{fontSize: '18px'}}/>}
                                  onClick={() => copyTrackingCode(data.trackingId)}
                                />
                              </>
                            )}
                          </div>
                        ))}

                      </div>
                    </div>


                    <div className='orderDetailsRow'>
                      <span className='grayedText'>Pagamento:</span>
                      <span>
                        {props.payment.type === 'credit' && 'Cartão de Crédito'}
                        {props.payment.type === 'pix' && 'Pix'}
                      </span>
                    </div>
                    {props.payment.type === 'credit' &&
                      <div className='orderDetailsRow'>
                        <span className='grayedText'>Parcelas:</span>
                        <span style={{textAlign: 'end'}}>{props.payment.parcels}</span>
                      </div>
                    }
                    <div className='orderDetailsRow'>
                      <span className='grayedText'>Subtotal:</span>
                      <span style={{textAlign: 'end'}}>{formatPrice(props.payment.subtotalPrice)}</span>
                    </div>
                    {props.payment.discount > 0 &&
                      <div className='orderDetailsRow'>
                        <span className='grayedText'>Desconto:</span>
                        <span className='greenText'>- {formatPrice(props.payment.discount)}</span>
                      </div>
                    }
                    {(props.payment.coupon && props.payment.coupon !== '') &&
                      <div className='orderDetailsRow'>
                        <span className='grayedText'>Cupom:</span>
                        <span style={{color: 'var(--mainColor)'}}>{props.payment.coupon}</span>
                      </div>
                    }
                    <div className='orderDetailsRow'>
                      <span className='grayedText'>Frete:</span>
                      <span>{formatPrice(props.payment.shipping)}</span>
                    </div>
                    <div className='orderDetailsRow'>
                      <span className='textColor bold'>Total:</span>
                      <span className='textColor bold' style={{textAlign: 'end'}}>{formatPrice(props.payment.totalPrice)}</span>
                    </div>
                  </div>
                </div>

                {props.payment.type === 'pix' && props.status === 4 &&
                  <div className='orderDetails' style={{marginBottom: '10px'}}>
                    <div className='column center' style={{gap: '10px'}}>
                      {/* <img alt='Pix QR Code' src={qrcode} className='pixQrCode'/> */}
                      {qrCode && (
                            <img 
                                alt='Pix QR Code' 
                                src={`data:image/png;base64,${qrCode}`} 
                                className='pixQrCode'
                            />
                        )}
                      {/* <span className='pixCode' style={{backgroundColor: 'transparent'}}>{props.payment.pixCode}</span> */}
                      {/* <Button
                        className='buttonColorMain smallButton'
                        label='Copiar código PIX'
                        iconStart={<CopyIcon style={{fontSize: '18px'}}/>}
                        onClick={() => handleShare(props.payment.pixCode)}
                      /> */}
                    </div>
                  </div>
                }

                <div className='orderDetails'>
                  {props.items.length === 1
                    ? <h3 className='textColor'>Produto:</h3>
                    : <h3 className='textColor'>Produtos:</h3>
                  }
                  <div className='column'>
                    {productDetails.map((product, index) => (
                      <div key={product.id} className='orderItem clickArea' onClick={() => navigate(`/${product.ds_number}`)}>
                        <h3>{props.items[index].quantity}x</h3>
                        {configData.defaultStore === 'Peças Kia' && baraoHyundaiFilter.includes(product.ds_number)
                          ?
                            <img src={contentData.missingPic} className='productRowImg'/>
                          :
                            <img src={product.imhash[0]} alt={product.desc || "Product image"} className='productRowImg'/>
                        }
                        <div className='column'>
                          <h3>{product.desc}</h3>
                          <h3 className='grayedText noSelect'>Código: {product.ds_number}</h3>
                          <h3>{formatPrice(props.items[index].fixedPrice)}</h3>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {isExpandedInfo &&
                <div className='column center clickArea' style={{display: 'flex', width: '100%', padding: '10px 0'}} onClick={toggleExpand}>
                  <KeyboardArrowUp style={{color: 'gray'}}/>
                </div>
              }

            </div>
          )}
        </div>
        <ToastContainer 
            position="top-center"
            autoClose={2000}
            hideProgressBar={true}
            closeOnClick
            pauseOnFocusLoss
            style={{ marginTop: '60px', zIndex: 999 }}
        />
      </>
    );
}

export default OrderItem;

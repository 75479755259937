import React from 'react';
import '../App.css';
import { Close } from '@mui/icons-material';
import ClickOutside from '../Utilities/ClickOutside';


// Rename component to start with an uppercase letter to follow React conventions
function DefaultPopup({ title, children, onClose, bgColor, contentColor, titleColor }) {
    return (
        <div className='defaultPopupBg fadeIn'>
            <ClickOutside onOutsideClick={onClose}>
                <div className='defaultPopupContainer' style={{backgroundColor: `${bgColor ? bgColor : 'var(--veryLightGray'}`, color: `${contentColor ? contentColor : 'var(--textColor)'}`}}>
                    <div className='defaultPopupCloseButton clickArea'>
                        <Close onClick={onClose}/>
                    </div>
                    <h1 style={{color: `${titleColor ? titleColor : 'var(--textColor)'}`}}>{title}</h1>
                    <div>{children}</div>
                </div>
            </ClickOutside>
        </div>
    );
}

export default DefaultPopup;
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { configData } from '../Whitelabel/websitesData';
import Button from '../Common/Button';
import { getMktName } from '../Providers/Sellers';
import { CircularProgress } from '@mui/material';
import { useUser } from '../Providers/UserContext';
import { RefreshOutlined } from '@mui/icons-material';

function AdminPage() {
    const {gpuser,setUser} = useUser();
    const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(true); //SET FALSE TO REQUIRE PASSWORD
  const correctPassword = 'GoParts123';
  const [marketNames, setMarketNames] = useState({});
  const [loading, setLoading] = useState(false); // Loading state

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert('Negado');
    }
  };

  useEffect(() => {
    const fetchMarketNames = async () => {
      setLoading(true);
      const sortedWarehouses = configData.storeSellersWarehouses.sort();
      const sortedDisabledSellers = configData.disabledSellers.sort();
      
      const names = {};
      
      // Load existing names from localStorage first
      const storedMarketNames = localStorage.getItem('marketNames');
      const existingNames = storedMarketNames ? JSON.parse(storedMarketNames) : {};
      
      // Combine all sellers
      const allSellers = [...sortedWarehouses, ...sortedDisabledSellers];
      
      // Only fetch names for sellers that don't exist in localStorage
      for (const seller of allSellers) {
        if (!existingNames[seller]) {
          const mktName = await getMktName(seller);
          names[seller] = mktName;
          setMarketNames(prevNames => ({
            ...prevNames,
            [seller]: mktName
          }));
        }
      }

      // Merge new names with existing names
      const updatedNames = { ...existingNames, ...names };
      localStorage.setItem('marketNames', JSON.stringify(updatedNames));
      setMarketNames(updatedNames);
      setLoading(false);
    };

    // Check localStorage and compare with current sellers
    const storedMarketNames = localStorage.getItem('marketNames');
    const parsedMarketNames = storedMarketNames ? JSON.parse(storedMarketNames) : {};
    const allCurrentSellers = [...configData.storeSellersWarehouses, ...configData.disabledSellers];
    
    // Check if any current sellers are missing from stored names
    const hasMissingSellers = allCurrentSellers.some(seller => !parsedMarketNames[seller]);

    if (!storedMarketNames || hasMissingSellers) {
      fetchMarketNames();
    } else {
      setMarketNames(parsedMarketNames);
    }
  }, [isAuthenticated]);

  const handleReload = () => {
      localStorage.removeItem('marketNames'); // Clear marketNames from localStorage
      window.location.reload();
  }

  return (
    <div className='main fadeIn'>
        {gpuser
            ?
                <div className='pageContent center' style={{ paddingInline: '10px', boxSizing: 'border-box' }}>
                    {!isAuthenticated ? (
                    <form onSubmit={handlePasswordSubmit} className='card column' style={{ padding: '20px', gap: '10px', maxWidth: '480px' }}>
                        <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Insira a senha"
                        />
                        <Button
                        type="submit"
                        label="Acessar"
                        className='buttonColorThird smallButton'
                        />
                    </form>
                    ) : (
                      <div className='column' style={{ gap: '20px' }}>
                        <div className='rowToColumn800' style={{ gap: '20px' }}>
                          <div className='column' style={{ gap: '12px' }}>
                              <h2 style={{ color: 'var(--secondColor)' }}>{configData.storeSellersWarehouses.length > 1 ? `${configData.storeSellersWarehouses.length} sellers ativos:` : `${configData.storeSellersWarehouses.length} seller ativo:`}</h2>
                              {configData.storeSellersWarehouses
                              .sort() // This line can be removed since sorting is done in fetchMarketNames
                              .map((warehouse, index) => (
                                  <p key={index}>
                                  <span style={{ fontWeight: 'bold' }}>{warehouse}</span>
                                  <span>
                                      {!marketNames[warehouse] ? ( // Show loader if loading or market name is not yet fetched
                                      <CircularProgress
                                          size={20}
                                          sx={{
                                          color: 'var(--secondColor)',
                                          marginLeft: '10px',
                                          }}
                                      />
                                      ) : (
                                      ` - ${marketNames[warehouse] || 'N/A'}`
                                      )}
                                  </span>
                                  </p>
                              ))}
                          </div>
                          <div className='column' style={{ gap: '12px' }}>
                              <h2 style={{ color: 'var(--secondColor)' }}>{configData.disabledSellers.length > 1 ? `${configData.disabledSellers.length} sellers inativos:` : `${configData.disabledSellers.length} seller inativo:`}</h2>
                              {configData.disabledSellers
                              .sort() // This line can be removed since sorting is done in fetchMarketNames
                              .map((warehouse, index) => (
                                  <p key={index}>
                                  <span style={{ fontWeight: 'bold' }}>{warehouse}</span>
                                  <span>
                                      {!marketNames[warehouse] ? ( // Show loader if loading or market name is not yet fetched
                                      <CircularProgress
                                          size={20}
                                          sx={{
                                          color: 'var(--secondColor)',
                                          marginLeft: '10px',
                                          }}
                                      />
                                      ) : (
                                      ` - ${marketNames[warehouse] || 'N/A'}`
                                      )}
                                  </span>
                                  </p>
                              ))}
                          </div>
                        </div>
                        <div className='column center'>
                          <div className='row' style={{maxWidth: '200px'}}>
                            <Button
                              label="Forçar atualização"
                              iconStart={<RefreshOutlined />}
                              className='buttonColorMain smallButton'
                              onClick={() => {handleReload();}}
                            />
                          </div>
                        </div>
                      </div>

                    )}
                </div>
            :
                <div className='column center' style={{ gap: '10px', paddingTop: '20px' }}>
                    <h2>Acesso restrito</h2>
                    <Link className='textLink' to='/login?destination=/sellers-ativos'>Fazer login</Link>
                    {/* <Button
                        label="Login"
                        className='buttonColorThird smallButton'
                        onClick={() => navigate('/login')}
                    /> */}
                </div>
        }
    </div>
  );
}

export default AdminPage;
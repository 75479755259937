import FiltrosImg from '../images/categories/filtros_500px.webp';
import FreiosImg from '../images/categories/freios_500px.webp';
import SuspensaoDirecaoImg from '../images/categories/suspensaoedirecao_500px.webp';
import MotorPerifericosImg from '../images/categories/motoreperifericos_500px.webp';
import ArrefecimentoImg from '../images/categories/arrefecimento_500px.webp';
import IgnicaoEletricaImg from '../images/categories/ignicaoeeletrica_500px.webp';
import CorreiasTensoresRolamentosImg from '../images/categories/correiastensoreserolamentos_500px.webp';
import SistemasAlimentacaoCombustivelImg from '../images/categories/alimentacaodecombustivel_500px.webp';
import ArCondicionadoImg from '../images/categories/arcondicionado_500px.webp';
import TransmissaoEmbreagensImg from '../images/categories/transmissaoeembreagens_500px.webp';
import CoxinsSuportesImg from '../images/categories/coxins_e_suportes_500px.webp';
import LubrificantesFluidosImg from '../images/categories/lubrificantes_e_fluidos_500px.webp';
import CarroceriaImg from '../images/categories/bodyparts_500px.webp';
import IluminacaoImg from '../images/categories/iluminacao_500px.webp';
import KitsRevisaoReparoImg from '../images/categories/manutencaobasica_500px.webp';
import AcessoriosBikesImg from '../images/categories/capacete_500px.webp';
import FreiosBikesImg from '../images/categories/freios_moto_2.1_500px.webp';
import KitsRevisaoBikesImg from '../images/categories/kit_reposicao_moto_1.1_500px.webp';
import TransmissaoEmbreagensBikesImg from '../images/categories/transmissao_moto_500px.webp';
import Boutique from '../images/UmuaramaMotos/boutique_500px.png';
import Pecas from '../images/UmuaramaMotos/pecas_500px_2.png';
import Acessorios from '../images/UmuaramaMotos/acessorios_500px.png';
import KTM from '../images/categories/ktm_500px.webp';
import Triumph from '../images/categories/triumph_500px.webp';
// import KTM from '../images/UmuaramaMotos/pecas_500px_2_ktm.png';
import TudoImg from '../images/categories/shop_500px.webp';

// export const nomeCategoria = [
//   "Filtros",
//   "Freios",
//   "Suspensão & Direção",
//   "Motor & Periféricos",
//   "Arrefecimento",
//   "Ignição & Elétrica",
//   "Correias & Tensores & Rolamentos",
//   "Sistemas de Alimentação & Combustivel",
//   "Ar Condicionado",
//   "Transmissão & Embreagens",
//   //"Sensores",
//   "Coxins e Suportes",
//   "Lubrificantes e Fluidos",
//   //"Acessórios & Exterior",
//   "Carroceria",
//   // "Iluminação",
// ];

export const bikeBrands = [
  "honda motos",
  "yamaha",
  "suzuki",
  "kawasaki",
  "bmw motos",
  "ducati",
  "ktm",
  "triumph",
  "harley-davidson",
];

export const categoriesCars = {
  'Filtros': FiltrosImg,
  'Freios': FreiosImg,
  'Suspensão & Direção': SuspensaoDirecaoImg,
  'Motor & Periféricos': MotorPerifericosImg,
  'Arrefecimento': ArrefecimentoImg,
  'Ignição & Elétrica': IgnicaoEletricaImg,
  'Correias & Tensores & Rolamentos': CorreiasTensoresRolamentosImg,
  'Sistemas de Alimentação & Combustivel': SistemasAlimentacaoCombustivelImg,
  'Ar Condicionado': ArCondicionadoImg,
  'Transmissão & Embreagens': TransmissaoEmbreagensImg,
  'Coxins e Suportes': CoxinsSuportesImg,
  'Lubrificantes e Fluidos': LubrificantesFluidosImg,
  'Carroceria': CarroceriaImg,
  'Iluminação': IluminacaoImg,
  'Tudo': TudoImg,
  // Uncomment these if needed
  // 'Kits de Revisão & Reparo': KitsRevisaoReparoImg
};

export const categoriesBikes = {
  "Filtros": FiltrosImg,
  "Freios": FreiosBikesImg,
  "Kits Reposição": KitsRevisaoBikesImg,
  "Ignição & Elétrica": IgnicaoEletricaImg,
  "Suspensão & Direção": SuspensaoDirecaoImg,
  "Transmissão & Embreagens": TransmissaoEmbreagensBikesImg,
  "Acessórios": AcessoriosBikesImg,
  "Lubrificantes e Fluidos": LubrificantesFluidosImg,
  "Tudo": TudoImg,
};

export const CategoriesUHD = {
  'Boutique': Boutique,
  'Peças': Pecas,
  'Acessórios': Acessorios,
  'KTM': KTM,
  'Triumph': Triumph,
}

export const subcatPeloNomeCategoria = {
  "Filtros": [
    "Filtro de Ar",
    "Filtro de Cabine",
    "Filtro de Combustível",
    "Filtro de Óleo",
    "Filtro de Câmbio Automático",
    "Lubrificantes e aditivos",
    "Kit de revisão",
    //"Filtro do Diferencial"
  ],
  "Freios": [
    "Pastilha Dianteira",
    "Pastilha Traseira",
    "Disco Dianteiro",
    "Disco Traseiro",
    //"Tambor",
    "Sapatas",
    "Sensor de Desgaste de Pastilhas",
    "Cilindro Mestre de Freio",
    //"Pinças de Freio e Componentes",
    "Sensor de Freio ABS",
    //"Flexível & Mangueira"
    "Fluido de Freio",
    "Tambor de freio"
  ],
  "Suspensão & Direção": [
    "Amortecedores Dianteiros",
    "Amortecedores Traseiros",
    "Coxins de Amortecedor",
    "Pivôs",
    "Bieletas",
    "Terminal de Direção",
    "Barras Axiais",
    //"Kit de Reparo da Homocinética",
    //"Semi Arvores",
    "Braços Oscilantes & Bandeja",
    "Cubo de Roda Dianteiro",
    "Cubo de Roda Traseiro",
    //"Rolamentos de Roda",
    //"Caixa de Direção",
    //"Bomba da Direção Hidráulica",
    //"Barra de Direção",
    //"Batentes de Amortecedor",
    //"Buchas",
    //"Coifas",
    //"Barra Estabilizadora"
    "Kit coxim e rolamento dianteiro",
    "Rolamento do batente dianteiro",
    "Batentes de amortecedor",
    //"Braços oscilantes/Bandeja -dianteiro",
    "Coifa homocinética - lado câmbio",
    "Kit batentes dianteiro",
    "Kit batentes traseiro",
  ],
  "Motor & Periféricos": [
    "Bomba Água",
    "Bomba Água Auxiliar",
    "Bomba Óleo",
    "Bomba Óleo Auxiliar",
    "Radiador de Óleo",
    "Juntas do Cabeçote",
    //"Carter",
    "Tampa de Válvulas",
    "Junta da tampa de válvulas",
    "PCV / Anti-Chama",
    "Válvula DV",
    "Controle pressão turbina",
    "Sonda Lambda",
    "Sensor de rotação fônica",
    "Sensor de fase",
    "Kit comandos válvulas",
    "Kit capas protetoras correia",
    "Kit juntas EGR",
    "Peças gerais motor",
    "Kit anti-chamas",
    "Bico Injetor",
    "Kit bomba e bicos injetores",
    "Kit flautas de combustível",
    //"Coxins do Motor e Transmissão",
    //"Catalisador",
    //"PreCatalisador",
    //"Anéis",
    //"Pistões",
    //"Bielas",
    //"Virabrequim",
    //"Bronzinas & Bielas",
    //"Bronzinas & Mancal",
    //"Camisas",
    //"Comando de Válvulas",
    //"Válvulas Admissão",
    //"Válvulas Escape",
    //"Tuchos",
    //"Módulo EGR",
    //"Válvula EGR",
    "Controle pressão turbina",
  ],
  "Arrefecimento": [
    "Bomba Água",
    "Radiador",
    "Eletroventilador",
    "Radiador de Óleo",
    "Válvula Termostática",
    "Reservatório de Expansão",
    //"Mangueiras de Arrefecimento",
    ///"Bomba de água auxiliar",
    "Resfriador de Ar",
    "Líquido de arrefecimento",
    "Lubrificantes e aditivos"
  ],
  "Ignição & Elétrica": [
    "Jogo Velas",
    "Bobinas Ignição",
    "Cabo Velas",
    //"Distribuidor", //sdasdsad
    "Alternador",
    "Motor de Arranque",
    //"Regulador de tensão"
  ],
  "Correias & Tensores & Rolamentos": [
    "Correia Dentada",
    "Correia de Acessórios",
    //"Kit Correia Dentada",
    //"Tensores",
    //"Polias e Rolamentos",
    //"AllBeltDrive",
    //"Correia DH" ,
    //"Correia Bomba Água" ,
    //"Correia Alternador" ,
    "Kit correia de acessórios",
    "Kit corrente de distribuição",
    "Kit correia dentada"
  ],
  "Sistemas de Alimentação & Combustivel": [
    "Bico Injetor",
    "Bomba Combustivel",
    "Bomba Combustivel Alta",
    //"Cânister",
    //"Sensor Nível Combustível",
    "Velas aquecedoras - Diesel",
    "Sensor de nível de combustível",
    "Kit bomba e bicos injetores",
    "Kit flautas de combustível",
  ],
  "Ar Condicionado": [
    "Compressor",
    "Filtro de Cabine",
    "Condensador",
    //"Ventilador",
  ],
  "Transmissão & Embreagens": [
    "Kit de Substituição de Embreagem",
    //"Filtro de Câmbio Automático",
    //"Cilindros & Componentes" ,
    //"Disco de Embreagem",
    //"Atuador de Embreagem",
    //"Filtro do diferencial",
    "Cilindro atuador da embreagem",
    "Cilindro escravo da embreagem",
    "Rolamento da embreagem"
  ],
  "Sensores": [
    "Sonda Lambda",
    "Sensor Rotação Fônica",
    "Sensor Fase",
    "Sensor Nível Combustível",
    "Sensor ABS",
    "Sensor de Desgaste de Pastilhas",
    "Sensor Estacionamento",
    "Sensor de Massa de Ar"
  ],
  "Acessórios & Exterior": [
    "Palhetas",
    "Lâmpadas",
    "Farol principal",
    "Mola a gás",
    "Bomba Água Para-brisa",
    "Tampa do Reservatório de água"
  ],
  "Coxins e Suportes": [
    "Coxim do motor",
    "Coxim do amortecedor dianteiro",
    "Kit coxim e rolamento dianteiro"
  ],
  "Lubrificantes e Fluidos": [
    "Fluido de Freio",
    "Líquido de arrefecimento",
    "Lubrificantes e aditivos",
    "Tambor de freio",
  ],
  "Carroceria": [
    "Palhetas",
    "Retrovisores",
    // "Farol dianteiro",
    // "Farol de neblina",
    // "Lanterna traseira",
    "Grades dianteiras",
    "Para-choque dianteiro",
    "Para-choque traseiro",
    "Kit suportes parachoque",
    "Kit borrachas de porta",
    "Peças gerais carroceria",
    // "Kit de lâmpadas farol",
    "Dobradiças de capô",
  ],
  "Iluminação": [
    "Farol dianteiro",
    "Lanterna traseira",
    "Kit de lâmpadas farol",
    "Farol de neblina",
  ],
  "Tudo": [
    "Filtro de Ar",
    "Filtro de Cabine",
    "Filtro de Combustível",
    "Filtro de Óleo",
    "Filtro de Câmbio Automático",
    "Lubrificantes e aditivos",
    "Kit de revisão",
    "Filtro do Diferencial",
    "Pastilha Dianteira",
    "Pastilha Traseira",
    "Disco Dianteiro",
    "Disco Traseiro",
    "Tambor",
    "Sapatas",
    "Sensor de Desgaste de Pastilhas",
    "Cilindro Mestre de Freio",
    "Pinças de Freio e Componentes",
    "Sensor de Freio ABS",
    "Flexível & Mangueira",
    "Fluido de Freio",
    "Tambor de freio",
    "Amortecedores Dianteiros",
    "Amortecedores Traseiros",
    "Coxins de Amortecedor",
    "Pivôs",
    "Bieletas",
    "Terminal de Direção",
    "Barras Axiais",
    "Kit de Reparo da Homocinética",
    "Semi Arvores",
    "Braços Oscilantes & Bandeja",
    "Cubo de Roda Dianteiro",
    "Cubo de Roda Traseiro",
    "Rolamentos de Roda",
    "Caixa de Direção",
    "Bomba da Direção Hidráulica",
    "Barra de Direção",
    "Batentes de Amortecedor",
    "Buchas",
    "Coifas",
    "Barra Estabilizadora",
    "Kit coxim e rolamento dianteiro",
    "Rolamento do batente dianteiro",
    "Braços oscilantes/Bandeja -dianteiro",
    "Coifa homocinética - lado câmbio",
    "Kit batentes dianteiro",
    "Kit batentes traseiro",
    "Bomba Água",
    "Bomba Água Auxiliar",
    "Bomba Óleo",
    "Bomba Óleo Auxiliar",
    "Radiador de Óleo",
    "Juntas do Cabeçote",
    "Carter",
    "Tampa de Válvulas",
    "Junta da tampa de válvulas",
    "PCV / Anti-Chama",
    "Válvula DV",
    "Controle pressão turbina",
    "Sonda Lambda",
    "Sensor de rotação fônica",
    "Sensor de fase",
    "Kit comandos válvulas",
    "Kit capas protetoras correia",
    "Kit juntas EGR",
    "Peças gerais motor",
    "Kit anti-chamas",
    "Bico Injetor",
    "Kit bomba e bicos injetores",
    "Kit flautas de combustível",
    "Coxins do Motor e Transmissão",
    "Catalisador",
    "PreCatalisador",
    "Anéis",
    "Pistões",
    "Bielas",
    "Virabrequim",
    "Bronzinas & Bielas",
    "Bronzinas & Mancal",
    "Camisas",
    "Comando de Válvulas",
    "Válvulas Admissão",
    "Válvulas Escape",
    "Tuchos",
    "Módulo EGR",
    "Válvula EGR",
    "Radiador",
    "Eletroventilador",
    "Válvula Termostática",
    "Reservatório de Expansão",
    "Mangueiras de Arrefecimento",
    "Bomba de água auxiliar",
    "Resfriador de Ar",
    "Líquido de arrefecimento",
    "Jogo Velas",
    "Bobinas Ignição",
    "Cabo Velas",
    "Alternador",
    "Motor de Arranque",
    "Regulador de tensão",
    "Correia Dentada",
    "Correia de Acessórios",
    "Kit Correia Dentada",
    "Tensores",
    "Polias e Rolamentos",
    "AllBeltDrive",
    "Correia DH",
    "Correia Bomba Água",
    "Correia Alternador",
    "Kit correia de acessórios",
    "Kit corrente de distribuição",
    "Kit correia dentada",
    "Bomba Combustivel",
    "Bomba Combustivel Alta",
    "Cânister",
    "Sensor Nível Combustível",
    "Velas aquecedoras - Diesel",
    "Sensor de nível de combustível",
    "Compressor",
    "Filtro de Cabine",
    "Condensador",
    "Ventilador",
    "Kit de Substituição de Embreagem",
    "Filtro de Câmbio Automático",
    "Cilindros & Componentes",
    "Disco de Embreagem",
    "Atuador de Embreagem",
    "Cilindro atuador da embreagem",
    "Cilindro escravo da embreagem",
    "Rolamento da embreagem",
    "Sensor Rotação Fônica",
    "Sensor Fase",
    "Sensor Nível Combustível",
    "Sensor ABS",
    "Sensor Estacionamento",
    "Sensor de Massa de Ar",
    "Palhetas",
    "Lâmpadas",
    "Farol principal",
    "Mola a gás",
    "Bomba Água Para-brisa",
    "Tampa do Reservatório de água",
    "Coxim do motor",
    "Coxim do amortecedor dianteiro",
    "Kit de lâmpadas farol",
    "Dobradiças de capô",
    "Farol de neblina",
  ],
};

export const subcatPeloNomeCategoriaBikes = {
  "Filtros": [
    "Filtro de Ar",
    "Filtro de Combustível",
    "Filtro de Óleo",
    "Lubrificantes e aditivos",
    "Kit de revisão"
  ],
  "Freios": [
    "Pastilha Dianteira",
    "Pastilha Traseira",
    "Disco Dianteiro",
    "Disco Traseiro",
    "Sapatas",
    "Fluido de Freio",
    "Tambor de freio"
  ],
  "Kits Reposição": [
    "Kit Manutenção",
    "Kit Relação",
    "Kit Cilindros",
    "Kit Caixa Direção",
  ],
  "Ignição & Elétrica": ["Velas de Ignição", "Bateria"],
  "Suspensão & Direção": [
    "Amortecedores Dianteiros",
    "Amortecedores Traseiros",
  ],
  "Transmissão & Embreagens": [
    "Kit de Substituição de Embreagem",
    "Kit Relação",
    "Disco de Embreagem"
  ],
  "Acessórios": ["Capacetes", "Pneus"],
  "Lubrificantes e Fluidos": [
    "Lubrificantes e aditivos",
  ],
  "Tudo": [
    "Filtro de Ar",
    "Filtro de Combustível",
    "Filtro de Óleo",
    "Lubrificantes e aditivos",
    "Kit de revisão",
    "Pastilha Dianteira",
    "Pastilha Traseira",
    "Disco Dianteiro",
    "Disco Traseiro",
    "Sapatas",
    "Fluido de Freio",
    "Tambor de freio",
    "Kit Manutenção",
    "Kit Relação",
    "Kit Cilindros",
    "Kit Caixa Direção",
    "Velas de Ignição", 
    "Bateria",
    "Amortecedores Dianteiros",
    "Amortecedores Traseiros",
    "Kit de Substituição de Embreagem",
    "Disco de Embreagem"
  ]
};

export const idsSubcategoriaPeloNome = {
  "Filtro de Ar": [5, 2008, 3301, 7473],
  "Filtro de Cabine": [343],
  "Filtro de Combustível": [6, 5643, 7476],
  "Filtro de Óleo": [4],
  "Lubrificantes e aditivos": [5245, 2942, 4066],
  "Kit de revisão": [4066],
  "Filtro de Câmbio Automático": [338, 2263, 3263],
  "Filtro do Diferencial": [2981],
  "Pastilha Dianteira": [328],
  "Pastilha Traseira": [328],
  "Disco Dianteiro": [68],
  "Disco Traseiro": [68],
  "Tambor": [3278],
  "Sapatas": [59],
  "Sensor de Desgaste de Pastilhas": [332, 2885],
  "Cilindro Mestre de Freio": [219],
  "Pinças de Freio e Componentes": [65, 33],
  "Sensor de Freio ABS": [335, 1086, 1816],
  "Flexível & Mangueira": [69],
  "Amortecedores Dianteiros": [704, 897],
  "Amortecedores Traseiros": [704, 897],
  "Coxins de Amortecedor": [761],
  "Pivôs": [2002],
  "Bieletas": [1691, 2716, 2988],
  "Terminal de Direção": [741],
  "Kit batentes dianteiro": [5240],
  "Kit batentes traseiro": [6368],
  "Barras Axiais": [44],
  "Kit de Reparo da Homocinética": [3, 198],
  "Semi Arvores": [9],
  "Braços Oscilantes & Bandeja": [230],
  "Cubo de Roda Dianteiro": [539],
  "Cubo de Roda Traseiro": [539],
  "Rolamentos de Roda": [540, 541],
  "Caixa de Direção": [240],
  "Bomba da Direção Hidráulica": [8],
  "Barra de Direção": [238, 412, 559],
  "Batentes de Amortecedor": [745],
  "Buchas": [1328, 1692],
  "Coifas": [2848],
  "Barra Estabilizadora": [231],
  "Bomba Água": [957, 2593, 3576],
  "Bomba Água Auxiliar": [1037],
  "Bomba Óleo": [487],
  "Bomba Óleo Auxiliar": [5480],
  "Radiador de Óleo": [381],
  "Juntas do Cabeçote": [269, 270],
  "Carter": [483],
  "Tampa de Válvulas": [778],
  "Junta da tampa de válvulas": [271, 779],
  "PCV / Anti-Chama": [3303],
  "Válvula DV": [3707],
  "Controle pressão turbina": [621],
  "Sonda Lambda": [3338],
  "Sensor de rotação fônica": [685],
  "Sensor de fase": [3362],
  "Kit comandos válvulas": [461],
  "Kit capas protetoras correia": [2793],
  "Kit juntas EGR": [4018],
  "Peças gerais motor": [4318],
  "Kit anti-chamas": [2809],
  "Coxins do Motor e Transmissão": [209, 210, 211, 430],
  "Catalisador": [3528, 2276, 347, 348],
  "PreCatalisador": [2431],
  "Anéis": [360, 2718],
  "Pistões": [359, 757],
  "Bielas": [472],
  "Virabrequim": [3125],
  "Bronzinas & Bielas": [5503],
  "Bronzinas & Mancal": [4384, 467],
  "Camisas": [4052, 2912],
  "Comando de Válvulas": [460],
  "Válvulas Admissão": [965],
  "Válvulas Escape": [966],
  "Tuchos": [926],
  "Módulo EGR": [3602],
  "Válvula EGR": [868],
  //"Controle pressão turbina": [621],
  //"Bomba Água": [957, 2593, 3576],
  "Radiador": [382],
  "Eletroventilador": [414, 647, 953],
  //"Radiador de Óleo": [381],
  "Válvula Termostática": [267],
  "Reservatório de Expansão": [1879],
  "Mangueiras de Arrefecimento": [387, 7756, 1425],
  "Bomba de água auxiliar": [1037],
  "Resfriador de Ar": [380, 4306],
  "Jogo Velas": [569],
  "Velas de Ignição": [569],
  "Bateria": [0],
  "Bobinas Ignição": [571, 1842],
  "Cabo Velas": [567, 568],
  "Distribuidor": [566, 570, 572, 573, 574, 575, 1685, 1841, 1843, 2487],
  "Alternador": [2],
  "Motor de Arranque": [1],
  "Regulador de tensão": [242],
  "Correia Dentada": [259],
  "Correia de Acessórios": [258, 438],
  "Kit Correia Dentada": [260],
  "Tensores": [436, 437, 465],
  "Polias e Rolamentos": [263],
  "AllBeltDrive": [
    7,
    257,
    258,
    259,
    260,
    261,
    262,
    263,
    264,
    436,
    437,
    438,
    439,
    440,
    1297,
    1300,
    1742,
    1745,
    1746,
    1747,
    1995,
    2173,
    2240,
    2246,
    2269,
    2271,
    2425,
    2701,
    2793,
    2806,
    2807,
    2984,
    2993,
    2994,
    3020,
    3042,
    3043,
    3061,
    3465,
    3470,
    3650,
    3651,
    3653,
    4028,
    4142,
    4274,
    5732,
    7016
  ],
  "Bico Injetor": [3321],
  "Bomba Combustivel": [373, 1067],
  "Bomba Combustivel Alta": [3334, 3320],
  "Cânister": [1095],
  "Sensor Nível Combustível": [496],
  "Compressor": [363],
  //"Filtro de Cabine": [343],
  "Condensador": [364],
  "Ventilador": [2192],
  "Kit de Substituição de Embreagem": [391],
  //"Filtro de Câmbio Automático": [338, 2263, 3263],
  "Cilindros & Componentes": [197, 509],
  "Disco de Embreagem": [222],
  "Atuador de Embreagem": [41],
  "Filtro do diferencial": [2981],
  //"Sonda Lambda": [3338, 334],
  "Sensor Rotação Fônica": [685],
  "Sensor Fase": [3362],
  //"Sensor Nível Combustível": [496],
  "Sensor ABS": [335, 1816],
  //"Sensor de Desgaste de Pastilhas": [332, 2885],
  "Sensor Estacionamento": [1965],
  "Sensor de Massa de Ar": [3342],
  //"Palhetas": [251],
  "Lâmpadas": [91, 92, 93, 94, 99, 100, 103, 832, 888, 2502],
  "Farol principal": [220],
  "Mola a gás": [183],
  "Bomba Água Para-brisa": [649],
  "Tampa do Reservatório de água": [50],
  "Fluido de Freio": [2841],
  "Tambor de freio": [3278],
  "Kit coxim e rolamento dianteiro": [1292],
  "Rolamento do batente dianteiro": [894],
  "Batentes de amortecedor": [745],
  "Braços oscilantes/Bandeja -dianteiro": [230],
  "Coifa homocinética - lado câmbio": [166],
  "Líquido de arrefecimento": [1265],
  "Velas aquecedoras - Diesel": [205],
  "Sensor de nível de combustível": [496],
  "Kit bomba e bicos injetores": [3348],
  "Kit flautas de combustível": [3380],
  "Kit correia de acessórios": [438],
  "Kit corrente de distribuição": [1074],
  "Kit correia dentada": [260],
  "Cilindro atuador da embreagem": [197],
  "Cilindro escravo da embreagem": [40],
  "Rolamento da embreagem": [41],
  "Coxim do motor": [209],
  "Coxim do amortecedor dianteiro": [761],
  "Kit Manutenção": [4066],
  "Kit Relação": [3492],
  "Kit Cilindros": [360],
  "Kit Caixa Direção": [3977],
  "Capacetes": [5712],
  "Pneus": [1365],
  "Palhetas": [251],
  "Retrovisores": [2468],
  "Farol dianteiro": [220],
  "Farol de neblina": [243],
  "Lanterna traseira": [244],
  "Grades dianteiras": [384],
  "Para-choque dianteiro": [15],
  "Para-choque traseiro": [827],
  "Kit suportes parachoque": [3136],
  "Kit borrachas de porta": [1682],
  "Peças gerais carroceria": [589],
  "Kit de lâmpadas farol": [93],
  "Dobradiças de capô": [1245],
};

export const categoryAxle = {
    "Pastilha Dianteira": "front",
    "Pastilha Traseira": "rear",
    "Disco Dianteiro": "front",
    "Disco Traseiro": "rear",
    "Amortecedores Dianteiros": "front",
    "Amortecedores Traseiros": "rear",
    "Cubo de Roda Dianteiro": "front",
    "Cubo de Roda Traseiro": "rear",
}